import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from "./config.service";

@Injectable()
export class BeaconGatewayService {

  constructor(private http: HttpClient,public configService:ConfigService) { }
/*  saveBeaconGatewaySettings(beaconGatewaySettings) {
    return this.http.post(this.configService.appConfig.beaconGateway.BEACON_GATEWAY_MICRO_SERVICE_URL +'beaconGatewaySettings', beaconGatewaySettings);
  }*/

  saveBeaconGatewaySetting(url,RFIDGatewaySettings) {
    return this.http.post(url +'/beaconGatewaySettings', RFIDGatewaySettings);
  }


}

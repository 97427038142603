import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { EChartDataType} from '../e-charts/e-charts-data-type';

@Component({
  selector: 'app-sys-stats-graph',
  templateUrl: './sys-stats-graph.component.html',
  styleUrls: ['./sys-stats-graph.component.scss']
})
export class SysStatsGraphComponent implements OnInit {
  @Input() chartData: EChartDataType;
  @Input() loader: boolean = false;
  @Input() show: boolean = false;
  @Input() isPillTabToggleRequired: boolean;
  @Input() pillActiveColour: string;
  @Input() hardwarePropertyType: string;
  @Input() graphTitle: string;
  @Input() defaultActivePillIndex: number;
  @Input() arrayOfPills: string;
  @Output() graphDataWithSeriesType: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  getActivePillValue(value) {
    let obj: any;
    obj = {};
    obj['field'] = this.hardwarePropertyType;
    obj['duration'] = value;
    this.graphDataWithSeriesType.emit(obj);
  }
}

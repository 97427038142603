import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';
import { Role } from '../state/SuperAdminSettings/interfaces/superadminsettings.interface';

@Injectable()
export class RoleManagementService {
  private _roleConfig: any = [];
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  getRoleData(name) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'role/' + name);
  }

  getRoleMetaData() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'role/meta/get');
  }

  getRoleConfig(): any {
    return new Promise((resolve,) => {
      if (this._roleConfig.length === 0) {
        this.getRoleMetaData()
          .subscribe(roleConfig => {
            this.setRoleConfig(roleConfig);
            resolve(this._roleConfig);
          });
      } else {
        resolve(this._roleConfig);
      }
    });
  }

  setRoleConfig(roleConfig: any) {
    this._roleConfig = roleConfig;
  }

  resetRoleConfigValue() {
    this._roleConfig = [];
  }

  saveRoleData() {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'role' , "roleData");
  }

  deleteRole(roleID) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'role/' + roleID);
  }

  updateRole(roleId, roleData) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'role/' + roleId , roleData);
  }

  getRolesForAccount(accountId){
    return this.http.get(this.configService.appConfig.appBaseUrl + 'accountRole/?accountId=' + accountId);
  }

  saveRoleDataForAccount(roleData, accountId) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'accountRole?accountId=' + accountId , roleData);
  }

  updateRoleDataForAccount(roleData, roleID) {
    return this.http.put<ISuccessResponse>(`${this.configService.appConfig.appBaseUrl}accountRole/id/${roleID}` , roleData);
  }

  getRoleMetaDataForAccount(accountId) {
    return this.http.get<Role>(`${this.configService.appConfig.appBaseUrl}account/modules/metadata?accountId=${accountId}`);
  }

  getRoleDataForAccount(roleID) {
    return this.http.get<Role>(`${this.configService.appConfig.appBaseUrl}accountRole/id/${roleID}`);
  }

  deleteRoleForAccount(roleID) {
    return this.http.delete<ISuccessResponse>(`${this.configService.appConfig.appBaseUrl}accountRole/id/${roleID}` );
  }
}
export interface ISuccessResponse{
  msg?: string
}
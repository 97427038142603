import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {sortBy} from 'lodash';

@Component({
  selector: 'app-server-details-card',
  templateUrl: './server-details-card.component.html',
  styleUrls: ['./server-details-card.component.scss']
})
export class ServerDetailsCardComponent implements OnInit, OnChanges {
  @Input() assetData: any;
  public fsSize: any;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'assetData') {
        if (change.currentValue !== change.previousValue) {
          if (this.assetData.stats) {
            this.formateStatsData(this.assetData.stats);
            if (this.assetData.stats.fsSize && this.assetData.stats.fsSize.length > 0) {
              this.formateFsSizeArray(this.assetData.stats.fsSize);
            }
          }
        }
      }
    }
  }

  formateFsSizeArray(fsSizeArray) {
    let sortedFsSize = [];
    sortedFsSize =  sortBy(fsSizeArray, [function(o) { return o.size; }]);
    this.fsSize = sortedFsSize[sortedFsSize.length - 1];
    this.fsSize.size = this.formatBytesToGb(this.fsSize.size);
    this.fsSize.used = this.formatBytesToGb(this.fsSize.used);
  }

  formateStatsData(stats) {
    if (stats.mem) {
      stats.mem.used = this.formatBytesToGb(stats.mem.used);
      stats.mem.total = this.formatBytesToGb(stats.mem.total);
    }
    if (stats.currentLoad) {
      stats.currentLoad.avgload = this.getTwoDecimalPoints(stats.currentLoad.avgload);
      stats.currentLoad.currentload = this.getTwoDecimalPoints(stats.currentLoad.currentload);
    }
    if (stats.fullLoad) {
      stats.fullLoad = this.getTwoDecimalPoints(stats.fullLoad);
    }
  }

  formatBytesToGb(bytes) {
    return ((bytes / (1024 * 1024 * 1024)).toFixed(2));
  }

  getTwoDecimalPoints(value) {
    return (value.toFixed(2));
  }
}

<div class="linkedIconsGrid">
  <ul>
    <!--<li *ngIf="assetLinkedDetails.car"><img class="icon-color" src="../../assets/asset-icons/car.svg"><span class="badge">{{assetLinkedDetails.car.length}}</span></li>
    <li *ngIf="assetLinkedDetails.driver"><img class="icon-color" src="../../assets/asset-icons/driver.svg"><span class="badge">{{assetLinkedDetails.driver.length}}</span></li>
    <li *ngIf="assetLinkedDetails.beacon"><img class="icon-color" src="../../assets/asset-icons/beacon.svg"><span class="badge">{{assetLinkedDetails.beacon.length}}</span></li>
    <li *ngIf="assetLinkedDetails.rfId"><img class="icon-color" src="../../assets/asset-icons/rfId.svg"><span class="badge">{{assetLinkedDetails.rfId.length}}</span></li>
    <li *ngIf="assetLinkedDetails.obdDevice"><img class="icon-color" src="../../assets/asset-icons/obd.svg"><span class="badge">{{assetLinkedDetails.obdDevice.length}}</span></li>
    <li *ngIf="assetLinkedDetails.beaconGateway"><img class="icon-color" src="../../assets/asset-icons/beaconGateWay-black.png"><span class="badge">{{assetLinkedDetails.beaconGateway.length}}</span></li>
    <li *ngIf="assetLinkedDetails.rfidGateway"><img class="icon-color" src="../../assets/asset-icons/rfIdGateWay-black.png"><span class="badge">{{assetLinkedDetails.rfidGateway.length}}</span></li>
    <li *ngIf="assetLinkedDetails.general"><img class="icon-color" src="../../assets/asset-icons/general.png"><span class="badge">{{assetLinkedDetails.general.length}}</span></li>
    <li *ngIf="assetLinkedDetails.server"><img class="icon-color" src="../../assets/asset-icons/server-black.png"><span class="badge">{{assetLinkedDetails.server.length}}</span></li>
    <li *ngIf="assetLinkedDetails.door"><img class="icon-color" src="../../assets/asset-icons/door.png"><span class="badge">{{assetLinkedDetails.door.length}}</span></li>
    <li *ngIf="assetLinkedDetails.fingerPrintSensor"><img class="icon-color" src="../../assets/asset-icons/fingerPrintSensor.png"><span class="badge">{{assetLinkedDetails.fingerPrintSensor.length}}</span></li>
    <li *ngIf="assetLinkedDetails.camera"><img class="icon-color" src="../../assets/asset-icons/camera.png"><span class="badge">{{assetLinkedDetails.camera.length}}</span></li>
    <li *ngIf="assetLinkedDetails.person"><img class="icon-color" src="../../assets/asset-icons/person.png"><span class="badge">{{assetLinkedDetails.person.length}}</span></li>-->
    <!-- {{badgeColors | json}} -->
    @for (key of Object.keys(assetLinkedDetails); track key) {
      <li [pTooltip]="badgeColors[key]['tooltip']" tooltipPosition="top"
          [attr.data-bg-color]="badgeColors[key]['color']" class="asset-linked-container">
        <!-- <i pBadge [value]="assetLinkedDetails[key].length" [ngClass]="{ 'p-badge': {'background' : badgeColors[key]['color']}}"> -->
        <img [ngSrc]="assetLinkedDetails[key]?.icon" alt="icon" width="500" height="250" priority>
      <!-- </i> -->
        @if (assetLinkedDetails[key].length; as count) {
          <div>
            <p-badge [style]="{'background' : badgeColors[key]['color']}"
                     [value]="count > 99 ? '99+' : count"></p-badge>
          </div>
        }
    </li>
    }
  </ul>
</div>

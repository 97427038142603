import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CONSTANT} from 'config/constant';
import moment from 'moment-timezone';
import {LangUtilService} from 'app/util/lang-util.service';
import {NgrxSocketService} from "state/Socket/ngrx-socket.service";

declare let $: any;
const maxTimeOut = 5;
const nameSpaceString = 'cameraLiveFeed_';

interface VideoElement extends HTMLVideoElement {
  disablePictureInPicture: boolean;
  requestFullscreen(): any;
  webkitRequestFullscreen(): any;
}

@Component({
  selector: 'app-video-feed',
  templateUrl: './video-feed.component.html',
  styleUrls: ['./video-feed.component.scss']
})

export class VideoFeedComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild("videoElement") videoElement: ElementRef;
  @Input() nameSpace: string;
  @Input() camera: any;
  @Output() disConnectedCamera: EventEmitter<string> = new EventEmitter();
  connection = {};
  videoElementObj: VideoElement;
  liveVideo = {};
  allTimerObject = {};
  timer = {
    isInitiate: false,
    value: null
  };
  clearTimer = {};
  isConnectionEstablished: boolean;
  showCircularLoader: boolean;
  isResumeCameraRequired: boolean;
  isCameraDisconnectRequired: boolean;
  popUpMsg: string;
  disconnectPopUpMsg: string;

  constructor(private socket: NgrxSocketService, private lang: LangUtilService) {
    this.showCircularLoader = false;
    this.isConnectionEstablished = false;
    this.isResumeCameraRequired = false;
    this.isCameraDisconnectRequired = false;
    this.popUpMsg = '';
  }

  ngOnInit(): void {
    this.showCircularLoader = true;
    this.connectToNameSpace(this.nameSpace);
  }

  ngAfterViewInit() {
    this.videoElementObj = this.videoElement.nativeElement;
    this.videoElementObj.disablePictureInPicture = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'nameSpace') {
        if (change.currentValue !== change.previousValue) {
          this.nameSpace = nameSpaceString + this.nameSpace;
        }
      }
    }
  }

  ngOnDestroy() {
    this.disconnectFromNameSpace(this.nameSpace);
  }

  closePopup() {
    this.isResumeCameraRequired = false;
    this.disConnectedCamera.emit(this.nameSpace);
  }

  setTimer(nameSpace: string) {
    const eventTime = 1588703700; // Timestamp - Wed, 06 May 2020 00:05:00 GMT
    // let eventTime= 1588876260; // Timestamp - Wed, 06 May 2020 01:00:00 GMT
    const currentTime = 1588703400; // Timestamp - Wed, 06 May 2020 00:00:00 GMT
    // let currentTime = 1588876200; // Timestamp - Wed, 06 May 2020 00:00:00 GMT
    const diffTime = eventTime - currentTime;
    let duration = moment.duration(diffTime * 1000, 'milliseconds');
    const interval = 1000;

    if (this.clearTimer[nameSpace]) {
      clearInterval(this.clearTimer[nameSpace]);
      this.clearTimer[nameSpace] = null;
    }

    this.clearTimer[nameSpace] = setInterval(() => {
      duration = moment.duration(duration.milliseconds() - interval, 'milliseconds');
      let min: string | number = duration.minutes();
      let sec: string | number = duration.seconds();

      if (duration.minutes() >= 0 && duration.minutes() <= 9) {
        min = '0' + duration.minutes();
      }
      if (duration.seconds() >= 0 && duration.seconds() <= 9) {
        sec = '0' + duration.seconds();
      }
      this.timer.value = min + ':' + sec;
    }, interval);
  }

  connectToNameSpace(nsp: string) {
    this.showCircularLoader = true;
    this.isResumeCameraRequired = false;
    this.setTimerTimeOut(nsp);

    if (this.connection[nsp]) {
      this.connection[nsp].close();
    }

    this.socket.emit(nsp, {nameSpaceDetails: {name: nsp}});
    this.connection[nsp] = this.socket.conn(nsp);

    this.connection[nsp].on('connect', () => {
      this.initVideoListener(nsp);
      this.timer.isInitiate = true;
    });

    this.connection[nsp].on('disconnect', () => {
      this.timer.value = '';
      this.isConnectionEstablished = this.connection[nsp].connected;
      this.showCircularLoader = false;
      this.isResumeCameraRequired = true;
      this.popUpMsg = this.lang.getTranslation('app.security.conn-fail-retry');
      if (this.clearTimer[nsp]) {
        clearInterval(this.clearTimer[nsp]);
        this.clearTimer[nsp] = null;
        this.timer.isInitiate = false;
      }
      /*if (that.liveVideoObject[nameSpace]) {
        that.liveVideoObject[nameSpace].video.pause();
        that.liveVideoObject[nameSpace] = null;
      }
      that.disconnectFromNameSpace(nameSpace);*/
    });

    /*if (!this.liveFeedSocketConnection[nameSpace]) {
      this.liveFeedSocketConnection[nameSpace] = io.connect(socketConnection, {reconnection: true});
      this.liveFeedSocketConnection[nameSpace].on('connect', function (socketInstance) {
        const connectionStatus = that.liveFeedSocketConnection[nameSpace].connected;
        console.log(nameSpace, 'Name Space Connection Status ==> is Connection Established', connectionStatus);
        console.log('initVideoListener connection block----------');
        that.initVideoListener(nameSpace);
      });
      this.liveFeedSocketConnection[nameSpace].on('disconnect', function (socketInstance) {
        console.log('NameSpace disconnected ==>', nameSpace);
      });
    } else {
      if (this.liveFeedSocketConnection[nameSpace] && this.liveFeedSocketConnection[nameSpace].disconnected) {
        this.liveFeedSocketConnection[nameSpace].open(socketConnection);
        const connectionStatus = this.liveFeedSocketConnection[nameSpace].connected;
        console.log('Name Space', nameSpace, 'Connection Status is ==>', connectionStatus);
      }
    }*/

    this.connection[nsp].initFlag = false;

    /*if (this.liveVideoObject[nameSpace] && this.liveVideoObject[nameSpace].video) {
      this.liveVideoObject[nameSpace].video.play();
    } else {
      console.log('initVideoListener in else block');
      this.initVideoListener(nameSpace);
    }*/

    this.connection[nsp].on(CONSTANT.SOCKET.TOPICS.CAMERA_LIVE_FEED_STATUS, (status) => {
      this.isConnectionEstablished = status.connection;
    });

    this.connection[nsp].on('error', () => {
      // console.log('errrrrorrrr----', error);
    });
  }

  disconnectFromNameSpace(nsp: string) {
    this.isConnectionEstablished = false;
    this.showCircularLoader = false;

    if (this.connection[nsp]) {
      this.connection[nsp].close();
    }

    if (this.liveVideo[nsp] && this.liveVideo[nsp].video) {
      this.liveVideo[nsp].video.pause();
    }

    if (this.connection[nsp] && this.connection[nsp].initFlag) {
      this.connection[nsp].initFlag = false;
    }
  }

  initVideoListener(camName: string) {
    if (!this.liveVideo[camName]) {
      this.liveVideo[camName] = {}
    }

    this.liveVideo[camName].mime = 'video/mp4; codecs="avc1.4D0029"';
    if (!MediaSource.isTypeSupported(this.liveVideo[camName].mime)) {
      alert('Unsupported mime type');
      return;

    }

    this.liveVideo[camName].video = document.getElementById(camName);
    this.liveVideo[camName].mediaSource = new MediaSource();
    this.liveVideo[camName].video.src = URL.createObjectURL(this.liveVideo[camName].mediaSource);

    this.liveVideo[camName].mediaSource.addEventListener('sourceended', console.log);

    this.liveVideo[camName].mediaSource.addEventListener('sourceclose', () => {
      //todo remove( buffer = mediaSource.addSourceBuffer(mime))
    });

    this.liveVideo[camName].mediaSource.addEventListener('error', console.error);

    this.liveVideo[camName].mediaSource.addEventListener('sourceopen', () => {
      this.liveVideo[camName]['buffer'] = this.liveVideo[camName].mediaSource.addSourceBuffer(this.liveVideo[camName].mime);
      this.liveVideo[camName]['buffer'].mode = 'sequence';

      this.liveVideo[camName]['buffer'].addEventListener('update', console.log);

      this.liveVideo[camName]['buffer'].addEventListener('updateend', () => {
        //hack to get safari on mac to start playing, video.currentTime gets stuck on 0
        if (this.liveVideo[camName].mediaSource.duration !== Number.POSITIVE_INFINITY &&
          this.liveVideo[camName].video.currentTime === 0 &&
          this.liveVideo[camName].mediaSource.duration > 0) {
          this.liveVideo[camName].video.currentTime = this.liveVideo[camName].mediaSource.duration - 1;
          this.liveVideo[camName].mediaSource.duration = Number.POSITIVE_INFINITY;
        }
        this.liveVideo[camName].video.play();
      });

      this.connection[camName].on(CONSTANT.SOCKET.TOPICS.CAMERA_LIVE_FEED, (data: {
        timeStampAtServer: string;
        initSegment: Iterable<number>;
        segment: Iterable<number>;
      }) => {
        const diff = new Date().getTime() - Date.parse(data.timeStampAtServer);
        console.log('time diff', diff);
        if (diff < 2000) {
          console.log('updating-------', this.liveVideo[camName]['buffer'].updating);
          if (!this.liveVideo[camName]['buffer'].updating) {
            if (!this.connection[camName].initFlag) {
              this.connection[camName].initFlag = true;
              const initSegment = new Uint8Array(data.initSegment);
              this.liveVideo[camName]['buffer'].appendBuffer(initSegment);
              // let segment = new Uint8Array(data.segment);
              // that.liveVideoObject[camName]['buffer'].appendBuffer(segment);
              this.showCircularLoader = false;
            } else {
              console.log('$$$$ frame is been pushed $$$$$$');
              const segment = new Uint8Array(data.segment);
              this.liveVideo[camName]['buffer'].appendBuffer(segment);
              this.showCircularLoader = false;
            }
          }
        } else {
          console.log('frame is been skipped');
        }

        if (this.timer.isInitiate) {
          this.timer.isInitiate = false;
          this.setTimer(this.nameSpace);
        }
      });
    }, false);
  }

  resetTimeout() {
    this.timer.value = '';
    if (this.isConnectionEstablished) {
      this.setTimerTimeOut(this.nameSpace);
      this.setTimer(this.nameSpace);
    }
  }

  setTimerTimeOut(nameSpace: string) {
    if (this.allTimerObject[nameSpace]) {
      clearTimeout(this.allTimerObject[nameSpace]);
      this.allTimerObject[nameSpace] = null;
    }
    this.allTimerObject[nameSpace] = setTimeout(() => {
      this.isResumeCameraRequired = true;
      this.popUpMsg = this.lang.getTranslation('app.security.timeout-resume-warn');
      this.disconnectFromNameSpace(nameSpace);
      // eslint-disable-next-line max-len
      // const msg = that.lang.getTranslation('app.security.auto-disconn-cam') + ' ' + nameSpace + ' ' + that.lang.getTranslation('app.security.app.security.it-was-connected-for')+' ' + maxTimeOut + ' ' +this.lang.getTranslation('sys.minutes');
    }, 1000 * 60 * maxTimeOut);
  }

  toggleConnection() {
    if (this.isConnectionEstablished) {
      this.disconnectPopUpMsg = this.lang.getTranslation('app.security.disconnect-confirm');
      this.isCameraDisconnectRequired = true;
      // this.disconnectFromNameSpace(this.nameSpace);
    } else {
      this.connectToNameSpace(this.nameSpace);
    }
  }

  disconnectSelectedNameSpace() {
    this.timer.value = '';
    this.disconnectFromNameSpace(this.nameSpace);
    this.isCameraDisconnectRequired = false;
    setTimeout(() => {
      this.isResumeCameraRequired = false;
    }, 50);
  }

  getTimerTooltip(): string {
    if (this.isConnectionEstablished) {
      return `${this.lang.getTranslation('app.security.stream-auto-disconnect')} ${this.timer.value} mins.
  ${this.lang.getTranslation('app.security.click-to-reset')}.`;
    } else {
      return '';
    }
  }

  openFullscreen() {
    if (this.videoElementObj.requestFullscreen) {
      this.videoElementObj.requestFullscreen();
    } else if (this.videoElementObj.webkitRequestFullscreen) { /* Safari */
      this.videoElementObj.webkitRequestFullscreen();
    }
  }
}

/**
 * Created by chandru on 16/10/18.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from "./config.service";

@Injectable()
export class ServiceLinkableService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  // getAllServiceAssets() {
  //   return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssets/' );
  // }

  getCountsForServiceAssets(type?) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'serviceAssets/count?';
    if (type) {
      queryUrl = queryUrl + 'type=' + type;
    }
    return this.http.get(queryUrl);
  }

  getDataForServiceAssets(skip, limit, type?) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'serviceAssets/' + '?skip=' + skip + '&limit=' + limit;
    if (type) {
      queryUrl = queryUrl + '&type=' + type;
    }
    return this.http.get(queryUrl);
  }

  getServiceAssetDetailsById(serviceAssetId) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssets/id/' + serviceAssetId);
  }

  saveServiceAssetDetails(serviceAssetData) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'serviceAssets/', serviceAssetData);
  }
  updateServiceById(serviceId, serviceDetails) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'serviceAssets/' + serviceId, serviceDetails);
  }
  getServiceAssetConfigrationTypes() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssetsConfig/types' );
  }

  getServiceAssetConfig(serviceAssetType) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssetsConfig?type=' + serviceAssetType);
  }

  deregisterServiceAsset(serviceAssetId) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'serviceAssets/id/' + serviceAssetId);
  }
  linkAsset(linkAssetDetails) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'serviceAssets/link/', linkAssetDetails);
  }
  delinkAsset(delinkAssetDetails) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'serviceAssets/deLink/', delinkAssetDetails);
  }
  manageServices(action) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'serviceAssets/manage', action);
  }
  getServicesHealth(start, end, id?: number) {
    if (id) {
      return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssets/status/' + start + '/' + end + '/' + id );
    } else {
      return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssets/status/' + start + '/' + end );
    }
  }
  getRoleConfigrationTypes() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'role/' );
  }

  getServiceStatus(serviceId, duration?) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'serviceAssets/serviceStatus/' + serviceId;
    if (duration) {
      queryUrl = queryUrl + '?duration=' + duration;
    } else {
      queryUrl = queryUrl + '?duration=' + 10;
    }
    return this.http.get(queryUrl);
  }

  getAllServicesToManage() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'serviceAssets/manage/availableServices' );
  }

  deployServiceOnServer(data) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'serviceAssets/manage/deploy', data);
  }
}

/**
 * Created by suhas on 28/3/19.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class GeofenceService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  getAll() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'geoFence/');
  }
  getById(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'geoFence/id/' + id);
  }
  updateById(id , data ) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'geoFence/id/' + id , data);
  }
  deleteById( id ) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'geoFence/id/' + id );
  }
  add( newGeoFenceData ) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'geoFence/', newGeoFenceData );
  }
  getAllGeoFenceAssetCount() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'geoFence/allGeoFenceAssetCount');
  }
  getCarDwellingTime(startTime, endTime, vinNumber) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'geoFence/allGeoFence/dwellingTime/' + startTime + '/' + endTime + '/' + vinNumber);
  }
  getAggregatedCarDwellingTime(startTime, endTime, vinNumber, series? , timeZone? ) {
    if (!series) {
      series = 'hour';
    }
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return this.http.get(this.configService.appConfig.appBaseUrl + 'geoFence/allGeoFence/dwellingTimeAggregated/' + startTime + '/' +
      endTime + '/' + vinNumber+ '?series=' + series + '&timezone=' + timeZone );
  }
  updateGeoFenceDetails( id ) {
    return this.http.put(this.configService.appConfig.appBaseUrl + 'geoFence/maintenanceBay/', id );
  }
  getAssetsForGeoFenceById(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'geoFence/all/assets/geoFenceId/' + id);
  }
}

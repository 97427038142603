import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-thermometer-gauge',
  templateUrl: './thermometer-gauge.component.html',
  styleUrls: ['./thermometer-gauge.component.scss']
})
export class ThermometerGaugeComponent implements OnChanges, OnInit {
  public option;
  @Input() value: number;
  @Input() max: number;
  @Input() min: number;
  @Input() resetRandomNumber: number;
  @Input() graphTitle: any;
  public showGauge: boolean;
  constructor() {
    this.showGauge = false;
    this.value = 0;
    this.max = 60;
    this.min = 0;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'value') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.drawGraph();
        }
      }
      if (propName === 'resetRandomNumber') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.resetValues();
        }
      }
    }
  }

  resetValues() {}

  drawGraph() {
    this.showGauge = true;
    this.option = {
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 200,
          endAngle: -20,
          min: this.min,
          max: this.max,
          splitNumber: 12,
          itemStyle: {
              color: '#FFAB91'
          },
          progress: {
            show: true,
            width: 10
          },
          pointer: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 10
            }
          },
          axisTick: {
            distance: -25,
            splitNumber: 5,
            lineStyle: {
              width: 1,
              color: '#999'
            }
          },
          splitLine: {
            distance: -32,
            length: 14,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          axisLabel: {
            distance: -25,
            color: '#999',
            fontSize: 12
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '-15%'],
            fontSize: 30,
            fontWeight: 'bolder',
            formatter: '{value} °C',
            color: 'auto'
          },
          data: [
            {
              value: 0
            }
          ]
        },
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 200,
          endAngle: -20,
          min: this.min,
          max: this.max,
          itemStyle: {
            color: '#FD7347'
          },
          progress: {
            show: true,
            width: 3
          },
          pointer: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          detail: {
            show: false
          },
          data: [
            {
              value: 0
            }
          ]
        }
      ]
    };
    this.setData();
  }

  setData() {
    this.option.series[0].data[0].value = this.value;
    this.option.series[1].data[0].value = this.value;
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {AssetService} from "../../../services/asset.service";
import {ServiceLinkableService} from '../../../services/serviceLinkable.service';
import {ConfigService} from '../../../services/config.service';

declare  var $: any;
import {forEach} from 'lodash';
import {LinkedAssetIconService} from '../../../services/linked-asset-icon.service';

@Component({
  selector: 'app-search-for-service',
  templateUrl: './search-for-service.component.html',
  styleUrls: ['./search-for-service.component.scss']
})
export class SearchForServiceComponent implements OnChanges, OnInit {
  @Input() serviceAssetConfigDetails: any;
  @Input() resetSearchedServicesOnPlusClick: number;
  @Input() assetData: any;
  @Input() resetSearchedAssetOnBackBtn: number;
  @Output() searchedAssets: EventEmitter<any> = new EventEmitter();
  searchForAsset: string = '';
  searchedAssetForLinking: any = [];
  assetTypeForSearch: string = '';
  assetLabel: string = '';
  assetNotFound: boolean = false;
  linkableAssets: any;
  defaultAssetForDropDown = {};
  public linkableAssetsLabelWithType: any;
  constructor(public assetService: AssetService, public configService: ConfigService,
              private  serviceLinkableService: ServiceLinkableService, private linkedAssetIconService: LinkedAssetIconService) {
    this.linkableAssetsLabelWithType = [];
    this.linkableAssets = [];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'assetData') {
        if (change.currentValue !== change.previousValue) {
          this.linkableAssetsLabelWithType = this.getServicesLabelsWithTypes(this.serviceAssetConfigDetails);
          this.setdefaultAssetForDropDown();
        }
      }
      /*if (propName === 'serviceAssetConfigDetails') {
        console.log('this.asset )))))))))))))______---', this.serviceAssetConfigDetails);
        if (this.assetData && this.assetData.assetType) {
          if (change.currentValue !== change.previousValue) {
            this.getLinkableAssetListForAsset();
          }
        } else if (this.assetData && this.assetData.serviceType) {
          if (change.currentValue !== change.previousValue) {
            this.getLinkableAssetListForServiceAsset();
          }
        }
      }*/
      if (propName === 'resetSearchedServicesOnPlusClick') {
        if (change.currentValue !== change.previousValue) {
          this.resetSearchedAssets(true);
        }
      }
      if (propName === 'resetSearchedAssetOnBackBtn') {
        if (change.currentValue !== change.previousValue) {
          this.resetSearchedAssets(false);
        }
      }
    }
  }

  /*getLinkableAssetListForAsset() {
    let linkableAssetObject: any = {};
    let selectedAsset: string = '';
    selectedAsset = this.assetData.assetType ? this.assetData.assetType : '';
    if (this.serviceAssetConfigDetails && this.serviceAssetConfigDetails.length > 0) {
      this.linkableAssetsLabelWithType = [];
      this.linkableAssets = [];
      linkableAssetObject = _.find(this.serviceAssetConfigDetails, ['type', selectedAsset]);
      this.linkableAssets = linkableAssetObject['config']['assetsLinkable'];
      this.linkableAssetsLabelWithType = this.getAssetsLabelsWithTypes(this.linkableAssets, this.serviceAssetConfigDetails);
      if (this.linkableAssetsLabelWithType.length > 0) {
        this.assetLabel = this.linkableAssetsLabelWithType[0]['label'];
      }
    }
  }*/
  /*getLinkableAssetListForServiceAsset() {
    let linkableAssets: any = [];
    if (this.serviceAssetConfigDetails && this.serviceAssetConfigDetails.length > 0) {
      this.linkableAssetsLabelWithType = [];
      this.linkableAssets = [];
      _.forEach(this.serviceAssetConfigDetails, function(value) {
        if (value && value.config && value.config.isServiceLinkable === true) {
          linkableAssets.push(value.type);
        }
      });
      this.linkableAssets = linkableAssets;
      this.linkableAssetsLabelWithType = this.getAssetsLabelsWithTypes(linkableAssets, this.serviceAssetConfigDetails);
      if (this.linkableAssetsLabelWithType.length > 0) {
        this.assetLabel = this.linkableAssetsLabelWithType[0]['label'];
      }
    }
  }*/
  setdefaultAssetForDropDown() {
    if (this.linkableAssetsLabelWithType.length > 0) {
      this.linkedAssetIconService.getIconAndColorsForLinkedAssets(this.linkableAssetsLabelWithType, this.serviceAssetConfigDetails);
      this.defaultAssetForDropDown['assetLabel'] = this.linkableAssetsLabelWithType[0]['label'];
      if (this.linkableAssetsLabelWithType[0] && this.linkableAssetsLabelWithType[0]['icon'] && this.linkableAssetsLabelWithType[0] && this.linkableAssetsLabelWithType[0]['color']) {
        this.defaultAssetForDropDown['icon'] = this.linkableAssetsLabelWithType[0]['icon'];
        this.defaultAssetForDropDown['color'] = this.linkableAssetsLabelWithType[0]['color'];
      }
    }
  }

  getServicesLabelsWithTypes(serviceAssetConfigDetails ) {
    let labelObj: any;
    let linkableAssetsLabelWithType: any = [];
    forEach(serviceAssetConfigDetails, function(value) {
      labelObj = {};
      labelObj = value;
      if (labelObj) {
        let obj: any = {};
        obj = {
          'type': labelObj.type,
          'label': labelObj.label
        };
        linkableAssetsLabelWithType.push(obj);
      }
    });
    return linkableAssetsLabelWithType;
  }

  searchAsset(event) {
    this.assetNotFound = false;
    this.searchForAsset = event;
    // urlForSearch = this.assetTypeForSearch + '/' + this.searchForAsset;
    if (this.searchForAsset) {
      this.assetService.searchForAssetFromassetType(this.searchForAsset, this.assetTypeForSearch, 0, 12)
        .subscribe(searchResult => {
          this.searchedAssetForLinking = searchResult;
          if (this.searchedAssetForLinking.length === 0) {
            this.assetNotFound = true;
          }
          this.searchedAssets.emit(this.searchedAssetForLinking);
        });
    } else {
      this.searchedAssetForLinking = [];
      this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
    }
  }

  getLastTenAssetForSelectedType(type) {
    this.getAllServiceAssets(type);
  }

  getAllServiceAssets(type) {
    // this.serviceLinkableService.getAllServiceAssets()
    //   .subscribe(serviceAssets => {
    //     this.searchedAssetForLinking = serviceAssets;
    //     this.searchedAssets.emit(this.searchedAssetForLinking);
    //   });
    this.serviceLinkableService.getCountsForServiceAssets(type)
      .subscribe((result) => {
        if (result && result['count'] > 0 ) {
          this.serviceLinkableService.getDataForServiceAssets(0, 10, type)
            .subscribe((serviceAssets) => {
              this.assetNotFound = false;
              this.searchedAssetForLinking = serviceAssets;
              this.searchedAssets.emit(this.searchedAssetForLinking);
            });
        } else {
          this.searchedAssetForLinking = [];
          this.assetNotFound = true;
          this.searchedAssets.emit(this.searchedAssetForLinking);
        }
      });
  }

  setSelectedAssetType(asset) {
    this.assetTypeForSearch = asset.type;
    this.defaultAssetForDropDown['assetLabel'] = asset.label;
    if (asset && asset['icon'] && asset['color']) {
      this.defaultAssetForDropDown['icon'] = asset.icon;
      this.defaultAssetForDropDown['color'] = asset.color;
    }
    this.searchedAssetForLinking = [];
    this.searchForAsset = '';
    this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
  }

  resetSearchedAssets(getLastTenAssets: boolean) {
    this.searchedAssetForLinking = [];
    this.searchedAssets.emit(this.searchedAssetForLinking);
    this.searchForAsset = '';
    this.assetTypeForSearch = this.linkableAssets[0] ? this.linkableAssets[0] : '';
    if (this.linkableAssetsLabelWithType.length > 0) {
      this.defaultAssetForDropDown['assetLabel'] = this.linkableAssetsLabelWithType[0]['label'];
    }
    if (getLastTenAssets) {
      this.getAllServiceAssets(this.linkableAssetsLabelWithType[0]['type']);
    }
  }
}

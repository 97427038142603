import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";


export const selectProfile = (state:AppState) => state.userProfile


export const getUserProfileDetails = createSelector(
    selectProfile,
    (userProfile) => userProfile.profile
)

export const getUserLanguage = createSelector(
    selectProfile,
    (userProfile) => userProfile.language
)

export const getAllLangList = createSelector(
    selectProfile,
    (userProfile) => userProfile.allLangList
)

export const getAllTimeZones = createSelector(
    selectProfile,
    (userProfile) => userProfile.timezones
)

export const getUpdatePasswordResponse = createSelector(
    selectProfile,
    (userProfile) => userProfile.updatePassword.res
)

export const getUpdateUserDetailsResponse = createSelector(
    selectProfile,
    (userProfile) => userProfile.updateUserDetails.res
)

export const getPushNotificationStatus = createSelector(
    selectProfile,
    (userProfile) => userProfile?.profile?.userNotifications?.pushNotification
) 
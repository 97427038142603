/**
 * Created by chandru on 22/11/21.
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'namingConvention',
  standalone: true,
})
export class NamingConventionFilterPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case 'inProgress':
        value = 'In Progress';
        break;
      case 'notStarted':
        value = 'Not Started';
        break;
      case 'pickedUp':
        value = 'Picked Up';
        break;
      case 'deliveryPartnerDeclinedPickedUp':
        value = 'Picked Up Declined';
        break;
      case 'deliveryPartnerRejectedOrder':
        value = 'Rejected';
        break;
      case 'deliveryPartnerAcceptedOrder':
        value = 'Accepted';
        break;
      case 'deliveryPartnerReturnedOrder':
        value = 'Returned';
        break;
      case 'deliveryAcknowledgedByProcessor':
        value = 'Delivery Acknowledged To Store';
        break;
      case 'dispatchedByProcessor':
        value = 'Pick Up Acknowledged To Processor';
        break;
      case 'processed':
        value = 'Order Processed By Store';
        break;
      case 'processing':
        value = 'Order Sent To Store';
        break;
      case 'acceptedForProcessing':
        value = 'Store Accepted Order For Processing';
        break;
      case 'cancelledByProcessor':
        value = 'Order Processing Cancelled By Store';
        break;
      case 'deliveryOnHold':
        value = 'On Hold';
        break;
      case 'deliveryInProgress':
        value = 'In Progress';
        break;
      case 'orderStatusDetails':
        value = 'Status';
        break;
      case 'notPaid':
        value = 'Not Paid';
        break;
      case 'nonVeg':
        value = 'Non Veg';
        break;
      case 'lp':
        value = 'Logistic Provider';
        break;
      default:
        value = this.formateCamelCaseToSentence(value);
        break;
    }
    return value;
  }

  formateCamelCaseToSentence(camelCase) {
    if (camelCase) {
      const str = camelCase.toString();
      // adding space between strings
      const result = str.replace(/([a-z])([A-Z])/g, '$1 $2');
      // converting first character to uppercase and join it to the final string
      const final = result.charAt(0).toUpperCase() + result.slice(1);
      return final;
    }
  }
}

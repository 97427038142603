import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {forEach, filter, cloneDeep} from 'lodash';
import { setSidebarState } from '../state/Sidebar/sidebar.action';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SplitScreenService {
  constructor(private store: Store) { }

  ToggleSplitScreen() {
    if ($('#layout-main-content').hasClass( 'split-view-active')) {
      return true;
    } else {
      return false;
    }
  }

  scrollToSelectedCard() {
    // @ts-ignore
    if ($('#layout-main-content').hasClass('split-view-active')) {
      return;
    } else {
      setTimeout(() => {
        this.scrollMainContainer();
      }, 500);
    }
    // else if ($('#main-container').hasClass('split-view-active-lg')) {
    //   return;
    // }

  }

  scrollMainContainer() {
    // let activeClassName: any;
    // if ($('.card').hasClass('active')) {
    //   activeClassName = '.card.active';
    // } else if ($('.card').hasClass('background-active')) {
    //   activeClassName = '.card.background-active';
    // }
    // if(activeClassName) {
    //   $('#layout-main-content').animate({
    //     scrollTop: $(activeClassName).offset().top - 100
    //   }, 'slow');
    // }

    let activeClassName: any;
    if ($('.p-details-card').hasClass('active')) {
      activeClassName = '.p-details-card.active';
    }
    if ($('.p-thing-card').hasClass('active')) {
      activeClassName = '.p-thing-card.active';
    } else if ($('.p-thing-card').hasClass('background-active')) {
      activeClassName = '.p-thing-card.background-active';
    }

    if ($('.card').hasClass('active')) {
        activeClassName = '.card.active';
    } else if ($('.card').hasClass('background-active')) {
        activeClassName = '.card.background-active';
    }

    if(activeClassName) {
      $('#layout-main-content').animate({
        scrollTop: $(activeClassName).offset().top - 100
      }, 'slow');
    }
    
  }

  addActiveClass(data, arrayOfCards, cardUniqueProperty) {
    let dataCopy = cloneDeep(data);
    dataCopy = arrayOfCards.find((item) => {
      return item[cardUniqueProperty] === ( (dataCopy && dataCopy[cardUniqueProperty]) ?  dataCopy[cardUniqueProperty]  : '' );
    });
    if (dataCopy) {
      dataCopy= {...dataCopy,cardClicked:true};
      for (const _ of arrayOfCards) {
        const remainingData = filter(arrayOfCards, function(item) {
          return item[cardUniqueProperty] !== dataCopy[cardUniqueProperty];
        });
        forEach(remainingData, function(item) {
          item={cardClicked:false,embossedFlagStatus:false,...item}
        });
      }
      data = dataCopy;
      return data;
    }

  }

  expandConsignmentAddActiveClass(data, arrayOfCards, cardUniqueProperty) {
    let dataCopy = cloneDeep(data);
    dataCopy[cardUniqueProperty] = dataCopy['orderDocId']

    dataCopy = arrayOfCards.find((item) => {
      return item[cardUniqueProperty] === ( (dataCopy && dataCopy[cardUniqueProperty]) ?  dataCopy[cardUniqueProperty]  : '' );
    });
    if (dataCopy) {
      dataCopy['cardClicked'] = true;
      for (const _ of arrayOfCards) {
        const remainingData = filter(arrayOfCards, function(item) {
          return item[cardUniqueProperty] !== dataCopy[cardUniqueProperty];
        });
        forEach(remainingData, function(item) {
          item['cardClicked'] = false;
          item['embossedFlagStatus'] = false;
        });
      }
      data = dataCopy;
      return data;
    }

  }

  removeActiveClassForAllCards(arrayOfCards) {
    arrayOfCards=arrayOfCards.map(card=>{
      return {...card,cardClicked:false}
    })
  }

  removeMultiSelectedActiveClassForAllCards(arrayOfCards) {
    for (const i in arrayOfCards) {
      arrayOfCards[i]['isAssetSelected'] = false;
    }
  }

  hideModalAndRemoveSplitScreen() {
    // $('#main-container').removeClass('split-view-active');
    // $('#layout-main-content').removeClass('split-view-active');
    // $('#main-container').removeClass('withSmallModal');
    // $('#layout-main-content').removeClass('withSmallModal');
    // $('.custom-modal').removeClass('custom-modal-view').hide(100);
    // $('.custom-modal-sm').removeClass('custom-modal-view').hide(100);
    // $('.create-modal').removeClass('custom-modal-view').modal('hide');
    // $('html').css('overflow-y', 'auto');
    this.store.dispatch(setSidebarState({
      splitState: false, 
      currentActiveId: ''
    }))
  }

  closeCreateModal() {
    this.store.dispatch(setSidebarState({
      splitState: false, 
      currentActiveId: ''
    }))
    // if ($(editModalClass).hasClass('custom-modal-view')) {
    //   $(createModalClass).removeClass('custom-modal-view').modal('hide');
    //   $('#main-tabs-container').removeClass('mat-tab-header-pagination-controls-enabled');
    //   setTimeout(() => {
    //     this.checkForTabsPagination('#main-tabs-container');
    //   }, 500);
    //   return true;
    // } else {
    //   $(createModalClass).removeClass('custom-modal-view').modal('hide');
    //   $('#layout-main-content').removeClass('split-view-active');
    //   $('#layout-main-content').removeClass('withSmallModal');
    //   $('#main-tabs-container').removeClass('mat-tab-header-pagination-controls-enabled');
    //   setTimeout(() => {
    //     this.checkForTabsPagination('#main-tabs-container');
    //   }, 500);
    //   return false;
    // }
  }

  addOrRemoveSplitScreen (mode, targetModal, splitMainScreen = true) {
    if (mode === 'addSplitScreen') {
      this.store.dispatch(setSidebarState({
        splitState: splitMainScreen, 
        currentActiveId: targetModal.substring(1)
      }))
      // $('html').css('overflow-y', 'hidden');
      setTimeout(() => {
        this.checkForTabsPagination('#main-tabs-container');
      }, 500);
    } else if (mode === 'removeSplitScreen') {
      //No longer being called
      // if (targetModal === '.create-modal') {
      //   $(targetModal).removeClass('custom-modal-view').modal('hide');
      this.store.dispatch(setSidebarState({
        splitState: false, 
        currentActiveId: ''
      }))
      // $('html').css('overflow-y', 'auto');
      $('#main-tabs-container').removeClass('mat-tab-header-pagination-controls-enabled');
      setTimeout(() => {
        this.checkForTabsPagination('#main-tabs-container');
      }, 500);
      $('.modal-backdrop').remove();
    }
  }

  checkForTabsPagination(targetElementid) {
    const linksContainerWidth = Math.floor($(targetElementid).children('div.mat-tab-link-container').width());
    const listWidth = Math.floor($(targetElementid).children('div.mat-tab-link-container').children('div.mat-tab-list').width());
    const listElement = $(targetElementid).children('div.mat-tab-link-container').children('div.mat-tab-list');
    const listTransform = listElement.length > 0 ? listElement.offset().left : 0;
    if (listWidth > linksContainerWidth) {
      $(targetElementid).addClass('mat-tab-header-pagination-controls-enabled');
    } else {
      if (listTransform < 0) {
        $(targetElementid).addClass('mat-tab-header-pagination-controls-enabled');
      } else {
        $(targetElementid).removeClass('mat-tab-header-pagination-controls-enabled');
      }
    }
  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {AssetService} from "../../../services/asset.service";
import {ConfigService} from '../../../services/config.service';

declare  var $: any;
import {find, forEach} from 'lodash';
import {LinkedAssetIconService} from '../../../services/linked-asset-icon.service';
import {LangUtilService} from '../../util/lang-util.service';
import { Store } from '@ngrx/store';
import { loadAssetsFromState } from '../../../state/Assets/assets.action';
import { selectedAssetsDataSelector } from '../../../state/Assets/assets.selector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-for-asset',
  templateUrl: './search-for-asset.component.html',
  styleUrls: ['./search-for-asset.component.scss']
})
export class SearchForAssetComponent implements OnChanges, OnInit, OnDestroy {
   @Input() allAssetTypes: any;
   @Input() resetSearchedAssetOnPlusClick: number;
   @Input() assetData: any;
   @Input() config: any;
   @Input() resetSearchedAssetOnBackBtn: number;
   @Input() assetsLimit: number;
   @Output() searchedAssets: EventEmitter<any> = new EventEmitter();
   @Output() assetTypeChange: EventEmitter<any> = new EventEmitter();
   @Output() searchingAsset: EventEmitter<string> = new EventEmitter();
   private selectedAssetsDataSub: Subscription;
  searchForAsset: string = '';
  searchedAssetForLinking: any = [];
  assetTypeForSearch: string = '';
  assetLabel: string = '';
  //TODO: type needed
  defaultAssetForDropDown:any = {};
  assetNotFound: boolean = false;
  linkableAssets: any;
  public linkableAssetsLabelWithType: any;
  constructor(public assetService: AssetService, public configService: ConfigService, private linkedAssetIconService: LinkedAssetIconService,
    private lang: LangUtilService,private store: Store) {
    this.linkableAssetsLabelWithType = [];
    this.linkableAssets = [];
  }

  ngOnInit() {
    this.selectedAssetsDataSub = this.store.select(selectedAssetsDataSelector).subscribe((data) => {
      this.setAssetData(data);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'assetData') {
        if (this.assetData && this.assetData.assetType) {
          if (change.currentValue !== change.previousValue) {
            this.getLinkableAssetListForAsset();
          }
        } else if (this.assetData && this.assetData.serviceType) {
          if (change.currentValue !== change.previousValue) {
            this.getLinkableAssetListForServiceAsset();
          }
        }
      }
      if (propName === 'allAssetTypes') {
        if (this.assetData && this.assetData.assetType) {
          if (change.currentValue !== change.previousValue) {
            this.getLinkableAssetListForAsset();
          }
        } else if (this.assetData && this.assetData.serviceType) {
          if (change.currentValue !== change.previousValue) {
            this.getLinkableAssetListForServiceAsset();
          }
        }
      }
      if (propName === 'resetSearchedAssetOnPlusClick') {
        if (change.currentValue !== change.previousValue) {
          this.resetSearchedAssets(true);
          this.displayElementOnUI=true
        }
      }
      if (propName === 'resetSearchedAssetOnBackBtn') {
        if (change.currentValue !== change.previousValue) {
          this.resetSearchedAssets(false);
          this.displayElementOnUI=false
        }
      }
      if (propName === 'assetsLimit') {
        if (change.currentValue !== change.previousValue) {
         this.store.dispatch(loadAssetsFromState({assetType: this.assetTypeForSearch, skip: 0, limit: this.assetsLimit}));
        }
      }

    }
  }

  displayElementOnUI:boolean

  ngOnDestroy(): void {
    this.selectedAssetsDataSub?.unsubscribe();
  }

  clearSearchedText() {
    this.searchForAsset = '';
    this.searchAsset(this.searchForAsset);
  }

  setAssetData(assets) {
    this.searchedAssetForLinking = assets;
    if (this.searchedAssetForLinking.length === 0) {
      this.assetNotFound = true;
    } else {
      this.assetNotFound = false;
    }
    this.searchedAssets.emit({assets:this.searchedAssetForLinking,isSearched:false});
  }

  getLinkableAssetListForAsset() {
    let linkableAssetObject: any = {};
    let selectedAsset: string = '';
    selectedAsset = this.assetData.assetType ? this.assetData.assetType : '';
    if (this.allAssetTypes && this.allAssetTypes.length > 0) {
      this.linkableAssetsLabelWithType = [];
      this.linkableAssets = [];
      linkableAssetObject = find(this.allAssetTypes, ['type', selectedAsset]);
      this.linkableAssets = linkableAssetObject['config']['assetsLinkable'];
      this.linkableAssetsLabelWithType = this.getAssetsLabelsWithTypes(this.linkableAssets, this.allAssetTypes);
      if (this.linkableAssetsLabelWithType.length > 0) {
        this.linkedAssetIconService.getIconAndColorsForLinkedAssets(this.linkableAssetsLabelWithType, this.config);
        this.defaultAssetForDropDown['assetLabel'] = this.getLangLabel(this.linkableAssetsLabelWithType[0]['label']);
        setTimeout(() => {
          if (this.linkableAssetsLabelWithType && this.linkableAssetsLabelWithType[0] && this.linkableAssetsLabelWithType[0]['icon'] && this.linkableAssetsLabelWithType[0] && this.linkableAssetsLabelWithType[0]['color']) {
            this.defaultAssetForDropDown['icon'] = this.linkableAssetsLabelWithType[0]['icon'];
            this.defaultAssetForDropDown['color'] = this.linkableAssetsLabelWithType[0]['color'];
          }
        }, 500);
      }
    }
  }
  getLinkableAssetListForServiceAsset() {
    let linkableAssets: any = [];
    if (this.allAssetTypes && this.allAssetTypes.length > 0) {
      this.linkableAssetsLabelWithType = [];
      this.linkableAssets = [];
      forEach(this.allAssetTypes, function(value) {
        if (value && value.config && value.config.isServiceLinkable === true) {
          linkableAssets.push(value.type);
        }
      });
      this.linkableAssets = linkableAssets;
      this.linkableAssetsLabelWithType = this.getAssetsLabelsWithTypes(linkableAssets, this.allAssetTypes);
      if (this.linkableAssetsLabelWithType.length > 0) {
        this.linkedAssetIconService.getIconAndColorsForLinkedAssets(this.linkableAssetsLabelWithType, this.config);
        this.defaultAssetForDropDown['assetLabel'] = this.getLangLabel(this.linkableAssetsLabelWithType[0]['label']);
        setTimeout(() => {
          if (this.linkableAssetsLabelWithType[0] && this.linkableAssetsLabelWithType[0]['icon'] && this.linkableAssetsLabelWithType[0] && this.linkableAssetsLabelWithType[0]['color']) {
            this.defaultAssetForDropDown['icon'] = this.linkableAssetsLabelWithType[0]['icon'];
            this.defaultAssetForDropDown['color'] = this.linkableAssetsLabelWithType[0]['color'];
          }
        }, 500);
      }
    }
  }
  getAssetsLabelsWithTypes(linkableAssets, allAssetTypes ) {
    let labelObj: any;
    let linkableAssetsLabelWithType: any = [];
    forEach(linkableAssets, function(value) {
      labelObj = {};
      labelObj = find(allAssetTypes, function(o) { return o.type === value; });
      if (labelObj) {
        let obj: any = {};
        obj = {
          'type': labelObj.type,
          'label': labelObj.label
        };
        linkableAssetsLabelWithType.push(obj);
      }
    });
    return linkableAssetsLabelWithType;
  }

  searchAsset(event) {
    this.assetNotFound = false;
    this.searchForAsset = event;
    // const urlForSearch: string = this.assetTypeForSearch + '/' + this.searchForAsset;
    if (this.searchForAsset) {
      this.assetService.searchForAssetFromassetType(this.searchForAsset, this.assetTypeForSearch, 0, 12)
        .subscribe(searchResult => {
          this.searchedAssetForLinking = searchResult;
          if (this.searchedAssetForLinking.length === 0) {
            this.assetNotFound = true;
          }
          this.searchingAsset.emit(this.searchForAsset)
          this.searchedAssets.emit({assets:this.searchedAssetForLinking,isSearched:true});
        });
    } else {
      this.searchedAssetForLinking = [];
      this.searchingAsset.emit(this.searchForAsset)
      this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
    }
  }

  getLastTenAssetForSelectedType(assetType) {
    // this.assetNotFound = false;
    this.store.dispatch(loadAssetsFromState({assetType: assetType, skip: 0, limit: 10}));
    /* this.assetService.getAllByType(assetType, 0, 10)
      .subscribe(lastTenAssetOfSpecificType => {
        this.searchedAssetForLinking = lastTenAssetOfSpecificType;
        if (this.searchedAssetForLinking.length === 0) {
          this.assetNotFound = true;
        }
        this.searchedAssets.emit(this.searchedAssetForLinking);
      }); */
  }

  setSelectedAssetType(assetData) {
    const asset = assetData.value;
    if (asset) {
      this.assetTypeForSearch = asset.type;
      this.defaultAssetForDropDown["assetLabel"] = this.getLangLabel(
        asset.label
      );
      if (asset && asset["icon"] && asset["color"]) {
        this.defaultAssetForDropDown["icon"] = asset.icon;
        this.defaultAssetForDropDown["color"] = asset.color;
      }
      this.searchedAssetForLinking = [];
      this.searchForAsset = "";
      this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
      this.assetTypeChange.emit(true)
    }
  }

  resetSearchedAssets(getLastTenAssets: boolean) {
    this.searchedAssetForLinking = [];
    this.searchedAssets.emit({assets:this.searchedAssetForLinking,isSearched:false});
    this.searchForAsset = '';
    this.assetTypeForSearch = this.linkableAssetsLabelWithType?.[0]?.type || '';
    if (this.linkableAssetsLabelWithType?.length > 0) {
      this.defaultAssetForDropDown['assetLabel'] = this.getLangLabel(this.linkableAssetsLabelWithType[0]['label']);
      this.defaultAssetForDropDown['icon'] = this.linkableAssetsLabelWithType[0]['icon']
      this.defaultAssetForDropDown['color'] = this.linkableAssetsLabelWithType[0]['color']
    }
    if (getLastTenAssets) {
      this.getLastTenAssetForSelectedType(this.assetTypeForSearch);
    }
  }
  getLangLabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }
}

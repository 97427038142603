import { Component, ElementRef, Output, ViewChild,EventEmitter, AfterViewInit, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@ngrx/store";
import { getGooglePlaceApiKeySelector } from "../../../state/User/user.selector";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";


declare var google: any;

@Component({
  selector: "app-auto-complete-address",
  standalone: true,
  imports: [CommonModule ,FormsModule,ButtonModule],
  templateUrl: "./auto-complete-address.component.html",
  styleUrls: ["./auto-complete-address.component.scss"],
})
export class AutoCompleteAddressComponent implements AfterViewInit {

  @Input()showGeoFenceEdit:boolean

  @ViewChild('resultContainer') resultContainer: ElementRef;
  @Output() emittingLatLng: EventEmitter<any> = new EventEmitter();

  private autoCompleteService;
  private sessionToken;
  private googlePlaceApiKey:string;

  public searchedPlaceTitle:string
  public openSearchBar:boolean

  constructor(private store: Store) {}

  
  ngAfterViewInit() {
    this.store.select(getGooglePlaceApiKeySelector).subscribe(res=> this.googlePlaceApiKey=res); 

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googlePlaceApiKey}&libraries=places`;
    document.body.appendChild(script);
  }

  initAutocomplete() {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  searchPlaces(query:string) {
    if (query?.trim()?.length>0) {
      this.autoCompleteService.getPlacePredictions({
        input: query,
        sessionToken: this.sessionToken
      }, (results, status) => {
       
        if (status === google.maps.places.PlacesServiceStatus.OK) {
      
          this.resultContainer.nativeElement.innerHTML = '';
       
          results.forEach(result => {
            const resultTag = document.createElement('p');
            resultTag.innerHTML = 
            `<div style="display:flex;gap:10px;padding:5px 5px 5px 20px">
            <i class="pi pi-map-marker"></i>
            <p>${result.description}</p></div>`;

          

            resultTag.style.marginTop='7px';
            resultTag.addEventListener('click', () => {
              this.getPlaceDetails(result.place_id);
              this.searchedPlaceTitle=result.description
            });
          
            this.resultContainer.nativeElement.appendChild(resultTag);
          });
        }
      });
    }else{
      this.resultContainer.nativeElement.innerHTML = '';
    }
  }

   
 getPlaceDetails(placeId) {
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    
    service.getDetails({
      placeId: placeId,
      fields: ['geometry']
    }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
       
        this.emittingLatLng.emit({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
        this.resultContainer.nativeElement.innerHTML = '';
      }
    });
  }
  
  suggstPlaces(event) {
  
    if (!this.autoCompleteService || !this.sessionToken) {
      this.initAutocomplete();
    }
     this.searchPlaces(event.target.value);
  };
}

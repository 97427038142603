import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConfigService} from 'services/config.service';
import {io, Socket} from 'socket.io-client';


type OnSocketData = (data: unknown) => void;

@Injectable({
  providedIn: 'root',
})
export class NgrxSocketService {
  private configService: ConfigService = inject(ConfigService);
  private connections: Record<string, Socket> = {};
  private callbacks: Record<string, OnSocketData> = {};
  private url: string = this.configService.appConfig.serverAddress;
  private connectionStatus: Record<string, BehaviorSubject<boolean>> = {};
  _factory(nsp: string) {
    const token = sessionStorage.getItem('token');
    if (!token) {
      throw Error(`Token not found, Cannot init to socket for ${nsp}`);
    }

    if (this.connections?.[nsp]) {
      return this.connections[nsp];
    } else {
      const url = `${this.url}${nsp}`;
      this.connections[nsp] = io(url, {
        query: {token: token},
        transports: ['websocket'],
      });

      this.connections[nsp].on('connect', () => {
        console.log(nsp, 'connect');
      });

      this.connections[nsp].on('disconnect', () => {
        console.log(nsp, 'disconnected');
      });

      this.connections[nsp].on('error', (e) => {
        console.error(`${nsp} ${e}`);
      });

      return this.connections[nsp];
    }
  }

  checkStatus(nsp: string): Observable<boolean> {
    if (!this.connectionStatus[nsp]) {
      this.connectionStatus[nsp] = new BehaviorSubject<boolean>(false);

      const socket: Socket = this._factory(nsp);

      socket.on('connect', () => {
        this.connectionStatus[nsp].next(true);
      });

      socket.on('disconnect', () => {
        this.connectionStatus[nsp].next(false);
      });

      socket.on('error', (error) => {
        console.error(`Error in namespace ${nsp}:`, error);
        this.connectionStatus[nsp].next(false);
      });
    }

    return this.connectionStatus[nsp].asObservable();
  }


  get(nsp: string, topic: string, key = 'default') {
    const socket = this._factory(nsp);
    const c = this.getListenerKey(nsp, topic, key);
    if (socket.disconnected) {
      socket.open();
    }

    if (this.callbacks[c]) {
      throw Error(`Needs unique key, or previous connection is not ended ${c}`);
    }

    return new Observable<unknown>((observer) => {
      this.callbacks[c] = (data: unknown) => observer.next(data);
      socket.on(topic, this.callbacks[c]);
    })
  }

  emit(nsp: string, data: unknown): Socket {
    const socket = this._factory(nsp);
    if (socket.disconnected) {
      socket.open();
    }

    return socket.emit(nsp, data);
  }

  conn(nsp: string) {
    const socket = this._factory(nsp);
    if (socket.disconnected) {
      socket.open();
    }

    return socket;
  }

  disconnect(nsp: string, topic: string, key = 'default') {
    const socket: Socket = this.connections?.[nsp];
    const ck = this.getListenerKey(nsp, topic, key);
    if (!socket) {
      return new Observable<string>(s => s.next(`No socket found: - ${ck}`));
    }
    if (!socket?.hasListeners(topic)) {
      socket.close();
      delete this.callbacks?.[ck];
    } else {
      if (this.callbacks?.[ck]) {
        socket.removeListener(topic, this.callbacks?.[ck]);
        delete this.callbacks?.[ck];
        if (socket.listeners(topic).length >= 0) {
          socket.close();
        }
      } else {
        socket.close();
      }
    }

    return new Observable<string>(s => s.next(`Disconnected: - ${ck}`));
  }


  // Call this when logging out or switch account
  killAll() {
    const keys = Object.keys(this.connections);
    Object.values(this.connections).forEach(s => s.close());
    this.connections = {};

    return new Observable<string>(s => s.next(`Disconnected: ${keys.join(' ')}`));
  }


  getListenerKey(n: string, t: string, k: string) {
    return `${n}-${t}-${k}`;
  }
}


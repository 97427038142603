<!--
<mat-card class="MB10">
  <mat-card-header>
    <mat-card-title>Memory <span class="sub-title">in GB</span></mat-card-title>
    &lt;!&ndash;<mat-card-subtitle>in Mb</mat-card-subtitle>&ndash;&gt;
  </mat-card-header>
  <mat-card-content>
    <div class="card-text">Free space: <span>{{assetData.stats.mem.free}} GB</span></div>
    <div class="card-text">Total space: <span>{{assetData.stats.mem.total}} GB</span></div>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>CPU</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="card-text">Current load: <span>{{assetData.stats.currentLoad.currentload}}</span></div>
    <div class="card-text">Avg load: <span>{{assetData.stats.currentLoad.avgload}}</span></div>
    <div class="card-text">Full load: <span>{{assetData.stats.fullLoad}}</span></div>
  </mat-card-content>
</mat-card>-->

<div class="W100FL MB10">
  <p-card class="server-card">
    <ng-template pTemplate="header">
      {{ 'sys.disk-space' | translate }}<span class="sub-title"> {{ 'sys.size-in-GB' | translate }}</span>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="card-text">{{ 'sys.mount' | translate }}: @if (fsSize && fsSize.mount) {
        <span>"{{ fsSize.mount }}"</span>
      }</div>
      <div class="card-text">{{ 'sys.size' | translate }}: @if (fsSize && fsSize.size) {
        <span>{{ fsSize.size }} {{
            'sys.size-GB' |
              translate
          }}</span>
      }</div>
      <div class="card-text">{{ 'sys.used' | translate }}: @if (fsSize && fsSize.used) {
        <span>{{ fsSize.used }} {{
            'sys.size-GB' |
              translate
          }}</span>
      }</div>
    </ng-template>
  </p-card>
</div>

<div class="W100FL">
  <div class="server-card-col" style="margin-right: 4%;">
    <p-card class="server-card">
      <ng-template pTemplate="header">
        {{ 'sys.memory' | translate }} <span class="sub-title">{{ 'sys.size-in-GB' | translate }}</span>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="card-text">{{ 'sys.used' | translate }}: @if (assetData.stats.mem) {
          <span>{{ assetData.stats.mem.used }} {{ 'sys.size-GB' | translate }}</span>
        }</div>
        <div class="card-text">{{ 'sys.total' | translate }}: @if (assetData.stats.mem) {
          <span>{{ assetData.stats.mem.total }} {{ 'sys.size-GB' | translate }}</span>
        }</div>
      </ng-template>
    </p-card>
  </div>

  <div class="server-card-col">
    <p-card class="server-card">
      <ng-template pTemplate="header">
        {{ 'sys.cpu-load' | translate }}
      </ng-template>
      <ng-template pTemplate="content">
        <div class="card-text">{{ 'sys.current' | translate }}: @if (assetData.stats.currentLoad) {
          <span>{{ assetData.stats.currentLoad.currentload }}</span>
        }</div>
        <div class="card-text">{{ 'sys.avg' | translate }}: @if (assetData.stats.currentLoad) {
          <span>{{ assetData.stats.currentLoad.avgload }}</span>
        }</div>
        <div class="card-text">{{ 'sys.full' | translate }}: @if (assetData.stats.fullLoad) {
          <span>{{ assetData.stats.fullLoad }}</span>
        }</div>
      </ng-template>
    </p-card>
  </div>
</div>



import {createAction, props} from '@ngrx/store';
import {UserProfile} from '../../app/settings/user-profile/user-profile-type';


export const getUserProfile = createAction(
  '[Load App-main API] fetch user profile details'
);

export const onUserProfileFetchSuccess = createAction(
  '[Effects API] on user profile succuess API request',
  props<{ details: UserProfile }>()
);

export const onUserProfileFetchFailure = createAction(
  '[Effect API] on user profile failure API request',
  props<{ error: string }>()
);

export const getUserLanguage = createAction(
  '[Load lang-util-service API] fetch user language'
);

export const onUserLangFetchSuccess = createAction(
  '[Effects API] on user lang fetch success',
  props<{ details: any }>()
);


export const onUserLangFetchFailure = createAction(
  '[Effects API] on user lang fetch failure',
  props<{ error: string }>()
);

export const getAllLanguages = createAction(
  '[Load app API] fetch all languages'
);

export const onAllLangFetchSuccess = createAction(
  '[Effects API] on all lang fetch success',
  props<{ list: any }>()
);

export const onAllLangFetchFailure = createAction(
  '[Effects API] on all lang fetch error',
  props<{ error: string }>()
);

export const updatePassowrd = createAction(
  '[user-edit | user-profile user API] on change of password update API',
  props<{profileId?:string, newPassword: any }>()
);

export const onUpdatePasswordSuccess = createAction(
  '[Effects API] on successful updating the password',
  props<{ response: any ,userId?}>()
);

export const onUpdatePasswordFailure = createAction(
  '[Effects API] on failure whilte updating the password',
  props<{ error: string }>()
);


export const updateUserDetails = createAction(
  '[Settings user] update details',
  props<{ details: any }>()
);

export const onUpdateUserDetailsSuccess = createAction(
  '[Effects API] on successful updating the user details',
  props<{ response: any }>()
);

export const onUpdateUserDetailsFailure = createAction(
  '[Effects API] on failure whilte updating the user details',
  props<{ error: string }>()
);


export const resetPasswordRespStatus = createAction(
  '[Effects API] on reset password resp status'
);

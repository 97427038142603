@if (isFormReadyToDisplay) {
  <form [formGroup]="form">
    @if (mapVisible) {
      <app-map-marker
        [mapVisible]="mapVisible"
        [mapDetails]="(mapDetails$ | async)"
        [latitude]="latitude"
        [longitude]="longitude"
        (doubleClickOnMap)="assignValuesOnDoubleClick($event)"
        (hideMapView)="hideMapView()"
        [mapHeader]="form?.value?.name || 'Mark Location'"
        [geoFencesForAsset]="(geoFencesForAsset$ | async)"
        (emitGeoFenceRadius)="updateGeoFenceRadius($event)"
        [showGeoFenceEdit]="showGeoFenceEdit"
      ></app-map-marker>
    }
    <div class="grid my-1">
      @for (field of formConfigData; track field) {
        <div class="form-group py-3 col-12 md:col-6 lg:col-6 xl:col-6"
             [ngClass]="{'hide': ((field?.linkedWith ? (form?.value?.[field?.linkedWith] !==  field?.toValue) : false) || field.type === 'hidden')}">
          @if ((field.type === 'text') || (field.type === 'email') || (field.type === 'preFill')) {
            <div
            >
              <!-- <mat-form-field color="accent" class="full-width" appearance="outline">
              <mat-label>{{field.label  | translate}}</mat-label>
              <input matInput type={{field.type}} placeholder="{{field.description | translate}}" formControlName={{field.field}} [required]="field.required">
            </mat-form-field> -->
              <span class="p-float-label">
              <input type="{{field.type}}" class="full-width" id="basicInput" pInputText
                     [ngClass]="{'input-disable': (isFieldInvalid(field?.requiredField)) || (field.disabled && field.disabled === true)}"
                     placeholder="{{field.description | translate}}"
                     (input)="handleInputChange($event,field?.isUnique,field.field);regexValidation($event?.target?.value,field?.regexValidation,field.field)"
                     formControlName={{field.field}} (change)="handleUppercaseCheck($event,field?.isUpperCase,field.field)"
                     [required]="field.required">
              <label for="basicInput">{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
                @if (!valueExistMessage?.[field.field]?.['isUnique']) {
                  <small id="basicInput" class="smallErrorMsg">{{ valueExistMessage?.[field.field]?.isValueExistMessage }}</small>
                  }
                  @if (regexValidationObj && !regexValidationObj[field.field]) {
                  <small id="basicInput" class="smallErrorMsg">{{ field?.regexValidation?.message }}</small>
                }
            </span>
            </div>
          }
          @if (field.type === 'number') {
            <div class="number-field-div">
            <span class="p-float-label full-width">
              <p-inputNumber styleClass="full-width pInputNumber" class="full-width pInputNumber"
                             placeholder="{{field.description | translate}}" formControlName={{field.field}}
                             mode="decimal" [showButtons]="true" [minFractionDigits]="2" [maxFractionDigits]="14"
                             inputId="minmax-buttons"
                             (onInput)="(field.field==='lat' || field.field==='lng') && onChange($event,field.field)"
              ></p-inputNumber>
              <label for="textarea">{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
            </span>
              @if ((field.field === 'lat' || field.field === 'lng')) {
                <button pButton
                        pTooltip="Click to select from Map"
                        [tooltipPosition]="'bottom'"
                        (click)="mapVisible ? hideMapView() : showMapView()"
                        [disabled]="isEditForm ? !isEditOptionClicked : false"
                ><i class="pi pi-map-marker"></i></button>
              }
            </div>
          }
          @if (field.type === 'telephone') {
            <div>
              <span class="p-float-label full-width">
                <p-inputNumber styleClass="full-width" class="full-width"
                               placeholder="{{field.description | translate}}" formControlName={{field.field}}
                               mode="decimal" inputId="withoutgrouping" [useGrouping]="false"></p-inputNumber>
                <label for="textarea">{{ field.label | translate }} @if (field.required) {
                  <span> *</span>
                }</label>
              </span>
            </div>
          }
          @if (field.type === 'multiText') {
            <div>
              <span class="p-float-label full-width">
                <p-chips styleClass="full-width" inputId="multiText" formControlName={{field.field}}
                         [separator]="separatorExp" placeholder="{{field.description | translate}}"></p-chips>
                <label for="multiText">{{ field.label | translate }} @if (field.required) {
                  <span> *</span>
                }</label>
              </span>
            </div>
          }
          @if (field.type === 'textarea') {
            <div>
              <span class="p-float-label full-width">
                <textarea pInputTextarea inputId="textarea" class="full-width" rows="3" cols="15"
                          placeholder=" {{field.description | translate}}" formControlName={{field.field}}
                          [required]="field.required"
                          (change)="handleUppercaseCheck($event,field?.isUpperCase,field.field)"></textarea>
                <label for="textarea">{{ field.label | translate }} @if (field.required) {
                  <span> *</span>
                }</label>
              </span>
            </div>
          }
          @if (field.type == 'date') {
            <div>
              <!-- <mat-form-field color="accent" class="full-width" appearance="outline">
              <mat-label>{{field.label  | translate}}</mat-label>
              <input matInput type="text" matInput ngxDaterangepickerMd class="ng-date-picker-input"
                [singleDatePicker]="datePickerVariables.singleDatePicker" [autoApply]="datePickerVariables.autoApply" [maxDate]="datePickerVariables.maxDate"
                [locale]="datePickerVariables.locale" [showDropdowns]="datePickerVariables.showDropdowns"
                [minDate]="datePickerVariables.minDate"
                [required]="field.required" placeholder="{{field.description | translate}}" formControlName={{field.field}}>
            </mat-form-field> -->
              <span class="p-float-label full-width">
              <p-calendar class="full-width" placeholder="{{field.description}}"
                          formControlName={{field.field}}></p-calendar>
              <label>{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
            </span>
            </div>
          }
          @if (field.type == 'dropDown' && !field?.isConditional) {
            <div>
            <span class="p-float-label full-width">
              <p-dropdown class="full-width TTC" inputId="drop_dropdown" [autoDisplayFirst]="false"
                (onChange)="handleGenericDropDownChange($event,field)" (onFilter)="handleDataFilterInDropDown($event,field)"
                          [filter]="true" filterBy="label" placeholder="{{field.description | translate}}" formControlName={{field.field}}
                          [required]="field.required" [options]="field.dropDownValues" optionLabel="label" optionValue="value">
              </p-dropdown>
              @if (!valueExistMessage?.[field.field]?.['isUnique']) {
              <small id="basicInput" class="smallErrorMsg">{{ valueExistMessage?.[field.field]?.isValueExistMessage }}</small>
              }
              <label class="full-width" for="drop_dropdown">{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
            </span>
            </div>
          }
          @if (field.type == 'dropDown' && field?.isConditional && !field?.apiDetails?.searchAvailable &&
          form?.value?.[field?.linkedWith] === field?.toValue) {
            <div>
            <span class="p-float-label full-width">
              <p-dropdown class="full-width TTC" inputId="drop_dropdown" [autoDisplayFirst]="false"
                (onChange)="handleGenericDropDownChange($event,field)"
                          [filter]="true" filterBy="label" placeholder="{{field.description | translate}}" formControlName={{field.field}}
                          [required]="field.required" [options]="field.dropDownValues" optionLabel="label" optionValue="value">
              </p-dropdown>
              <label class="full-width" for="drop_dropdown">{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
            </span>
            </div>
          }
          @if (field.type == 'multiSelect') {
            <div>
              <!-- <mat-form-field color="accent" class="full-width" appearance="outline">
              <mat-label>{{field.label  | translate}}</mat-label>
              <mat-select  [disableOptionCentering]="true" placeholder="{{field.description | translate}}" formControlName={{field.field}} [required]="field.required" multiple>
                <mat-option  *ngFor="let fieldData of field.dropDownValues" [value]="fieldData.value">{{fieldData.label  | translate}}</mat-option>
              </mat-select>
            </mat-form-field> -->
              <span class="p-float-label full-width">
            <p-multiSelect [options]="field.dropDownValues" inputId="multiSeldropdown" formControlName={{field.field}}
                           [filter]="true" filterBy="label" optionLabel="label" optionValue="value"
                           placeholder="{{field.description | translate}}" [required]="field.required">
            </p-multiSelect>
            <label for="multiSeldropdown">{{ field.label | translate }} @if (field.required) {
              <span> *</span>
            }</label>
          </span>
            </div>
          }
          @if (field.type == 'selectButton') {
          <div>
            <span class="p-float-label full-width">
              <p-selectButton [options]="field.dropDownValues" unselectable="true" formControlName={{field.field}}
                optionLabel="label" optionValue="value" [required]="field.required" />
              <label style="top: -0.75rem !important;">{{ field.label | translate }} @if (field.required) {<span>
                  *</span>}</label>
            </span>
          </div>
          }
          @if (field.type == 'boolean') {
          <div>
            <span class="flex justify-content-between align-items-center gap-3">
              <label style="width:50%;white-space: nowrap;color: #6c757d;">{{ field.label | translate }} @if (field.required) {
                <span> *</span>
                }</label>
              <p-inputSwitch class="full-width flex justify-content-start"
                [pTooltip]="field?.tooltipLabel[form?.value?.[field?.field]]?.Descripton"
                [formControlName]="field.field"></p-inputSwitch>
            </span>
          </div>
          }
          @if (field.type == 'colorPicker') {
            <div>
              <div class="form-group">
                <div style="display: flex;">
              <span class="p-float-label">
                <input class="full-width" placeholder="Pick a color" value={{foreColor.inputBgColor}}
                       required type="text" id="color-text" (change)="onColorChanged($event,field.field)" pInputText>
                <label for="color-text">{{ 'sys.color' | translate }}</label>
              </span>
                  <p-colorPicker class="full-width" formControlName={{field.field}} #foreColor></p-colorPicker>
                </div>
              </div>
            </div>
          }
          @if (field.type == 'array') {
            <div>
              <!-- <mat-form-field color="accent" class="full-width" appearance="outline">
              <mat-label>{{field.label | translate}}</mat-label>
              <textarea matInput placeholder="{{field.description | translate}}" formControlName={{field.field}} type={{field.type}} [required]="field.required"></textarea>
              <mat-hint align="start" class="redColorText">Enter the values with comma separated</mat-hint>
            </mat-form-field> -->
        <span class="p-float-label full-width">
          <textarea pInputTextarea inputId="textarea" class="full-width" rows="3" cols="15"
            placeholder=" {{field.description | translate}}" formControlName={{field.field}}
                    [required]="field.required"></textarea>
          <label for="textarea">{{ field.label | translate }} @if (field.required) {
            <span> *</span>
          }</label>
          <small id="textarea" class="">Enter the values with comma separated.</small>
        </span>
      </div>
          }
          @if (field.type == 'accordian') {
            <div>
        <p-accordion [formGroupName]="field?.field">
          <p-accordionTab [header]="(field?.label | translate)">
            @for (accordian of field?.accordianData; track accordian) {
              <div>
                @if (accordian?.type == 'accordian') {
                  <div>
                    <p-accordion>
                      <p-accordionTab [header]="(accordian?.label | translate)">
                        <div [formGroupName]="accordian?.field">
                          @for (child of accordian?.childData; track child) {
                            <div>
                              <span class="p-float-label mt-4">
                                <input class="full-width" id="basicInput" pInputText [formControlName]="child?.field"
                                       [placeholder]="child.description | translate" [required]="child.required">
                                <label for="basicInput">{{ child.label | translate }} @if (child.required) {
                                  <span> *</span>
                                }</label>
                              </span>
                            </div>
                          }
                        </div>
                      </p-accordionTab>
                    </p-accordion>
                  </div>
                }
                @if (accordian?.type == 'text' || accordian?.type == 'password') {
                  <div>
                    <span class="p-float-label mt-4">
                      <input class="full-width" type="{{accordian?.type}}" id="basicInput"
                             [placeholder]="accordian.description | translate" [formControlName]="accordian.field"
                             pInputText>
                      <label for="basicInput">{{ accordian.label | translate }} @if (accordian.required) {
                        <span> *</span>
                      }</label>
                    </span>
                  </div>
                }
                @if (accordian?.type == 'boolean') {
                  <div>
                    <span class="flex justify-content-between mt-4">
                      <label class="full-width"
                             for="basicInput">{{ accordian.label | translate }} @if (accordian.required) {
                        <span> *</span>
                      }</label>
                      <p-inputSwitch class="full-width flex justify-content-end"
                                     [formControlName]="accordian.field"></p-inputSwitch>
                    </span>
                  </div>
                }
                @if (accordian?.type == 'dropDown') {
                  <div>
                    <span class="p-float-label full-width mt-4">
                      <p-dropdown class="full-width TTC" inputId="drop_dropdown" [autoDisplayFirst]="false"
                      [filter]="true" filterBy="label" placeholder="{{accordian.description | translate}}"
                                  formControlName={{accordian.field}} [required]="accordian.required"
                                  [options]="accordian.dropDownValues" optionLabel="label" optionValue="value">
                      </p-dropdown>
                      <label class="full-width"
                             for="drop_dropdown">{{ accordian.label | translate }} @if (accordian.required) {
                        <span> *</span>
                      }</label>
                    </span>
                  </div>
                }
              </div>
            }
          </p-accordionTab>
        </p-accordion>
      </div>
          }
        </div>
      }
    </div>
    <!--  -->
    <div class="grid my-1" [ngClass]="{ 'kiosk-add-More-details-form': isKioskAddMoreDetailsForm}">
      @if (!isKioskAddMoreDetailsForm) {
        <div class="col-6 mx-2" style="display: flex;">
          @if (formConfigData?.length > 0 && !isCloneBtnReq && showUpdateBtn) {
            <p-button class="MR10 p-button-sm" [disabled]="checkSubmitBtnDisableStatus()"
                      (onClick)="submitConfigDetails()">
              @if (isEditForm) {
                <span>{{ 'sys.update' | translate }}</span>
              }
              @if (!isEditForm) {
                <span>{{ 'sys.submit' | translate }}</span>
              }
            </p-button>
          }
      <!-- Submit Button in Clone Form -->
          @if (formConfigData?.length > 0 && isCloneBtnReq && showUpdateBtn && !isEditForm) {
            <p-button class="MR10 p-button-sm" (onClick)="submitConfigDetails()"
                      [disabled]="checkSubmitBtnDisableStatus()">
              <span>{{ 'sys.submit' | translate }}</span>
            </p-button>
          }
          <!-- Clone Button in Clone Form -->
          @if (formConfigData?.length > 0 && isCloneBtnReq && showUpdateBtn && isEditForm) {
            <p-button class="MR10 p-button-sm" (onClick)="submitConfigDetails()">
              <span pTooltip="Create a copy" tooltipPosition="top">{{ 'sys.clone' | translate }}</span>
            </p-button>
          }
          @if (formConfigData?.length > 0 && isCloneBtnReq && isEditForm) {
            <p class="btn-hint"> This is created by other user, you can't edit </p>
          }
      <!-- <p-button color="warn" class='buttonEnable' [disabled]="!isEditOptionClicked" [class.showDeregisterAssetPopupBtn]="isEditOptionClicked"
    *ngIf="showDeRegisterBtn" (onClick)="deregisterSelectedAsset()">{{'sys.de-register' | translate}}</p-button> -->
          <p-confirmPopup key="eventFormPopup"></p-confirmPopup>
          @if (showDeRegisterBtn) {
      <button pButton type="button" label="{{'sys.remove' | translate}}"
        class="p-button-outlined p-button-danger buttonEnable p-button-sm" 
              (click)="deregisterSelectedAsset($event)"></button>
          }
        </div>
      }
      <!-- Next Button Container for Kiosk Add More Details Form -->
      <ng-container *ngTemplateOutlet="kioskAddMoreDetailsFormTemplate"></ng-container>
    </div>
</form>
}

@if (showCircularLoader) {
  <div>
    <app-circular-loader></app-circular-loader>
  </div>
}





<ng-template #kioskAddMoreDetailsFormTemplate>
  @if (isKioskAddMoreDetailsForm) {
    <div class="col-6 mx-2" style="display: flex;">
      <button (click)="submitConfigDetails()" pButton
              iconPos="right" icon="pi pi-arrow-right"
              [disabled]="checkSubmitBtnDisableStatus()"
              label="Next" styleClass="mr-3 mb-3 p-button-sm"></button>
    </div>
  }
</ng-template>

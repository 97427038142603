import { createAction, props } from "@ngrx/store";

export const setSidebarSizeState = createAction(
    "[Window Resize Action] set sidebar size",
    props<{isFullScreen: boolean}>()
)

export const setSidebarState = createAction(
    "[Split Screen and Sidebar Id action] set split screen and current active id",
    props<{splitState: boolean, currentActiveId: string}>()
)
export const setSelectedIdToRowHighlight = createAction(
    "[Split Screen and Sidebar Id action] set selected row id to highlight primeNg table",
    props<{selectedIdToHighlightRow:string}>()
)
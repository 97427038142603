import {createSelector} from '@ngrx/store';
import {AppState} from '../app.state';
import { SidebarState } from './sidebar.reducer';

export const selectSidebar = (state: AppState) => state?.sidebar;

export const selectSidebarState = createSelector(
    selectSidebar, 
    (state: SidebarState)=> state.splitState
);

export const currentActiveId = (state: AppState) => state?.sidebar.currentActiveId;

export const selectIsSidebarFullScreen = createSelector(
    selectSidebar, 
    (state:SidebarState)=> state.fullScreenSidebar
);
export const selectedRowId = createSelector(
    selectSidebar, 
    (state:SidebarState)=> state.selectedIdToHighlightRow
);
<div class="md-drppicker" #pickerContainer
[ngClass]="{
    ltr: locale.direction === 'ltr',
    rtl: this.locale.direction === 'rtl',
    'shown': isShown || inline,
    'hidden': !isShown && !inline,
    'inline': inline,
    'double': !singleDatePicker && showCalInRanges,
    'show-ranges': rangesArray.length
}" [class]="'drops-' + drops + '-' + opens">
  <div class="ranges">
    <ul>
      @for (range of rangesArray; track range) {
        <li>
          <button type="button"
                  (click)="clickRange($event, range)"
                  [disabled]="disableRange(range)"
                  [ngClass]="{'active': range === chosenRange}">{{ range }}
          </button>
        </li>
      }
    </ul>
  </div>
  @if (showCalInRanges) {
    <div class="calendar" [ngClass]="{right: singleDatePicker, left: !singleDatePicker}"
    >
      <div class="calendar-table">
        @if (calendarVariables) {
          <table class="table-condensed">
            <thead>
            <tr>
              @if (showWeekNumbers || showISOWeekNumbers) {
                <th></th>
              }
              @if (!calendarVariables.left.minDate || calendarVariables.left.minDate.isBefore(calendarVariables.left.calendar.firstDay) && (!this.linkedCalendars || true)) {
                <th (click)="clickPrev(sideEnum.left)" class="prev available">
                </th>
              }
              @if (!(!calendarVariables.left.minDate || calendarVariables.left.minDate.isBefore(calendarVariables.left.calendar.firstDay) && (!this.linkedCalendars || true))) {
                <th></th>
              }
              <th colspan="5" class="month drp-animate">
                @if (showDropdowns && calendarVariables.left.dropdowns) {
                  <div class="dropdowns">
                    {{ this.locale.monthNames[calendarVariables?.left?.calendar[1][1].month()] }}
                    <select class="monthselect" (change)="monthChanged($event, sideEnum.left)">
                      @for (m of calendarVariables.left.dropdowns.monthArrays; track m) {
                        <option
                          [disabled]="(calendarVariables.left.dropdowns.inMinYear && m < calendarVariables.left.minDate.month()) || (calendarVariables.left.dropdowns.inMaxYear && m > calendarVariables.left.maxDate.month())"
                          [value]="m" [selected]="calendarVariables.left.dropdowns.currentMonth == m">
                          {{ locale.monthNames[m] }}
                        </option>
                      }
                    </select>
                  </div>
                  <div class="dropdowns">
                    {{ calendarVariables?.left?.calendar[1][1].format(" YYYY") }}
                    <select class="yearselect" (change)="yearChanged($event, sideEnum.left)">
                      @for (y of calendarVariables.left.dropdowns.yearArrays; track y) {
                        <option [selected]="y === calendarVariables.left.dropdowns.currentYear">
                          {{ y }}
                        </option>
                      }
                    </select>
                  </div>
                }
                @if (!showDropdowns || !calendarVariables.left.dropdowns) {
                  {{ this.locale.monthNames[calendarVariables?.left?.calendar[1][1].month()] }}  {{ calendarVariables?.left?.calendar[1][1].format(" YYYY") }}
                }
              </th>
              @if ((!calendarVariables.left.maxDate || calendarVariables.left.maxDate.isAfter(calendarVariables.left.calendar.lastDay)) && (!linkedCalendars || singleDatePicker)) {
                <th class="next available" (click)="clickNext(sideEnum.left)">
                </th>
              }
              @if (!((!calendarVariables.left.maxDate || calendarVariables.left.maxDate.isAfter(calendarVariables.left.calendar.lastDay)) && (!linkedCalendars || singleDatePicker))) {
                <th></th>
              }
            </tr>
            <tr class='week-days'>
              @if (showWeekNumbers || showISOWeekNumbers) {
                <th class="week"><span>{{ this.locale.weekLabel }}</span></th>
              }
              @for (dayofweek of locale.daysOfWeek; track dayofweek) {
                <th><span>{{ dayofweek }}</span></th>
              }
            </tr>
            </thead>
            <tbody class="drp-animate">
              @for (row of calendarVariables.left.calRows; track row) {
                <tr [class]="calendarVariables.left.classes[row].classList">
                  <!-- add week number -->
                  @if (showWeekNumbers) {
                    <td class="week">
                      <span>{{ calendarVariables.left.calendar[row][0].week() }}</span>
                    </td>
                  }
                  @if (showISOWeekNumbers) {
                    <td class="week">
                      <span>{{ calendarVariables.left.calendar[row][0].isoWeek() }}</span>
                    </td>
                  }
                  <!-- cal -->
                  @for (col of calendarVariables.left.calCols; track col) {
                    <td [class]="calendarVariables.left.classes[row][col]"
                        (click)="clickDate($event, sideEnum.left, row, col)"
                        (mouseenter)="hoverDate($event, sideEnum.left, row, col)">
                      <span>{{ calendarVariables.left.calendar[row][col].date() }}</span>
                    </td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
      @if (timePicker) {
        <div class="calendar-time">
          <div class="select">
            <select class="hourselect select-item" [disabled]="!startDate"
                    [(ngModel)]="timepickerVariables.left.selectedHour"
                    (ngModelChange)="timeChanged($event, sideEnum.left)">
              @for (i of timepickerVariables.left.hours; track i) {
                <option
                  [value]="i"
                  [disabled]="timepickerVariables.left.disabledHours.indexOf(i) > -1">{{ i }}
                </option>
              }
            </select>
          </div>
          <div class="select">
            <select class="select-item minuteselect" [disabled]="!startDate"
                    [(ngModel)]="timepickerVariables.left.selectedMinute"
                    (ngModelChange)="timeChanged($event, sideEnum.left)">
              @for (i of timepickerVariables.left.minutes; track i; let index = $index) {
                <option
                  [value]="i"
                  [disabled]="timepickerVariables.left.disabledMinutes.indexOf(i) > -1">{{ timepickerVariables.left.minutesLabel[index] }}
                </option>
              }
            </select>
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
          <div class="select">
            @if (timePickerSeconds) {
              <select class="select-item secondselect" [disabled]="!startDate"
                      [(ngModel)]="timepickerVariables.left.selectedSecond"
                      (ngModelChange)="timeChanged($event, sideEnum.left)">
                @for (i of timepickerVariables.left.seconds; track i; let index = $index) {
                  <option
                    [value]="i"
                    [disabled]="timepickerVariables.left.disabledSeconds.indexOf(i) > -1">{{ timepickerVariables.left.secondsLabel[index] }}
                  </option>
                }
              </select>
            }
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
          <div class="select">
            @if (!timePicker24Hour) {
              <select class="select-item ampmselect" [(ngModel)]="timepickerVariables.left.ampmModel"
                      (ngModelChange)="timeChanged($event, sideEnum.left)">
                <option value="AM" [disabled]="timepickerVariables.left.amDisabled">AM</option>
                <option value="PM" [disabled]="timepickerVariables.left.pmDisabled">PM</option>
              </select>
            }
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
        </div>
      }
    </div>
  }
  @if (showCalInRanges && !singleDatePicker) {
    <div class="calendar right"
    >
      <div class="calendar-table">
        @if (calendarVariables) {
          <table class="table-condensed">
            <thead>
            <tr>
              @if (showWeekNumbers || showISOWeekNumbers) {
                <th></th>
              }
              @if ((!calendarVariables.right.minDate || calendarVariables.right.minDate.isBefore(calendarVariables.right.calendar.firstDay)) && (!this.linkedCalendars)) {
                <th (click)="clickPrev(sideEnum.right)" class="prev available">
                </th>
              }
              @if (!((!calendarVariables.right.minDate || calendarVariables.right.minDate.isBefore(calendarVariables.right.calendar.firstDay)) && (!this.linkedCalendars))) {
                <th></th>
              }
              <th colspan="5" class="month">
                @if (showDropdowns && calendarVariables.right.dropdowns) {
                  <div class="dropdowns">
                    {{ this.locale.monthNames[calendarVariables?.right?.calendar[1][1].month()] }}
                    <select class="monthselect" (change)="monthChanged($event, sideEnum.right)">
                      @for (m of calendarVariables.right.dropdowns.monthArrays; track m) {
                        <option
                          [disabled]="(calendarVariables.right.dropdowns.inMinYear && calendarVariables.right.minDate && m < calendarVariables.right.minDate.month()) || (calendarVariables.right.dropdowns.inMaxYear && calendarVariables.right.maxDate && m > calendarVariables.right.maxDate.month())"
                          [value]="m" [selected]="calendarVariables.right.dropdowns.currentMonth == m">
                          {{ locale.monthNames[m] }}
                        </option>
                      }
                    </select>
                  </div>
                  <div class="dropdowns">
                    {{ calendarVariables?.right?.calendar[1][1].format(" YYYY") }}
                    <select class="yearselect" (change)="yearChanged($event, sideEnum.right)">
                      @for (y of calendarVariables.right.dropdowns.yearArrays; track y) {
                        <option [selected]="y === calendarVariables.right.dropdowns.currentYear">
                          {{ y }}
                        </option>
                      }
                    </select>
                  </div>
                }
                @if (!showDropdowns || !calendarVariables.right.dropdowns) {
                  {{ this.locale.monthNames[calendarVariables?.right?.calendar[1][1].month()] }}  {{ calendarVariables?.right?.calendar[1][1].format(" YYYY") }}
                }
              </th>
              @if (!calendarVariables.right.maxDate || calendarVariables.right.maxDate.isAfter(calendarVariables.right.calendar.lastDay) && (!linkedCalendars || singleDatePicker || true)) {
                <th class="next available" (click)="clickNext(sideEnum.right)">
                </th>
              }
              @if (!(!calendarVariables.right.maxDate || calendarVariables.right.maxDate.isAfter(calendarVariables.right.calendar.lastDay) && (!linkedCalendars || singleDatePicker || true))) {
                <th></th>
              }
            </tr>
            <tr class='week-days'>
              @if (showWeekNumbers || showISOWeekNumbers) {
                <th class="week"><span>{{ this.locale.weekLabel }}</span></th>
              }
              @for (dayofweek of locale.daysOfWeek; track dayofweek) {
                <th><span>{{ dayofweek }}</span></th>
              }
            </tr>
            </thead>
            <tbody>
              @for (row of calendarVariables.right.calRows; track row) {
                <tr [class]="calendarVariables.right.classes[row].classList">
                  @if (showWeekNumbers) {
                    <td class="week">
                      <span>{{ calendarVariables.right.calendar[row][0].week() }}</span>
                    </td>
                  }
                  @if (showISOWeekNumbers) {
                    <td class="week">
                      <span>{{ calendarVariables.right.calendar[row][0].isoWeek() }}</span>
                    </td>
                  }
                  @for (col of calendarVariables.right.calCols; track col) {
                    <td [class]="calendarVariables.right.classes[row][col]"
                        (click)="clickDate($event, sideEnum.right, row, col)"
                        (mouseenter)="hoverDate($event, sideEnum.right, row, col)">
                      <span>{{ calendarVariables.right.calendar[row][col].date() }}</span>
                    </td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
      @if (timePicker) {
        <div class="calendar-time">
          <div class="select">
            <select class="select-item hourselect" [disabled]="!startDate"
                    [(ngModel)]="timepickerVariables.right.selectedHour"
                    (ngModelChange)="timeChanged($event, sideEnum.right)">
              @for (i of timepickerVariables.right.hours; track i) {
                <option
                  [value]="i"
                  [disabled]="timepickerVariables.right.disabledHours.indexOf(i) > -1">{{ i }}
                </option>
              }
            </select>
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
          <div class="select">
            <select class="select-item minuteselect" [disabled]="!startDate"
                    [(ngModel)]="timepickerVariables.right.selectedMinute"
                    (ngModelChange)="timeChanged($event, sideEnum.right)">
              @for (i of timepickerVariables.right.minutes; track i; let index = $index) {
                <option
                  [value]="i"
                  [disabled]="timepickerVariables.right.disabledMinutes.indexOf(i) > -1">{{ timepickerVariables.right.minutesLabel[index] }}
                </option>
              }
            </select>
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
          <div class="select">
            @if (timePickerSeconds) {
              <select class="select-item secondselect" [disabled]="!startDate"
                      [(ngModel)]="timepickerVariables.right.selectedSecond"
                      (ngModelChange)="timeChanged($event, sideEnum.right)">
                @for (i of timepickerVariables.right.seconds; track i; let index = $index) {
                  <option
                    [value]="i"
                    [disabled]="timepickerVariables.right.disabledSeconds.indexOf(i) > -1">{{ timepickerVariables.right.secondsLabel[index] }}
                  </option>
                }
              </select>
            }
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
          <div class="select">
            @if (!timePicker24Hour) {
              <select class="select-item ampmselect" [(ngModel)]="timepickerVariables.right.ampmModel"
                      (ngModelChange)="timeChanged($event, sideEnum.right)">
                <option value="AM" [disabled]="timepickerVariables.right.amDisabled">AM</option>
                <option value="PM" [disabled]="timepickerVariables.right.pmDisabled">PM</option>
              </select>
            }
            <span class="select-highlight"></span>
            <span class="select-bar"></span>
          </div>
        </div>
      }
    </div>
  }
  @if (!autoApply && (!rangesArray.length || (showCalInRanges && !singleDatePicker))) {
    <div class="buttons">
      <div class="buttons_input">
        @if (showClearButton) {
          <button class="btn btn-default clear" type="button" (click)="clear()" [title]="locale.clearLabel">
            {{ locale.clearLabel }}
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 -5 24 24">
              <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
            </svg>
          </button>
        }
        @if (showCancel) {
          <button class="btn btn-default" type="button" (click)="clickCancel($event)">{{ locale.cancelLabel }}</button>
        }
        <button class="btn" [disabled]="applyBtn.disabled" type="button"
                (click)="clickApply($event)">{{ locale.applyLabel }}
        </button>
      </div>
    </div>
  }
</div>

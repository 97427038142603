import {Output,EventEmitter,Component,OnDestroy,Input,HostBinding,SimpleChanges,ViewChild,ElementRef,OnInit} from "@angular/core";

import { DialogModule } from "primeng/dialog";
import { IMapUserOptions } from "../../../state/User/interfaces/IUserMap";
import * as L from "leaflet";
import { AutoCompleteAddressComponent } from "../auto-complete-address/auto-complete-address.component";;
import { InputNumberModule } from "primeng/inputnumber";
import { ButtonModule } from "primeng/button";
import { FormsModule } from '@angular/forms';
import { map } from "lodash";
import { TooltipModule } from "primeng/tooltip";
import { getPopUpHeader,getPopupButtonsAndTip,showPopupData } from '../../delivery-managenment-system/services/common.service';


@Component({
  selector: "app-map-marker",
  standalone: true,
  imports: [DialogModule, AutoCompleteAddressComponent, InputNumberModule, ButtonModule, FormsModule, TooltipModule],
  templateUrl: "./map-marker.component.html",
  styleUrls: ["./map-marker.component.scss"],
})
export class MapMarkerComponent implements OnDestroy, OnInit  {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() mapDetails: IMapUserOptions;
  @Input() mapVisible: boolean;
  @Input() showGeoFenceEdit: boolean;
  @Input() geoFencesForAsset:IGeoFencesForAsset
  @Input() mapHeader='Mark Location'

  @Output() hideMapView: EventEmitter<void> = new EventEmitter<void>();
  @Output() doubleClickOnMap: EventEmitter<LatLng> = new EventEmitter();
  @Output() emitGeoFenceRadius: EventEmitter<number> = new EventEmitter();

  @HostBinding("style.--windowInnerHeight")
  get calculatedHeight() {
    return `${window.innerHeight}px`;
  }

  @HostBinding("style.--windowInnerWidth")
  get calculatedWidth() {
    return `${window.innerWidth}px`;
  }

  @ViewChild('map') mapElement: ElementRef;

  private map:L.Map;
  private mapInitializationStatus: boolean;
  private markerLayer:L.LayerGroup;
  private markerIcon:L.Icon;
  private markerCoordiantes:LatLng[]
  private markers:L.Marker[]
  private focusedLatLng:LatLng

  public geoFenceFormDialog: boolean;
  public geoFenceRadius:number

  constructor() {}
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'geoFencesForAsset') {
          this.geoFenceRadius=changes?.geoFencesForAsset?.currentValue?.geoFenceRadius || 0
          this.showGeoFenceEdit && this.plotGeoFences()
          this.showGeoFenceEdit && this.plotMarkers()
          this.panToGeoFence(this.geoFencesForAsset?.geoFenceCoordinates?.[0])
      }
    }
  }

  

  initMap(): void {
    this.map = L.map(this.mapElement.nativeElement, {
      center: [
        this.mapDetails.centerLocation.coordinates[0], //Latitude
        this.mapDetails.centerLocation.coordinates[1], //Longitude
      ],
      zoom: this.mapDetails.zoom,
      attributionControl:false,
       zoomControl: false
    });

    this.map.addControl(
      L.control.zoom({
        position: 'bottomright'
    })
  );

   
    const defaultMap = L.tileLayer(
      this.mapDetails.tile.url,
      this.mapDetails.tile.option
    );
  
    defaultMap.addTo(this.map);

    this.markerLayer = L.layerGroup().addTo(this.map);
    this.showGeoFenceEdit && this.plotGeoFences()
  }

  initializingMap() {
   
    if (!this.mapInitializationStatus && this.mapDetails) {
      
      this.initMap();
      this.mapInitializationStatus = true;

      if (this.latitude && this.longitude) {
        this.markerCoordiantes=[{ lat: this.latitude, lng: this.longitude }]
        this.plotMarkers()
      }
      this.map.on("dblclick", async (e) => {
        if(this.showGeoFenceEdit){
          this.markerCoordiantes=[this.markerCoordiantes[0],e.latlng]
          this.plotMarkers()
        }else{
          this.markerCoordiantes=[e.latlng]
          this.plotMarkers()
        }
 
      });
    }

  }

  plotMarkers(condition:string=null){
    if(condition === 'accept') {
      this.doubleClickOnMap.emit(this.focusedLatLng);
      this.emitGeoFenceRadius.emit(this.geoFenceRadius || 0)
      this.markerCoordiantes =[this.focusedLatLng]
     }
     else if(condition === 'reject') {
       this.markerCoordiantes =[this.markerCoordiantes[0]]
      }

    this.markerLayer?.clearLayers();
    this.markers=[]
    this.markerCoordiantes?.forEach((markerData,idx)=>{
      const draggable=this.markerCoordiantes.length===2 ? idx!==0 : true
         this.addMarker(markerData,draggable);
    })
  }

  addMarker(latlng:LatLng,draggable:boolean) {
    this.doubleClickOnMap.emit(latlng);
    //Emitting data for the Output on DoubleClick...

    if(!this.showGeoFenceEdit){
      draggable=true
    }

    this.markerIcon = L.icon({
      iconUrl:
      draggable ?
        "../../../assets/leaflet-icons/marker-icon.png" : "../../../assets/leaflet-icons/marker-icon-active.png",
      iconSize: [38, 38],
    });

  
    let marker :L.Marker= new L.Marker(latlng, {
      icon: this.markerIcon,
      draggable,
    });
    this.markerLayer && marker.addTo(this.markerLayer);
    //Creating New Marker...

      
      marker.bindPopup(this.getPopupContent(latlng,draggable),{offset:[0,-7],className:'marker-draggable-'+draggable})


      marker.on('mouseover',  () =>{
         marker.openPopup()
      });

      marker.on('popupopen',  () =>{
        draggable && setTimeout(()=> {
         this.addEventListenerForPopup()
        }, 1000); 
     });


      marker.on("click", () => {
         this.geoFenceFormDialog=false
      });

      marker.on("dragstart", () => {
        marker.unbindPopup()
        this.markers.length===1 && this.showGeoFenceEdit && this.addMarker(this.markerCoordiantes[0],false); // added Freezed marker 
        this.markers.length===2 && !this.showGeoFenceEdit && this.map.removeLayer(this.markers[0]) 
        this.markers.length===3 && this.map.removeLayer(this.markers[1])
      });

    marker.on("dragend", (e) => {
      //Emitting data for the Output on Dragging of Marker...
      
      setTimeout(()=> {
        marker.bindPopup(this.getPopupContent(e.target._latlng,draggable),{offset:[0,-7],className:'marker-draggable-'+draggable}).openPopup()
       }, 500); 
 
      if(this.showGeoFenceEdit){
        this.focusedLatLng=e.target._latlng
      }else{
        this.markerCoordiantes=[e.target._latlng]
        this.plotMarkers()
      }
    });

    this.map?.addLayer(marker);
    this.markers=[...(this.markers || []),marker]
    this.map?.panTo(latlng);
    //Adding new Marker...

    this.focusedLatLng=latlng
  }

  plotGeoFences() {
    if(!this.map || !this.geoFencesForAsset?.geoFenceCoordinates?.length)return;

    this.map.eachLayer(layer=>{
      layer?.['_latlngs']?.length && this.map.removeLayer(layer)
      })
    
    const coordinates = map(this.geoFencesForAsset?.geoFenceCoordinates, function (coordinate) {
      return L.latLng(coordinate[1], coordinate[0]);
    });


  const polygon:L.Polygon =L.polygon(coordinates, {color: 'blue',className:'geo-fence-plot'} ).addTo(this.map)

      document.querySelector('.geo-fence-plot')?.addEventListener('click', () =>{
        polygon.closePopup()
        this.geoFenceFormDialog=true
     });
  }

   panToGeoFence(coordinates:number[]){

    if(!coordinates?.length){return}

    const [lng, lat] = coordinates;
    this.map?.panTo({ lat, lng });
    setTimeout(()=> {
     this.map?.setZoom(17);
    }, 1000); 
  }

  addEventListenerForPopup(){
   if(!this.showGeoFenceEdit){
    return
   }

   document.
   querySelector('.marker-draggable-true')?.
   querySelector('.leaflet-popup-close-button')?.addEventListener('click', () =>{
    this.plotMarkers('reject')
    this.map.closePopup()
   });

    document.querySelector('.reject-popup-btn')?.addEventListener('click', () =>{
      this.plotMarkers('reject')
      this.map.closePopup()
   });
   document.querySelector('.accept-popup-btn')?.addEventListener('click', () =>{
    this.plotMarkers('accept')
 });
  }


  emittingLatLng(event:LatLng) {
    const prevData=(this.showGeoFenceEdit && this.markerCoordiantes?.[0] && [this.markerCoordiantes?.[0]]) || []
      this.markerCoordiantes=[...prevData,{ lat: event.lat, lng: event.lng }]
      this.plotMarkers()
  }


 
  updateGeoFenceRadius(){
    this.emitGeoFenceRadius.emit(this.geoFenceRadius|| 0)
    this.geoFenceFormDialog=false
  }



  closePopups(){
    this.map.closePopup()
  }


  

  getPopupContent(latlng:LatLng,draggable:boolean){

    const markerMovedStatus=(this.markerCoordiantes?.[0]?.lat!==latlng?.lat || this.markerCoordiantes?.[0]?.lng!==latlng?.lng)
 
     return `<div class='popup-content-map-marker' style="padding: 5px 1px 0px 1px;width: 100%;">

      
         ${getPopUpHeader(this.mapHeader,'',this.geoFenceRadius ? ('Geo-Fence Radius : ' + this.geoFenceRadius)  : null)}
         ${showPopupData([{label:('Lattitude : '),value:latlng?.lat.toFixed(4)},{label:('Longitude : '),value:latlng?.lng.toFixed(4)}])}
         ${(this.showGeoFenceEdit && draggable && markerMovedStatus) ? getPopupButtonsAndTip('New Lat Lng') : ''}

     </div>
     <div style="margin-top: 15px;">
     <div style='padding:5px 0px;display:${(this.showGeoFenceEdit && draggable && !markerMovedStatus) ? 'flex' : 'none'}'>
     <span style='border: 1px solid var(--primary-color); color: var(--primary-color); border-radius: 5px; padding: 3px; font-weight: bold'>Drag to Update Location</span></div>
     <div style='padding:0px 15px 10px 0px;display:${(this.showGeoFenceEdit && (!draggable || !markerMovedStatus)) ? 'flex' : 'none'}'>
     <span style='border: 1px solid blue; color: blue; border-radius: 5px; padding: 3px; font-weight: bold;white-space: nowrap;'>Click on Geo-Fence to Edit Radius</span></div>
     </div>`;
   }

  ngOnDestroy() {
    this.map?.remove();  //reomving Map...
   }

}



export interface IGeoFencesForAsset{
  geoFenceCoordinates:number[][];
  geoFenceRadius:number
}

export interface LatLng {
  lat:number;
  lng:number;
}
<div class="PR video-feed-container">
  <div class="camera-grid-container-header">
    <p>Currently not working due to socket migration</p>
    <div class="connect-btn">
      @if (!isConnectionEstablished) {
        <span (click)="toggleConnection()" pTooltip="Click to connect" tooltipPosition="top"><i class="fa fa-link"
                                                                                                aria-hidden="true"></i></span>
      }
      @if (isConnectionEstablished) {
        <span (click)="toggleConnection()" pTooltip="Click to disconnect" tooltipPosition="top"><i class="fa fa-unlink"
                                                                                                   aria-hidden="true"></i></span>
      }
    </div>
    <div class="status" [pTooltip]="isConnectionEstablished ? 'Connected' : 'Disconnected'" tooltipPosition="above" [ngClass]="isConnectionEstablished ? 'success' : 'fail'"></div>{{camera?.name}}
    <div class="countdown" class="allow-cr" [pTooltip]="getTimerTooltip()" tooltipPosition="top" (click)="resetTimeout()">{{timer.value}}</div>
    <span pTooltip="{{'sys.full-screen' | translate}}" tooltipPosition="left" class="full-screen" (click)="openFullscreen()"><i class="fa fa-expand"></i></span>
  </div>
  <video id={{nameSpace}} #videoElement width="100%" height="auto"></video>

  @if (isResumeCameraRequired) {
    <div class="resume">
      <p>{{ popUpMsg }}</p>
      <div class="btnMain small">
        <button pButton pRipple color="warn" class="close yes"
                (click)="connectToNameSpace(nameSpace)">{{ 'sys.yes' | translate }}
        </button>
        <button pButton pRipple color="accent" class="cancel close MR10 cancel"
                (click)="closePopup()">{{ 'sys.cancel' | translate }}
        </button>
      </div>
    </div>
  }

  @if (isCameraDisconnectRequired) {
    <div class="resume">
      <p>{{ disconnectPopUpMsg }}</p>
      <div class="btnMain small">
        <button pButton pRipple color="warn" class="close yes"
                (click)="disconnectSelectedNameSpace()">{{ 'sys.yes' | translate }}
        </button>
        <button pButton pRipple color="accent" class="cancel close MR10 cancel"
                (click)="isCameraDisconnectRequired = false">{{ 'sys.cancel' | translate }}
        </button>
      </div>
    </div>
  }
</div>

@if (showCircularLoader) {
  <div>
    <app-circular-loader></app-circular-loader>
  </div>
}

import {Component, OnInit, OnDestroy, OnChanges, Input} from '@angular/core';
import {CONSTANT} from '../../../config/constant';
declare var $: any;
import {forEach, clone, map, keys} from 'lodash';
import moment from 'moment-timezone';
//import { Chart } from 'chart.js';
import { EChartDataType, EChartSeries ,  } from '../e-charts/e-charts-data-type';
import { SocketActions } from '../../../state/Socket';
import { Store } from '@ngrx/store';
import { AppState } from '../../../state/app.state';

@Component({
  selector: 'app-server-live-stats',
  templateUrl: './server-live-stats.component.html',
  styleUrls: ['./server-live-stats.component.scss']
})
export class ServerLiveStatsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assetData: any;
  storageName = 'server_live_stats'
  chartData: EChartDataType;
  loader: boolean;
  show: boolean;
  serverLiveFeedSocketConnection = {};
  showCircularLoader: boolean;
  nameSpace: string;
  cpuStatsTopic :string;
  cpuMemoryTopic : string
  cpu = {
    groupData: {},
    xAsix: []
  };

  memory = {
    groupData: {},
    xAsix: []
  };

  public eChartGraph: any = {
    cpu: {
      graph: {
        series: [],
        legend: [],
        type: 'line',
        title: 'Inspection',
        xAxis: [],
        isDataZoomRequired: true
      },
      show: false,
      showEChartLoader: false,
      type: 'cpu',
      dataSetsBackgroundColor: 'rgba(56, 142, 60,1.9)',
      title: 'Cpu Stats'
    },
    memory: {
      graph: {
        series: [],
        legend: [],
        type: 'line',
        title: 'Inspection',
        xAxis: [],
        isDataZoomRequired: true
      },
      show: false,
      showEChartLoader: false,
      type: 'memory',
      dataSetsBackgroundColor:  'rgba(41,182,246,1.9)',
      title: 'Memory Stats'
    }
  };

  constructor( private store : Store<AppState>) {
    this.nameSpace = CONSTANT.SOCKET.SERVER.NAMESPACE.LIVE_FEED;
     this.cpuStatsTopic = CONSTANT.SOCKET.SERVER.TOPICS.LIVE_CPU_STATS + '_' + this.assetData._id;
     this.cpuMemoryTopic = CONSTANT.SOCKET.SERVER.TOPICS.LIVE_MEMORY_STATS + '_' + this.assetData._id;

  }

  ngOnInit(): void {
    this.store.dispatch(SocketActions.updateSocketDataRequired({
      nameSpace: this.nameSpace,
      topicName: this.cpuStatsTopic,
      key: this.storageName,
      callback: this.getSocketCpuStatsData,
    })); this.store.dispatch(SocketActions.updateSocketDataRequired({
      nameSpace: this.nameSpace,
      topicName: this.cpuMemoryTopic,
      key: this.storageName,
      callback: this.getSocketCpuMemoryData,
    }));

  }

  getSocketCpuStatsData = (data) => {
    this.showCircularLoader = false;
    this.cpu.groupData[moment().format('L, HH:mm:ss')] = data;
    this.cpu.xAsix = keys(this.cpu.groupData);
    this.drawCpuStatsChart(this.cpu.xAsix, this.cpu.groupData);
  }
  getSocketCpuMemoryData = (data) => {
    this.showCircularLoader = false;
    this.memory.groupData[moment().format('L, HH:mm:ss')] = data;
    this.memory.xAsix = keys(this.memory.groupData);
    this.drawMemoryStatsChart(this.memory.xAsix, this.memory.groupData);
  }
  ngOnChanges() {

  }

  ngOnDestroy() {
    this.store.dispatch(SocketActions.disconnect({
      nsp: this.nameSpace,
      topic: this.cpuStatsTopic,
      key: this.storageName
    }));
    this.store.dispatch(SocketActions.disconnect({
      nsp: this.nameSpace,
      topic: this.cpuMemoryTopic,
      key: this.storageName
    }));

  }

  drawCpuStatsChart(xAxis, groupData) {
    const yAxis = {
      currentLoad: [],
    };
    forEach(xAxis, function(xValue) {
      if (groupData && groupData[xValue] &&  groupData[xValue]['currentLoad']
        &&  groupData[xValue]['currentLoad']['currentload'] || groupData[xValue]['currentLoad']['currentload'] === 0) {
        const currentload = groupData[xValue]['currentLoad']['currentload'];
        yAxis['currentLoad'].push(currentload);
      } else {
        yAxis['currentLoad'].push(null);
      }
    });
    this.drawEChars(xAxis, yAxis, 'cpu', 'Cpu Stats');
  }

  drawMemoryStatsChart(xAxis, groupData) {
    const yAxis = {
      used: [], swapTotal: [], swapUsed: [], total: []
    };
    forEach(xAxis, function(xValue) {
      if (groupData && groupData[xValue] && groupData[xValue]
        && groupData[xValue] &&
        groupData[xValue]['used'] ) {
        yAxis.total.push((groupData[xValue]['total'] / (1024 * 1024 * 1024)).toFixed(2));
        yAxis.used.push((groupData[xValue]['used'] / (1024 * 1024 * 1024)).toFixed(2));
        yAxis.swapTotal.push((groupData[xValue]['swaptotal'] / (1024 * 1024 * 1024)).toFixed(2));
        yAxis.swapUsed.push((groupData[xValue]['swapused'] / (1024 * 1024 * 1024)).toFixed(2));
      } else {
        yAxis.total.push(null);
        yAxis.used.push(null);
        yAxis.swapTotal.push(null);
        yAxis.swapUsed.push(null);
      }
    });
    this.drawEChars(xAxis, yAxis, 'memory', 'Memory Stats');
  }

  drawEChars(label, series, value, title) {
    this.eChartGraph[value].showEchartLoader = false;
    const type = 'line';
    this.eChartGraph[value].graph.title = title;
    this.eChartGraph[value].graph.type = type;
    this.eChartGraph[value].graph.legend = keys(series);
    this.eChartGraph[value].graph.series = map(this.eChartGraph[value].graph.legend, function(seriesName) {
      const eChartSeries: EChartSeries = {
        data: series[seriesName],
        type: type,
        name: seriesName,
        stack: false,
        smooth: false,
        label: {normal: {show: false, position: 'top'}},
        areaStyle: {opacity: 0.005},
        itemStyle: {}
      };
      return eChartSeries;
    });
    this.eChartGraph[value].graph.xAxis = label;
    this.eChartGraph[value].show = true;
    this.eChartGraph[value].graph = clone(this.eChartGraph[value].graph);
    this.eChartGraph[value].show = clone(this.eChartGraph[value].show);
  }

}

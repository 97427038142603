<!-- prime card  clickable-->
@if (isCardClickable) {
  <p-card class="p-thing-card mb-3 mt-2" [ngClass]="{'active' : groupData && groupData['cardClicked']}">
    <ng-template pTemplate="header">
      <div class="card-header" (click)="editCardData(groupData)" [ngClass]="{'cardClickable': isCardClickable}">
        @if (cardLabel) {
          <h4 class="card-title TTC" [pTooltip]="cardLabel" tooltipPosition="left">{{ cardLabel }}</h4>
        }
      </div>
    </ng-template>
    <div class="p-card-body card-body pb-3" [ngClass]="{'cardClickable': isCardClickable}"
         (click)="editCardData(groupData)">
      <div class="card-text my-1">
        <div>
          <span class="field-label" pTooltip="{{'app.sidemenu.groups.card.label' | translate}}"
                tooltipPosition="left">{{ 'app.sidemenu.groups.card.label' | translate }}
            <span class="colon">:</span>
          </span>
          <span class="field-value" pTooltip={{groupData.label}} tooltipPosition="left">{{ groupData.label }}</span>
        </div>
      </div>
      <div class="card-text my-1">
        <div>
          <span class="field-label" pTooltip="{{'app.sidemenu.groups.card.color' | translate}}"
                tooltipPosition="left">{{ 'app.sidemenu.groups.card.color' | translate }}
            <span class="colon">:</span>
          </span>
          <span class="field-value color" pTooltip={{groupData.color}} tooltipPosition="left"
                [ngStyle]="{'background':groupData.color}"></span>
        </div>
      </div>
      <div class="card-text my-1">
        <div>
          <span class="field-label" pTooltip="{{'app.sidemenu.groups.card.thing-types' | translate}}"
                tooltipPosition="left" translate>{{ 'app.sidemenu.groups.card.thing-types' | translate }}
            <span class="colon">:</span>
          </span>
          <span class="field-value" pTooltip={{getLangLabels(groupData?.assetTypesLabels)}}
                tooltipPosition="left">{{ getLangLabels(groupData?.assetTypesLabels) }}</span>
        </div>
      </div>
      @if (groupData?.regionalLanguage) {
        <div class="card-text my-1">
          <div>
            <span class="field-label" pTooltip="Regional Language" tooltipPosition="left">Language
              <span class="colon">:</span>
            </span>
            <span class="field-value" pTooltip={{groupData.regionalLanguage}}
                  tooltipPosition="left">{{ groupData.regionalLanguage }}</span>
          </div>
        </div>
      }
    </div>
    <ng-template pTemplate="footer" class="border-top">
      <div class="grid mt-2">
        @if (showDeleteButton) {
          <div class="col-3">
            @if (ACTIONS.DELETE | able: SUBJECT.GROUP) {
              <span (click)="deleteGroupClicked(groupData)" pTooltip="Delete" tooltipPosition="left" id="cardDeleteBtn">
                <i class="pi pi-trash cursorPointer "></i>
              </span>
            }
          </div>
        }
        @if (groupData.updated) {
          <div class="date text-right mt-1 lighterColor" [ngClass]="showDeleteButton ? 'col-9' : 'col-12'">
            <span class="dotsOnOverflow"
                  pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(groupData.updated)}}"
                  tooltipPosition="top">{{ dateFormatter.convertDateToSelectedTimezone(groupData.updated) }}</span>
          </div>
        }
      </div>
    </ng-template>
  </p-card>
}


<!-- legacy material component -->
<!--================================================================-->




@if (!isCardClickable) {
  <p-card class="p-thing-card mb-3" [ngClass]="{'cardClickable':isCardClickable === true}"
    [ngClass]="groupData['cardClicked'] ? 'active' : (groupData['isGroupSelected'] ? 'active': '') "
    (click)="selectedGroupsForLinking()">
    <ng-template pTemplate="header">
      <div class="card-header">
        @if (cardLabel) {
          <h4 class="card-title TTC" [pTooltip]="cardLabel" tooltipPosition="left">{{ cardLabel }}</h4>
        }
      </div>
    </ng-template>
    <div class="p-card-body card-body ">
      <div class="card-text my-1">
        <div>
          <span class="field-label" pTooltip="{{'app.sidemenu.groups.card.label' | translate}}"
                tooltipPosition="left">{{ 'app.sidemenu.groups.card.label' | translate }}
            <span class="colon">:</span>
          </span>
          <span class="field-value" pTooltip={{groupData.label}} tooltipPosition="left">{{ groupData.label }}</span>
        </div>
      </div>
      <div class="card-text my-1">
        <div>
          <span class="field-label" pTooltip="{{'app.sidemenu.groups.card.color' | translate}}"
                tooltipPosition="left">{{ 'app.sidemenu.groups.card.color' | translate }}
            <span class="colon">:</span>
          </span>
          <span class="field-value color" pTooltip={{groupData.color}} tooltipPosition="left"
                [ngStyle]="{'background':groupData.color}"></span>
        </div>
      </div>
      <div class="card-text my-1">
        <div>
          <span class="field-label" pTooltip="{{'app.sidemenu.groups.card.thing-types' | translate}}"
                tooltipPosition="left">{{ 'app.sidemenu.groups.card.thing-types' | translate }}
            <span class="colon">:</span>
          </span>
          <span class="field-value" pTooltip={{getLangLabels(groupData?.assetTypesLabels)}}
                tooltipPosition="left">{{ getLangLabels(groupData?.assetTypesLabels) }}</span>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer" class="border-top mt-2 not-clickable">
      @if (groupData.updated) {
        <div class="date text-right mt-1 col-12 lighterColor">
          <span class="dotsOnOverflow"
                pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(groupData.updated)}}"
                tooltipPosition="top">{{ dateFormatter.convertDateToSelectedTimezone(groupData.updated) }}</span>
        </div>
      }
    </ng-template>
  </p-card>
}

<!-- - ------------------------------------------------------------------------------------------------------------------------------------>

<!--card Not Clickable-->




import {Injectable} from '@angular/core';

declare var $: any;

@Injectable()
export class SetCssPropertiesService {

  showMessagePopup(popup: string, animationSpeed?: number, animationDuration?: number) {
    // console.log(popup, animationDuration, animationSpeed);
    $('.' + popup).fadeIn(animationSpeed ? animationSpeed : 300);
    setTimeout(() => {
      $('.' + popup).fadeOut(animationSpeed ? animationSpeed : 300);
    }, (animationDuration ? animationDuration : 3000));
  }

  fadeOutCustomTooltip(popup: string, animationSpeed?: number, animationDuration?: number) {
    // console.log(popup, animationDuration, animationSpeed);
    setTimeout(() => {
      $('.' + popup).fadeOut(animationSpeed ? animationSpeed : 300);
    }, (animationDuration ? animationDuration : 3000));
  }

  formateCamelCaseToSentence(camelCase: string) {
    const result = camelCase.toString().replace(/([a-z])([A-Z])/g, '$1 $2');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  arrayToFormattedString(value: string | any[]) {
    let str: string;
    if (Array.isArray(value)) {
      str = value.toString().split(',')
        .map(w => w.charAt(0).toUpperCase() + w.slice(1))
        .join(', ');
    } else {
      str = value.charAt(0).toUpperCase() + value.slice(1);
    }
    return str;
  }

  getFullScreen(id: string) {
    const elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen().then(r => r);
    } else if (elem?.['webkitRequestFullscreen']) { /* Safari */
      elem?.['webkitRequestFullscreen']();
    } else if (elem?.['msRequestFullscreen']) { /* IE11 */
      elem?.['msRequestFullscreen']();
    }
  }
}

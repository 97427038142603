@if (eChartGraph['cpu'].show) {
  <div class="card-border mt-2 mb-3 PT10">
    <app-e-charts [loader]="eChartGraph['cpu'].showEchartLoader" [chartData]="eChartGraph['cpu'].graph"
                  [show]="eChartGraph['cpu'].show"></app-e-charts>
  </div>
}

@if (eChartGraph['memory'].show) {
  <div class="card-border mt-2 mb-3 PT10">
    <app-e-charts [loader]="eChartGraph['memory'].showEchartLoader" [chartData]="eChartGraph['memory'].graph"
                  [show]="eChartGraph['memory'].show"></app-e-charts>
  </div>
}

@if (showCircularLoader) {
  <div>
    <app-circular-loader></app-circular-loader>
  </div>
}

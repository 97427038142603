import {AppState} from '../app.state';


export const getUserMapSelector = (state: AppState) => state.user.userMap;


export const getGooglePlaceApiKeySelector = (state: AppState) => state.user.googlePlaceApiKey;


export const getAddressDetailsSelector = (state: AppState) => state.user.addressDetails;




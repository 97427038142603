import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {Group} from '../../../interfaces/group';
import {AssetGroupService} from '../../../services/asset.group.service';
declare  var $: any;
import {find, remove} from 'lodash';
import {LangUtilService} from '../../util/lang-util.service';
@Component({
  selector: 'app-groups-for-adding',
  templateUrl: './groups-for-adding.component.html',
  styleUrls: ['./groups-for-adding.component.scss']
})
export class GroupsForAddingComponent implements OnChanges, OnInit {
  @Input() assetData: any;
  @Input() isGetGroupsInitiated: number;
  @Input() clearGroups: number;
  groups: Array<Group> = [];
  showLoader: boolean;
  public isGroupsNotFound: boolean;
  _groupPaginationFields = {
    pagination: {
      'currentPage' : 1,
      'totalNoOfRecords': 0,
      'recordsPerPage' : 6,
      'pageCountValue' : 0,
    },
    pageNumber: 1,
    noOfPagesList: []
  };
  fieldsToBeDisplayed = ['label', 'color', 'assetTypes'];
  searchForGroup: string;
  public selectedGroupIdsToAdd: any = [];
  @Output() addGroupDetailsToSlider: EventEmitter<any> = new EventEmitter();
  constructor(private assetGroupService: AssetGroupService, private lang: LangUtilService) {
    this.isGroupsNotFound = false;
    this.showLoader = false;
    this.searchForGroup = '';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'isGetGroupsInitiated') {
        if (change.currentValue !== change.previousValue) {
          if (this.isGetGroupsInitiated) {
            this.resetSearchedGroups();
            this.getGroupsCount();
          }
        }
      }
      if (propName === 'clearGroups') {
        if (change.currentValue !== change.previousValue) {
          this.resetSearchedGroups();
        }
      }
    }
  }

  getGroupsCount() {
    this.showLoader = true;
    this.isGroupsNotFound = false;
    if(this.assetData){
    this.assetGroupService.getGroupCount(this.searchForGroup, this.assetData['assetType'])
      .subscribe(result => {
        this.showLoader = false;
        if (result && result['count']) {
          this._groupPaginationFields.pagination.totalNoOfRecords = result['count'];
          let obj: any = {};
          obj['pageIndex'] = 0;
          this.getPageCount();
          this.getAllGroups(obj);
        } else {
          this.groups = [];
          this.isGroupsNotFound = true;
        }
      });
    }
  }

  getAllGroups(currentPage) {
    this.showLoader = true;
    let recordsPerPage;
    let skip;
    this._groupPaginationFields.pageNumber = currentPage.page + 1;
    if (currentPage.pageIndex === 0) {
      skip = 0;
      recordsPerPage = this._groupPaginationFields.pagination.recordsPerPage;
    } else {
      skip = (currentPage.page) * this._groupPaginationFields.pagination.recordsPerPage;
      recordsPerPage = this._groupPaginationFields.pagination.recordsPerPage;
    }
    this.assetGroupService.getGroups(skip, recordsPerPage, this.searchForGroup, this.assetData['assetType'])
      .subscribe((groups: Group[]) => {
        this.showLoader = false;
        this.groups = groups;
        ((this.groups.length > 0) ? (this.isGroupsNotFound = false) : (this.isGroupsNotFound = true));
      });
  }

  getPageCount() {
    this._groupPaginationFields.noOfPagesList = [];
    this._groupPaginationFields.pageNumber = 1;
    this._groupPaginationFields.pagination.pageCountValue = Math.ceil((this._groupPaginationFields.pagination.totalNoOfRecords) / this._groupPaginationFields.pagination.recordsPerPage);
    for (let i = 1; i <= this._groupPaginationFields.pagination.pageCountValue; i++) {
      this._groupPaginationFields.noOfPagesList[i] = i;
    }
  }

  public updatePageNumber(index: number): void {
    this._groupPaginationFields.pageNumber = index;
  }

  searchGroup() {
    this.selectedGroupIdsToAdd = [];
    this.getGroupsCount();
  }

  groupDetailsToAdd(groupDetailsToAdd) {
    if (this.selectedGroupIdsToAdd.length > 0) {
      $('.buttonEnable').attr('disabled', false);
    } else {
      $('.buttonEnable').attr('disabled', true);
    }
    this.emitSelectedAssetIdsTOSlider(groupDetailsToAdd);
  }

  emitSelectedAssetIdsTOSlider(groupDetailsToAdd) {
    // this.linkAssetsDetails = groupDetailsToAdd;
    if (groupDetailsToAdd.isGroupSelected === true) {
      let res;
      res = find(this.selectedGroupIdsToAdd, function(o) { return o === groupDetailsToAdd._id; });
      if (!res) {
        this.selectedGroupIdsToAdd.push(groupDetailsToAdd._id);
      }
    } else if (groupDetailsToAdd.isGroupSelected === false) {
      remove(this.selectedGroupIdsToAdd, function(n) {
        return (n === groupDetailsToAdd._id);
      });
    }
    this.addGroupDetailsToSlider.emit(this.selectedGroupIdsToAdd);
  }

  resetSearchedGroups() {
    this.searchForGroup = '';
    this.selectedGroupIdsToAdd = [];
    this.groups = [];
  }

  getLangLabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }
}

<!-- <div class="popup normal delinkAlertPopup" >
<div class="x close">X</div>
<div class="popupCont subNormal">
  <div class="marginBtm mainNormal" translate>app.core.alert-popup.delink-asset-confirmation</div>
  <div class="btnMain small">
    <button (click)="confirmDelink()" class="close p-button-outlined" color="warn" pButton pRipple
    type="button">{{'sys.yes' |translate}}</button>
    <button pButton pRipple type="button" color="accent" class="p-button-outlined  p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
  </div>
</div>
</div> -->

<!-- <div class="popup normal delinkServicePopup">
<div class="x close">X</div>
<div class="popupCont subNormal">
  <div class="marginBtm mainNormal" translate>app.core.alert-popup.delink-service-confirmation</div>
  <div class="btnMain small">
    <button pButton pRipple type="button" color="warn" class="close p-button-outlined" (click)="confirmServiceDelink()">{{'sys.yes' |translate}}</button>
    <button pButton pRipple type="button" color="accent" class="p-button-outlined  p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
  </div>
</div>
</div> -->

<!-- <div class="popup normal linkAlertPopup">
<div class="x close">X</div>
<div class="popupCont subNormal">
  <div class="marginBtm mainNormal">{{'app.core.alert-popup.link-selected' | translate}} <span class="count green-color">{{selectedAssetToLinkCount}}</span> asset</div>
  <div class="btnMain small">
    <button pButton pRipple type="button" color="primary" class="close p-button-outlined" (click)="linkingConfirmed()">{{'sys.yes' |translate}}</button>
    <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
  </div>
</div>
</div> -->

<div class="popup normal linkOrAddActionAlertPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{linkOrAddActionDescription}} <span class="count green-color">{{linkOrAddActionCount}}</span> {{linkOrAddActionLabel}}</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="primary" class="close p-button-outlined " (click)="linkOrAddActionConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined  p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal delinkOrRemoveActionAlertPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{delinkOrRemoveActionDescription}} <span class="count red-color">{{delinkOrRemoveActionCount}}</span> {{delinkOrRemoveActionLabel}}</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="primary" class="close p-button-outlined" (click)="delinkOrRemoveActionConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class=" p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal linkServicePopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{'app.core.alert-popup.link-selected' | translate}} <span class="count green-color">{{selectedAssetToLinkCount}}</span> service</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="primary" class="close p-button-outlined" (click)="linkingServiceConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class=" p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal addGroupPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{'app.core.alert-popup.add-selected' | translate}} <span
      class="count green-color">{{ selectedGroupsToAddCount }}</span> {{ selectedGroupsToAddCount > 1 ? ('sys.groups' | translate) : ('sys.group' | translate) }}
    </div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="primary" class="close p-button-outlined"
              (click)="addGroupConfirmed()">{{ 'sys.yes' |translate }}
      </button>
      <button pButton pRipple type="button" color="accent"
              class="p-button-outlined p-button-secondary cancel close MR10">{{ 'sys.cancel' |translate }}
      </button>
    </div>
  </div>
</div>

<div class="popup normal removeGroupPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{'sys.remove-select-confirm' | translate}} <span
      class="count red-color">{{ selectedGroupsToRemoveCount }}</span> {{ selectedGroupsToRemoveCount > 1 ? ('sys.groups' | translate) : 'sys.group' | translate }}
    </div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="primary" class="p-button-outlined close"
              (click)="removeGroupConfirmed()">{{ 'sys.yes' |translate }}
      </button>
      <button pButton pRipple type="button" color="accent"
              class="p-button-outlined p-button-secondary cancel close MR10">{{ 'sys.cancel' |translate }}
      </button>
    </div>
  </div>
</div>

<div class="popup normal deregisterAssetPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{'app.core.alert-popup.de-register-asset-selected' | translate}}</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="warn" class="p-button-outlined close" (click)="deregisteringAssetConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal deleteUserApiKeysPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{msgToDisplay}} <span class="red-color FW500">{{labelToDelete}}</span></div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="warn" class="p-button-outlined close" (click)="deleteUserApiKeysConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal deleteAssetPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{msgToDisplay}} <span class="red-color FW500">{{labelToDelete}}</span></div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="warn" class="p-button-outlined close" (click)="deletingAssetConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal registerExceptionBeconPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{'app.core.alert-popup.register-exception-beacon' | translate}}</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="warn" class="p-button-outlined close" (click)="registerExceptionBeaconConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary btn cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal deregisterExceptionBeaconPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{'app.core.alert-popup.de-register-selected' | translate}} <span class="count red-color">{{selectedExceptionAssetsCount}}</span> Exception Beacon</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="warn" class="p-button-outlined close" (click)="deregisterExceptionBeaconConfirmed()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal serverActionPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{ 'sys.user-confirm' | translate }} @if (serverAction) {
      <span class="count"
            [ngClass]="(serverAction.label === 'Start') ? 'green-color' : (serverAction.label === 'Stop') ? 'red-color' : (serverAction.label === 'Restart') ? 'blue-color' : ''"
      >{{ serverAction.label }}</span>
    } the server
    </div>
    <div class="btnMain small">
      @if (serverAction) {
        <button pButton pRipple type="button"
                [color]="(serverAction.label === 'Start') ? 'primary' : (serverAction.label === 'Stop') ? 'warn' : (serverAction.label === 'Restart') ? 'primary' : 'primary'"
                class="p-button-outlined close"
                (click)="serverActionConfirmed()">{{ 'sys.yes' |translate }}
        </button>
      }

      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal genericApprovalConfirmationPopup">
  <div class="x close" (click)="cancelBtnClicked()">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{message}}</div>
    @if (note) {
      <div class="marginBtm mainNormal">{{ note }}</div>
    }
    <div class="btnMain small">
      <button pButton pRipple type="button" [disabled]="disableOkBtn" color="primary" class="close p-button-outlined" (click)="confirmApproval()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10" (click)="cancelBtnClicked()">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal genericErrorMsgsPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">@for (error of errorMessages; track error) {
      <span style="display: block">
        {{ error }}
      </span>
    }</div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary close">{{'sys.ok' | translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal genericWarningConfirmationPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <div class="marginBtm mainNormal">{{msgToDisplay}} <span class="red-color FW500">{{labelToDelete}}</span></div>
    <div class="btnMain small">
      <button pButton pRipple type="button" color="warn" class="p-button-outlined close" (click)="confirm()">{{'sys.yes' |translate}}</button>
      <button pButton pRipple type="button" color="accent" class="p-button-outlined p-button-secondary cancel close MR10">{{'sys.cancel' |translate}}</button>
    </div>
  </div>
</div>

<div class="popup normal missingDataPopup">
  <div class="x close">X</div>
  <div class="popupCont subNormal">
    <h4>Missing Fields</h4>
    <div class="marginBtm mainNormal">{{msgToDisplay}}</div>
  </div>
</div>

<div class="background"></div>


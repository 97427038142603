<div class="assetListGrid P10" id="groupListingPopup">
  <div class="grid mx-0 my-1 py-1 justify-content-between align-items-center group-search-container">
    <!--  Search Box-->
    <div class="input-outer-grid lg:col-6">
      <span class="p-input-icon-left full-width">
        <i class="pi pi-search"></i>
        <input type="text" placeholder="{{'sys.search-group' | translate}}" pInputText
               class="form-control" [autocomplete]="false" name="searchForAsset"
               [(ngModel)]="searchForGroup" (ngModelChange)="searchGroup()"/>
      </span>
    </div>
    <div class="grid mx-0 my-2 py-2 group-btn-container">
      @if (selectedGroupIdsToAdd.length > 0) {
        <button pButton color="accent" type="button"
                class="linkBtn showAddGroupPopupBtn buttonEnable mx-1 p-button-secondary"
        ><i class="pi pi-plus"
            style="margin-right: 4px;"></i> {{ selectedGroupIdsToAdd.length === 1 ? ' ' + getLangLabel('sys.group') : ' ' + getLangLabel('sys.groups') }}
        </button>
      }
      <button pButton color="warn" id="groupListBackBtn" class="mx-1 p-button-danger"
              (click)="resetSearchedGroups()">{{ 'sys.cancel' | translate }}
      </button>
    </div>
  </div>

  <div class="grid mx-0 my-1 mt-3 userManagement-cards-container">
    @for (group of groups; track group) {
      <div class="details-card-deck userManagement-card-deck col-12 md:col-6 align-items-center mb-3"
           pTooltip="{{'sys.sys.click-sel-add-group' | translate}}" tooltipPosition="right">
        <app-group-card [groupData]="group" [fieldsToBeDisplayed]="fieldsToBeDisplayed" [isCardClickable]="false"
                        [canAddToAsset]="true"
                        [cardLabel]="'label'" (linkGroupDetails)="groupDetailsToAdd($event)"></app-group-card>
      </div>
    }
    @if (isGroupsNotFound) {
      <div class="col-12" style="overflow: hidden;">
        <div class="grid align-items-center justify-content-center" style="height:100%">
          <h3 class="asset-not-found text-center"><span>&ldquo;</span>{{ 'sys.groups-not-found' | translate }}<span>&rdquo;</span>
          </h3>
        </div>
      </div>
    }


  </div>

  <div class="grid" style="margin-top: -5px;">
    @if (groups.length > 0) {
      <div class="md:col-12  col-12 flex justify-content-center MT20">
        @if (_groupPaginationFields.pagination.totalNoOfRecords > 0) {
          <p-paginator class="prime-paginator" [totalRecords]="_groupPaginationFields.pagination.totalNoOfRecords"
                       [showJumpToPageDropdown]="true" (onPageChange)="getAllGroups($event)"
                       [rows]="_groupPaginationFields.pagination.recordsPerPage"></p-paginator>
        }
      </div>
    }
  </div>

  @if (showLoader) {
    <div>
      <app-circular-loader></app-circular-loader>
    </div>
  }
</div>

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import * as _ from 'lodash';
//import { Chart } from 'chart.js';
import { EChartDataType , EChartSeriesUpdate } from '../e-charts/e-charts-data-type';


@Component({
  selector: 'app-car-status-graph',
  templateUrl: './car-status-graph.component.html',
  styleUrls: ['./car-status-graph.component.scss']
})
export class CarStatusGraphComponent implements OnChanges, OnInit {

  @Input() chartData: EChartDataType;
  @Input() graphTypeChangedRandomNumber: number;
  @Input() loader: boolean;
  @Input() isSmallEChart: boolean;
  @Input() gapBelowTitle: string;
  @Input() show: boolean;
  @Input() type = 'line';
  @Input() updateValue: EChartSeriesUpdate;
  @Input() showPills: boolean;
  @Input() showLeftPills: boolean;
  @Input() isPillTabToggleRequired: boolean;
  @Input() pillActiveColour: string;
  @Input() defaultActivePillIndex: number;
  @Input() arrayOfPills: any;
  @Input() leftPillActiveColour: string;
  @Input() leftDefaultActivePillIndex: number;
  @Input() leftArrayOfPills: any;
  @Input() downloadArrayOfPills: any;
  @Input() showTitleWithCounts: boolean;
  @Input() title: string;
  @Input() count: number;
  @Input() countArray: Array<Count>;
  @Input() showChartFilterMenu: boolean;
  @Input() isDataZoomRequired: boolean;
  @Input() isTotalNotRequired: boolean;
  @Input() forceChartHeightInPx: string;
  @Input() wrapperCardHeightInPx: string;
  @Input() isGraphStackable: boolean;
  @Input() graphData: any;


  @Output() activePillValue: EventEmitter<string> = new EventEmitter();
  @Output() graphTypePillValue: EventEmitter<any> = new EventEmitter();
  @Output() downloadTypePillValue: EventEmitter<string> = new EventEmitter();
  @Output() sendDataToPlotMarker: EventEmitter<string> = new EventEmitter();
  @Output() sendDataToOpenInModalView: EventEmitter<string> = new EventEmitter();
  @Output() sendDataToNavigateToSelectedComponent: EventEmitter<string> = new EventEmitter();
  
  filterMenuOptionsArray: any;
  constructor() {
    this.isSmallEChart = true;
    this.loader = false;
    this.show = false;
    this.showPills = false;
    this.showLeftPills = false;
    this.showTitleWithCounts = false;
    this.showChartFilterMenu = false;
    this.wrapperCardHeightInPx = '444px';
    // this.title = '';
  }

  ngOnInit() {
    this.leftArrayOfPills = [];
    this.downloadArrayOfPills = [];
    
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'loader') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.loader = change.currentValue;
        }
      }
      if(propName === 'arrayOfPills') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.initializeChartFilterMenu('arrayOfPills')
        }
      }
      if(propName === 'leftArrayOfPills') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.initializeChartFilterMenu('leftArrayOfPills')
        }
      }
      if(propName === 'downloadArrayOfPills') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
         this.initializeChartFilterMenu('downloadArrayOfPills')
        }
      }
    }
  }

  initializeChartFilterMenu(pillsType) {
    if(pillsType === 'leftArrayOfPills' && this.leftDefaultActivePillIndex < this.leftArrayOfPills.length) {
        let temp = _.cloneDeep(this.leftArrayOfPills);
        temp[this.leftDefaultActivePillIndex]['active'] = true
      _.forEach(temp, (pillobj) => {
        pillobj['filterType'] = 'chart Type'
      });    
      this.leftArrayOfPills = _.cloneDeep(temp);
    }
    if(pillsType === 'arrayOfPills' && this.defaultActivePillIndex < this.arrayOfPills.length) {
      let temp = _.cloneDeep(this.arrayOfPills);
      temp[this.defaultActivePillIndex]['active'] = true 
      temp.forEach((pillobj) => {
        pillobj['filterType'] = 'series'
      });
      this.arrayOfPills = _.cloneDeep(temp);
    }
    this.filterMenuOptionsArray = this.concatenateArraysIfDataExists(this.leftArrayOfPills,this.arrayOfPills);

    if(pillsType === 'downloadArrayOfPills'
      && this.downloadArrayOfPills.length > 0) {   
      let temp = _.cloneDeep(this.downloadArrayOfPills);
      temp.forEach((pillobj) => {
        pillobj['filterType'] = 'download'
      });
      this.downloadArrayOfPills = _.cloneDeep(temp);
      let downloadArray: any;
      downloadArray = this.concatenateArraysIfDataExists(this.filterMenuOptionsArray,this.downloadArrayOfPills)
      this.filterMenuOptionsArray = _.cloneDeep(downloadArray);
    }
  }

  sendDataToPlotMarkerOnMap(event){
    this.sendDataToPlotMarker.emit(event);
  }
  sendDataToOpenInModal(event){
    this.sendDataToOpenInModalView.emit(event);
  }
  sendDataToNavigateToSelectedComp(event){
    this.sendDataToNavigateToSelectedComponent.emit(event);
  }

   concatenateArraysIfDataExists(arr1, arr2) {
    if (arr1?.length > 0 && arr2?.length > 0) {
        return arr1.concat(arr2);
    } else if (arr1?.length > 0) {
        return arr1;
    } else if (arr2?.length > 0) {
        return arr2;
    } else {
        return [];
    }
}
  getActiveDistancePillValue(activePill) {
    this.activePillValue.emit(activePill);
  }

  getGraphType(data,status=false) {
    if(!status || !this.isGraphStackable){
      typeof data==='string' ? this.graphTypePillValue.emit(data) : this.graphTypePillValue.emit(data.graphType)
    }else{
       this.graphTypePillValue.emit({graphType:data.graphType,isStacked:data.isStacked})
    }
  }

  sendDownloadTypePillValue(id) {
    this.downloadTypePillValue.emit(id)
  }

  getSelectedChartFilterOption(data) {
    data.filterType === 'chart Type' ? this.getGraphType(data,true) : 
    data.filterType === 'series'? this.getActiveDistancePillValue(data.id) : 
    this.sendDownloadTypePillValue(data.id);
  }
}

export interface Count {
  count: number;
  tooltip: string;
  subTitle: string;
  unit: string;
}
<div id="pill-tab-toggle" [ngClass]="{'toggle-required': isPillTabToggleRequired, 'drop-down-toggle': isdropDownPillRequired}" class="cursorPointer defaultBorder"
     pTooltip="{{activePill?.label}}" tooltipPosition="top">
  <div class="toggle-label" [ngStyle]="{backgroundColor: getBackGroundColour(activePill?.id)}"
       [ngClass]="{'active': isPillActive(activePill?.id)}">
    @if (isPillActive(activePill?.id) && activePill?.activeImgSrc) {
      <img class="pill-label-icon" src="{{activePill?.activeImgSrc}}">
    }
    @if (activePill?.label) {
      <span>{{ restrictToggleLabelLength(activePill?.label) }}</span>
    }
  </div>
</div>

<div id="pill-tabs-container" [ngClass]="{'toggle-required': isPillTabToggleRequired, 'drop-down-menu': isdropDownPillRequired}" class="tabs-container defaultBorder">
  @for (value of arrayOfPills; track value) {
    <div class="tab-inner">
      <div class="tab" [ngClass]="{'defaultCursor': (isDisableUnactivePills && isPillActive(value.id))}">
        <div class="tab-text tab" [ngStyle]="{backgroundColor: getBackGroundColour(value.id)}"
             pTooltip={{getLangLabel(value.description)}} tooltipPosition="bottom"
             [ngClass]="{'active': isPillActive(value.id), 'disabledbutton': (isDisableUnactivePills && isPillActive(value.id))}"
             (click)="onPillSelected(value)">
          @if (isPillActive(value.id) && value.activeImgSrc) {
            <img class="pill-label-icon" src="{{value.activeImgSrc}}"
                 [ngClass]="{'onlineOfflineIcon': (value.id === 'online' || value.id === 'offline')}">
          }
          @if (!isPillActive(value.id) && value.inActiveImgSrc) {
            <img class="pill-label-icon" src="{{value.inActiveImgSrc}}"
                 [ngClass]="{'onlineOfflineIcon': (value.id === 'online' || value.id === 'offline')}">
          }
          @if (value.label && value.icon) {
            <span><i class="{{value.icon}} mr-1"></i>{{ value.label }}</span>
          }
          @if (value.label && !value.icon) {
            <span>{{ getLangLabel(value.label) }}</span>
          }
        </div>
      </div>
    </div>
  }
</div>

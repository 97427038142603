@if (showGraph && !showDataUnavailabe) {
  <div>
    <div echarts [options]="options" [ngClass]="{'small-e-chart':isSmallEChart}" [loading]="isLoading" theme="macarons"
         (chartClick)="onChartClick($event)"></div>
  </div>
}
@if (showDataUnavailabe) {
  <div style="text-align: center; width: 100%; height: auto; position: absolute; left: 0; top: 100px; z-index: 499;">
    <h4 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data-available' | translate }}<span>&rdquo;</span></h4>
  </div>
}

import {Injectable } from "@angular/core";
import { initializeApp, FirebaseApp, FirebaseOptions } from "firebase/app";
import { Analytics, getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
@Injectable({
  providedIn: "root",
})
export class FirebaseService{
  private app: FirebaseApp;
  private analytics: Analytics; 
  private defaultConfig: FirebaseOptions = {
    apiKey: "AIzaSyD4csxBJPxLAqE4dpz5LcT9PUQtJ-Pxc3c",
    authDomain: "iotzen-web.firebaseapp.com",
    projectId: "iotzen-web",
    storageBucket: "iotzen-web.appspot.com",
    messagingSenderId: "558918616049",
    appId: "1:558918616049:web:a6b66e8b5d002c437fba50",
    measurementId: "G-441MDDE8Z8"
  }
  private VAPID_KEY = 'BBcCGrgOiMCXzLA9u5L_lajdlLRA1zJ8_hPaFr1DpoDHK1AAWpUUPQClPPXOpCjTeU5GQGWSOEn6bqnLMK7RIfA';

  constructor() {
    this.init();
  }

  init(config?: FirebaseOptions) {
    const firebaseConfig = config ? config : this.defaultConfig;
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
  }

  async requestPermission() {
    if(typeof Notification.requestPermission !== 'function'){
      return
    }
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
    return permission;
  }

  async getToken() {
    const messaging = getMessaging();
    const currentToken = await getToken(messaging, { vapidKey: this.VAPID_KEY })
    if (currentToken) {
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      await this.requestPermission();
    }
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
    });
  }

  aSetUserID(user: string) {
    setUserId(this.analytics, user);
  }

  aLogEvent(eventName: string, eventParams?: {[key: string]: any}) {
    logEvent(this.analytics, eventName, eventParams);
  }

}

<button pButton type="button" icon="pi pi-ellipsis-v" (click)="menu.toggle($event)" class="p-button-rounded p-button-text p-button-plain">
</button>
<p-menu #menu [popup]="true" [model]="items">
  <ng-template pTemplate="submenuheader" let-item>
    <span class="font-bold">{{ item.label }}
    </span>
  </ng-template>
  <ng-template pTemplate="item" let-item>
    <a class="menu-option" [ngClass]="{'menu-option-selected': item.isCurrentType || item.id === series}">
      <div>
        <span [class]="item.icon">
        </span>
        <span class="ml-2">{{ item.label }}
        </span>
      </div>
      @if (isGraphStackable && item.isStackable) {
        <p-inputSwitch [(ngModel)]="item.isStacked" [pTooltip]="item.isStacked ? 'Disable Stacking' : 'Enable Stacking'"
                       tooltipPosition="top"/>
      }
    </a>
  </ng-template>
</p-menu>

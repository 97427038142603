import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { assetDefaultImageConfigsSelector } from '../state/Assets/assets.selector';
import { AssetImageConfigHolder } from '../state/Assets/interfaces/Assets.modal';

@Injectable({
  providedIn: 'root'
})
export class LinkedAssetIconService {

  assetLinkedImageConfig: AssetImageConfigHolder;
  constructor(private store: Store) {
    this.store.select(assetDefaultImageConfigsSelector).subscribe((configs: AssetImageConfigHolder) => {
      this.assetLinkedImageConfig = configs;
    })

   }

   getIconAndColorsForLinkedAssets(assetData, config){
      for (const i of assetData) {
        const t = i['assetType'] ? i['assetType'] : i['type'];
        for (const j in config) {
          if (i['assetType'] ? i['assetType'] === config[j]['type'] : i['type'] === config[j]['type']) {
            i['color'] = config[j]['theme']['color']['primary'];
            i['highlightedColor'] = config?.[j]?.['theme']?.['color']?.['primary'] ? this.getLighterColor(config[j]['theme']['color']['primary']) : 'red'
            if (this?.assetLinkedImageConfig?.[t]?.url) {
              i['icon'] = this.assetLinkedImageConfig[t]?.url;
            } else {
              i['icon'] = '../../assets/exclamation-red.png';
            }

          }
        }
      }
   }

  getLighterColor(hex: string): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return '#' + this.componentToHex(r + 20) + this.componentToHex(g + 20) + this.componentToHex(b + 20);
  }

  componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
   

}

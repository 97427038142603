import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
declare  var $: any;
import {find, remove} from 'lodash';
import {LangUtilService} from '../../util/lang-util.service';
import {ConfirmationService} from 'primeng/api';
import { assetsCardsImageDisplayStatusSelector } from '../../../state/Assets/assets.selector';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-assets-for-linking',
  templateUrl: './assets-for-linking.component.html',
  styleUrls: ['./assets-for-linking.component.scss']
})
export class AssetsForLinkingComponent implements OnChanges, OnInit {
  searchedAssetForLinking: any;
  resetSearchedAssetOnBackBtn: number;
  public  linkAssetsDetails: any;
  public linkAssetsIds: any = [];
  @Input() allAssetTypes: any;
  @Input() assetData: any;
  @Input() config: any;
  @Input() resetSearchedAssetOnPlusClick: any;
  @Output() linkAssetDetailsToSlider: EventEmitter<any> = new EventEmitter();

  cardsImageDisplayStatus$ = this.store.select(assetsCardsImageDisplayStatusSelector);

  constructor(private lang: LangUtilService,private confirmationService: ConfirmationService,private store: Store) {
  }

  ngOnInit() {}


  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'config') {
        if (change.currentValue !== change.previousValue) {
        }
      }
    }
  }

  assetsLimit=10
  visitedAssetIds=[]
  showCirularLoader: boolean


  searchedAssetsFound ({assets,isSearched}) {
    const count = assets?.length || 0
    this.searchedAssetForLinking = this.setAssetSelectedStatus(assets);
    count>=10 && 
    setTimeout(() => {
      this.showCirularLoader=false
      this.trackLastElementOfAsset(count,isSearched)
    }, 0);
  } 

  setAssetSelectedStatus(searchedAssets){
    let assets = [...searchedAssets];
    this.linkAssetsIds.forEach((id: string) => {
      for (let idx = 0; idx < searchedAssets.length; idx++) {
        if (searchedAssets[idx]._id === id) {
          const assetCopy = { ...assets[idx], isAssetSelected: true };
          assets[idx] = assetCopy;
          break;
        }
      }
    });
     return assets
  }

  linkAssetDetails(linkingAssetDetails) {
    if (this.assetData && this.assetData.assetType) {
      $('.buttonEnable').prop('disabled', false);
      this.emitSelectedAssetIdsTOSlider(linkingAssetDetails);
    } else if (this.assetData && this.assetData.serviceType) {
      this.emitSelectedAssetIdsTOSlider(linkingAssetDetails);
      if (this.linkAssetsIds.length > 1) {
        $('.buttonEnable').attr('disabled', true);
      } else {
        $('.buttonEnable').attr('disabled', false);
      }
    }
  }


  showPopupBtn(event: Event) {
    this.confirmationService.confirm({
        target: event.target,
        message: this.lang.getTranslation('app.core.alert-popup.link-selected') +' '+ this.linkAssetsIds.length + ' asset',
        icon: 'pi pi-exclamation-triangle',
        key: 'assetsForLinkingPopup',
        accept: () => {
         this.linkAssetDetailsToSlider.emit(this.linkAssetsIds);
         setTimeout(() =>{
          this.linkAssetsIds = [];
         }, 100)
        },
        reject: () => {
            //reject action
        }
    });
}


  emitSelectedAssetIdsTOSlider(linkingAssetDetails) {
    this.linkAssetsDetails = linkingAssetDetails;
    if (linkingAssetDetails.isAssetSelected === true) {
      let res;
      res = find(this.linkAssetsIds, function(o) { return o === linkingAssetDetails._id; });
      if (!res) {
        this.linkAssetsIds.push(linkingAssetDetails._id);
      }
    } else if (linkingAssetDetails.isAssetSelected === false) {
      remove(this.linkAssetsIds, function(n) {
        return (n === linkingAssetDetails._id);
      });
    }
    // this.linkAssetDetailsToSlider.emit(this.linkAssetsIds);
  }

  resetSearchedAssets() {
    this.resetSearchedAssetOnBackBtn = Math.random();
    this.resetAssetVisits();
    this.linkAssetsIds = [];
  }

  getLanglabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }


  getSearchVal(searchText: string) {
    this.visitedAssetIds = [];
    if (!searchText) {
    this.assetsLimit = 10;
   }
  }



  trackLastElementOfAsset(pos:number,isSearched:boolean){
    const id="#position_"+pos
    const element = document.querySelector(id)


    const observer = new IntersectionObserver((entries) =>{
      if(((entries?.[0]?.isIntersecting && entries?.[0]?.intersectionRatio) || 
          (entries?.[1]?.isIntersecting && entries?.[1]?.intersectionRatio)) && 
         !this.visitedAssetIds.includes(id) && !isSearched){
          this.showCirularLoader = true
          this.assetsLimit += 10
          this.visitedAssetIds=[...this.visitedAssetIds,id]
        observer.unobserve(element);
      }
    }, { threshold: [0] });
    element && observer.observe(element);
  }

  resetAssetVisits(){
    this.assetsLimit=10
    this.visitedAssetIds=[]
    this.linkAssetsIds = [];
  }
}

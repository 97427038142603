<!--<mat-form-field color="accent" class="full-width filter-form" appearance="outline">-->
<!--  <mat-label>Events Filter</mat-label>-->
<!--  <mat-select placeholder="Event filter" [disableOptionCentering]="true" [(ngModel)]="filterEvent" (ngModelChange)="onFilterEventChange($event)">-->
<!--    <mat-option *ngFor="let event of filterEvents" [value]="event">{{event.eventName}}</mat-option>-->
<!--  </mat-select>-->
<!--</mat-form-field>-->

<div class="wrapper table-container">
  <p-splitter gutterSize="10" [style]="{ height: '100%' }" layout="vertical" (onResizeEnd)="dragEnd()">
    <ng-template pTemplate>
      <div class="flex">
        <div class="car-event-table mat-elevation-z8"
             [ngStyle]="{'height': showDataNotAvailable || !searchedCar ? '100%' : 'calc(100% - 70px)'}"
             [ngClass]="{'long-car-event-table': isLongTableRequired, 'grid mx-0 justify-content-center align-items-center' : showDataNotAvailable || !searchedCar }">
          @if (!searchedCar) {
            <h3 class="asset-not-found">
              <span>&ldquo;</span>{{ 'app.car-event.no-trackable-cars-found' | translate }}<span>&rdquo;</span>
            </h3>
          }
          @if (showDataNotAvailable) {
            <h3 class="asset-not-found">
              <span>&ldquo;</span>{{ 'app.car-event.current-data-not-available' | translate }}<span>&rdquo;</span>
            </h3>
          }

          @if (dataSource.length > 0) {
            <p-table id="excel-table" [value]="dataSource"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>{{ 'sys.event' | translate }}</th>
                  <th>{{ 'sys.description' | translate }}</th>
                  <th>{{ 'sys.time' | translate }}</th>
                  <th>{{ 'sys.location' | translate }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-element>
                <tr (click)="showLocationOnMap(element);highlight(element)"
                    [ngClass]="{'row-highlight': selectedRowIndex == element._id}">
                  <td>{{ element.name }}</td>
                  <td>{{ element.description }}</td>
                  <td>{{ dateFormatter.convertDateToSelectedTimezone(element.generatedTimeStamp, "LL LTS") }}</td>
                  <td
                    pTooltip="Show location on map" tooltipPosition="left"
                  >
                    <img class="event-icon"
                         [src]="element['eventIcon']" alt=""></td>
                </tr>
              </ng-template>
            </p-table>
          }


        </div>
        @if (pagination.totalNoOfRecords > 0) {
          <div class="grid pagination-row eventsPagination-row mx-0">
            <div class="md:col-12  col-12 flex justify-content-center" style="margin-top: 10px">
              @if (pagination.totalNoOfRecords > 0) {
                <p-paginator [rows]="pagination.recordsPerPage"
                             [totalRecords]="pagination.totalNoOfRecords" [rowsPerPageOptions]="[10,20,30]"
                             (onPageChange)="getDataByPageNo($event)"
                             [showJumpToPageDropdown]="true" [showPageLinks]="true" class="row-exp-paginator"
                             currentPageReportTemplate="{first} to {last} of {totalRecords}">
                </p-paginator>
              }
            </div>
          </div>
        }
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div class="map" id="event-table-map" leaflet
           (leafletMapReady)="onMapReady($event)"
           [leafletOptions]="options"
           [leafletLayers]="markers">
      </div>
    </ng-template>
  </p-splitter>
</div>

<p-toast key="car-event-msg"></p-toast>

import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment-timezone';
import {Store} from '@ngrx/store';
import {ProfileSelectors} from '../../state/Profile';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LangUtilService {

  public isLanguageUpdated: Subject<any> = new BehaviorSubject<any>(false);

  graphSpecificRanges_today_yesterday = {
    Today: [moment().startOf('days'), moment()],
    Yesterday: [
      moment().subtract(1, 'days').startOf('days'),
      moment().subtract(1, 'days').endOf('days'),
    ],
  };
  graphSpecificRanges = {
    Today: [moment().startOf('days'), moment()],
    Yesterday: [
      moment().subtract(1, 'days').startOf('days'),
      moment().subtract(1, 'days').endOf('days'),
    ],
    'Last 7 Days': [moment().subtract(6, 'days').startOf('days'), moment()]
  };
  graphSpecificRanges_next_Days = {
    Today: [moment().startOf('days'), moment().endOf('days')],
    Tomorrow: [
      moment().add(1, 'days').startOf('days'),
      moment().add(1, 'days').endOf('days'),
    ],
    'Next 7 Days': [
      moment().startOf('days'),
      moment().add(6, 'days').endOf('days'),
    ],
  };
  graphSpecificRanges_today_tomorrow = {
    Today: [moment().startOf('days'), moment().endOf('days')],
    Tomorrow: [
      moment().add(1, 'days').startOf('days'),
      moment().add(1, 'days').endOf('days'),
    ],

  };
  graphSpecificRanges_seven_days = {
    Today: [moment().startOf('days'), moment().endOf('days')],
    'Last 7 Days': [moment().subtract(6, 'days').startOf('days'), moment()],
  };

  constructor(
    private translate: TranslateService,
    private store: Store
  ) {
  }

  sendLanguageUpdatedStatus() {
    this.isLanguageUpdated.next(true);
  }

  subscription: Subscription;

  updateUserLanguage(language: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.translate.use(language).subscribe(() => {
      sessionStorage.setItem('userLanguage', language);
      this.updateGraphicsLabels();
      // this.subscription.unsubscribe();
    });
  }

  getTranslation(str: string) {
    const translation = this.translate.instant(str); // get converted string from current language
    if (translation === undefined) {
      return this.translate.translations.en.str;
    } else {
      return translation;
    }
  }

  getUserLanguage() {
    // this.translate.addLangs(['en', 'de', 'fr', 'ar', 'kannada']);
    // this.translate.setDefaultLang('en');
    let userLanguage: string;
    userLanguage = sessionStorage.getItem('userLanguage');
    if (!userLanguage) {
      this.store
        .select(ProfileSelectors.getUserLanguage)
        .subscribe((details: { language: string }) => {
          if (details && details.language) {
            userLanguage = details.language;
            sessionStorage.setItem('userLanguage', userLanguage);
            this.updateUserLanguage(userLanguage);
          } else {
            this.translate.use('en');
          }
        });
    } else {
      this.updateUserLanguage(userLanguage);
    }
  }

  private updateGraphicsLabels() {
    // @ts-ignore
    this.graphSpecificRanges = {};
    // @ts-ignore
    this.graphSpecificRanges_today_yesterday = {};
    // @ts-ignore
    this.graphSpecificRanges_next_Days = {};
    // @ts-ignore
    this.graphSpecificRanges_today_tomorrow = {};
    this.graphSpecificRanges[this.getTranslation('app.report.today')] = [
      moment().startOf('days'),
      moment(),
    ];
    this.graphSpecificRanges_today_yesterday[
      this.getTranslation('app.report.today')
      ] = [moment().startOf('days'), moment()];
    // eslint-disable-next-line max-len
    this.graphSpecificRanges[this.getTranslation('app.report.yesterday')] = [
      moment().subtract(1, 'days').startOf('days'),
      moment().subtract(1, 'days').endOf('days'),
    ];
    // eslint-disable-next-line max-len
    this.graphSpecificRanges_today_yesterday[
      this.getTranslation('app.report.yesterday')
      ] = [
      moment().subtract(1, 'days').startOf('days'),
      moment().subtract(1, 'days').endOf('days'),
    ];
    this.graphSpecificRanges[this.getTranslation('app.report.last-7-days')] = [
      moment().subtract(6, 'days').startOf('days'),
      moment(),
    ];

    this.graphSpecificRanges_next_Days[
      this.getTranslation('app.report.today')
      ] = [moment().startOf('days'), moment().endOf('days')];
    this.graphSpecificRanges_next_Days[
      this.getTranslation('app.report.tomorrow')
      ] = [
      moment().add(1, 'days').startOf('days'),
      moment().add(1, 'days').endOf('days'),
    ];
    this.graphSpecificRanges_next_Days[
      this.getTranslation('app.report.next-7-days')
      ] = [moment().startOf('days'), moment().add(6, 'days').endOf('days')];

    this.graphSpecificRanges_today_tomorrow[
      this.getTranslation('app.report.today')
      ] = [moment().startOf('days'), moment().endOf('days')];
    this.graphSpecificRanges_today_tomorrow[
      this.getTranslation('app.report.tomorrow')
      ] = [
      moment().add(1, 'days').startOf('days'),
      moment().add(1, 'days').endOf('days'),
    ];
  }

  updateMomentLanguage(moment) {
    if (this.translate.currentLang === 'kannada') {
      moment.locale('kannada', {
        months:
          'ಜನವರಿ_ ಫೆಬ್ರವರಿ_ಮಾರ್ಚ್_ಆಪ್ರಿಲ್_ಮೇ_ಜೂನ್_ಜೂಲಿ_ಆಗಸ್ಟ್_ ಸೆಪ್ಟೆಂಬರ್_ಆಕ್ಟೇಬರ್_ ನವೆಂಬರ್_ಡೆಂಬರ್'.split(
            '_'
          ),
        monthsShort:
          'ಜನವರಿ_ ಫೆಬ್ರವರಿ_ಮಾರ್ಚ್_ಆಪ್ರಿಲ್_ಮೇ_ಜೂನ್_ಜೂಲಿ_ಆಗಸ್ಟ್_ ಸೆಪ್ಟೆಂಬರ್_ಆಕ್ಟೇಬರ್_ ನವೆಂಬರ್_ಡೆಂಬರ್'.split(
            '_'
          ),
        monthsParseExact: true,
        weekdays:
          'ಭಾನುವಾರ ಸೋಮವಾರ ಮಂಗಳವಾರ ಬುಧವಾರ ಗುರುವಾರ ಶುಕ್ರವಾರ ಶನಿವಾರ'.split(' '),
        weekdaysShort: 'ಸನ್_ಮೊನ್_ಟೂ._ವೆಡ್._ತು._ಫ್ರೀ_ಸಾಟ್.'.split('_'),
        weekdaysMin: 'ಡಿ_ಲು_ಮಾ_ಮೆ_ಜೆ_ವೀ_ಸಾ'.split('_'),
        weekdaysParseExact: true,
        longDateFormat: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd D MMMM YYYY HH:mm',
        },
        calendar: {
          sameDay: '[ಇಂದು] LT',
          nextDay: '[ನಾಳೆ] LT',
          nextWeek: 'dddd [ನಲ್ಲಿ] LT',
          lastDay: '[ನಿನ್ನೆ] LT',
          lastWeek: 'dddd [ಕೊನೆಯದು] LT',
          sameElse: 'L',
        },
        relativeTime: {
          future: 'ಸೈನ್ ಇನ್ %s',
          past: 'ಇದೆ %s',
          s: 'ಕೆಲವು ಸೆಕೆಂಡುಗಳು',
          m: 'ಒಂದು ನಿಮಿಷ',
          mm: '%d ನಿಮಿಷಗಳು',
          h: 'ಎ ಗಂಟೆ',
          hh: '%d ಗಂಟೆಗಳು',
          d: 'ಎ ದಿನ',
          dd: '%d ದಿನಗಳು',
          M: 'ಎ ತಿಂಗಳು',
          MM: '%d ತಿಂಗಳು',
          y: 'ಎ ವರ್ಷ',
          yy: '%d ವರ್ಷಗಳು',
        },
        dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
        ordinal: function (number) {
          return number + (number === 1 ? 'er' : 'e');
        },
        meridiemParse: /ಪಿಡಿ|ಎಂಡಿ/,
        isPM: function (input) {
          return input.charAt(0) === 'M';
        },
        // In case the meridiem units are not separated around 12, then implement
        // this function (look at locale/id.js for an example).
        // meridiemHour : function (hour, meridiem) {
        //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
        // },
        meridiem: function (hours, minutes, isLower) {
          //TODO: need to remove below args
          if (minutes && isLower) {

          }
          return hours < 12 ? 'ಪಿಡಿ' : 'ಎಂಡಿ';

        },
        week: {
          dow: 1, // Monday is the first day of the week.
          doy: 4, // Used to determine first week of the year.
        },
      });
    }
    moment.locale(this.translate.currentLang);
  }
}

import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { LangUtilService } from '../../util/lang-util.service';
import { SetCssPropertiesService } from '../../../services/set-css-properties.service';
import { forEach } from 'lodash';
import { Store } from '@ngrx/store';
import { assetDefaultImageConfigsSelector } from '../../../state/Assets/assets.selector';
import { AssetImageConfigHolder } from '../../../state/Assets/interfaces/Assets.modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-asset-linked-count',
  templateUrl: './asset-linked-count.component.html',
  styleUrls: ['./asset-linked-count.component.scss']
})
export class AssetLinkedCountComponent implements OnChanges, OnInit, OnDestroy {
  @Input() assetLinkedDetails: any;
  @Input() assetConfigDetails: any;
  @Input() serviceAssetConfigDetails: any;
  badgeColors: object = {};
  icons: object = {};
  Object = Object;
  private assetDefaultImageSub: Subscription;
  private assetDefaultImageConfig: AssetImageConfigHolder;
  constructor(private lang: LangUtilService, private store: Store,
     private setCssProperties: SetCssPropertiesService) { }

  ngOnInit() {
    this.assetDefaultImageSub = this.store.select(assetDefaultImageConfigsSelector).subscribe((config: AssetImageConfigHolder) => {
        this.assetDefaultImageConfig = config;
        this.getIcon();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'assetLinkedDetails') {
        if (change.currentValue !== change.previousValue) {
          if (this.assetConfigDetails) {
            this.getBadgeColors(this.assetConfigDetails);
          } else if (this.serviceAssetConfigDetails) {
            this.getBadgeColors(this.serviceAssetConfigDetails);
          }
          this.getIcon();
        }
      }
    }
  }

  ngOnDestroy(){
    this.assetDefaultImageSub?.unsubscribe();
  }

  // getBadgeColors(configDetails) {
  //   const objKeys = Object.keys(this.assetLinkedDetails);
  //   for (const i of objKeys) {
  //     let name = [];
  //     for(const n of this.assetLinkedDetails[i]) {
  //       if(n?.name) name.push(this.lang.getTranslation(n['name']))
  //     }
  //     for (const j in configDetails) {
  //       if (i === configDetails[j]['type']) {
  //         this.badgeColors[i] = sessionStorage.getItem('theme') === 'light-theme' ? { 'color': configDetails[j]['theme']['color']['primary'] } : { 'color': configDetails[j]['theme']['color']['secondary'] };
  //         let label = this.lang.getTranslation(configDetails[j]['label']);
  //         if (this.assetLinkedDetails[i].length > 0) label = label + ' : ' + name.join(',');
  //         this.badgeColors[i]['label'] = label;
  //         break;
  //       } else {
  //         this.badgeColors[i] = { 'color': 'red' };
  //         let label = this.lang.getTranslation(configDetails[j]['label']);
  //         if (this.assetLinkedDetails[i].length > 0) label = label + ' : ' + name.join(',');
  //         this.badgeColors[i]['label'] = label;
  //       }
  //     }
  //   }
  // }


  getBadgeColors(configDetails) {
    const objKeys = Object.keys(this.assetLinkedDetails);
    for (const i of objKeys) {
      for (const j in configDetails) {
        if (i === configDetails[j]['type']) {
          this.badgeColors[i] = sessionStorage.getItem('theme') === 'light-theme' ? { 'color': configDetails[j]['theme']['color']['primary'] } : { 'color': configDetails[j]['theme']['color']['secondary'] };

          this.badgeColors[i]['label'] = this.lang.getTranslation(configDetails[j]['label']);
          this.badgeColors[i]['tooltip'] = this.buildTheTooltip(this.assetLinkedDetails[i], this.badgeColors[i]['label'], configDetails[j]);
          break;
        } else {
          this.badgeColors[i] = { 'color': 'red' };
          this.badgeColors[i]['label'] = this.lang.getTranslation(configDetails[j]['label']);
          this.badgeColors[i]['tooltip'] = this.buildTheTooltip(this.assetLinkedDetails[i], this.badgeColors[i]['label'], configDetails[j]);
        }
      }
    }
  }


  getIcon() {
    let objKeys = Object.keys(this.assetLinkedDetails);
    for (const i of objKeys) {
      if(this.assetDefaultImageConfig?.[i]?.url){
        this.assetLinkedDetails[i]['icon'] = this.assetDefaultImageConfig[i].url;
      } else {
        this.assetLinkedDetails[i]['icon'] = '../../assets/exclamation-red.png';
      }
      
      /* this.assetService.getAssetImgUrl(i).subscribe((data: any) => {
        if (data) {
          this.assetLinkedDetails[i]['icon'] = data.url;
        } else {
          this.assetLinkedDetails[i]['icon'] = '../../assets/exclamation-red.png';
        }
      }) */
    }

  }

  buildTheTooltip(linkedAssetsArray, label, configDetails) {

    let configuration = (configDetails.configuration);
    let titleField = 'name';
    forEach(configuration, function (value, key) {
      if (value?.isTitle && value?.isSearchable) {
        titleField = key
      } 
    });

    let arr: any = [];
    let str: string;
    str = '';
    forEach(linkedAssetsArray, function (value) {
      if(value[titleField]){
        arr.push(value[titleField]);
      }
    });
    str = this.setCssProperties.arrayToFormattedString(arr);
    str = label + ' : ' + str;
    return str;
  }

}

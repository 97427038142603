import { Injectable } from '@angular/core';

@Injectable({  providedIn: 'root'}
)
export class CommonService {

  constructor() { }

  getIconsForEvents(event) {
    switch (event['name']) {
      case 'Ignition':
        switch (event['status']) {
          case 'on':
            return 'assets/leaflet-icons/carOn.png';
          case 'off':
            return 'assets/leaflet-icons/carOff.png';
        }
        return '';
      case 'Harsh Event':
        switch (event['status']) {
          case 'Harsh Braking':
            return 'assets/leaflet-icons/harshEvents3.png';
          case 'Harsh Cornering':
            return 'assets/leaflet-icons/harshEvents4.png';
          case 'Harsh Acceleration':
            return 'assets/leaflet-icons/harshEvents2.png';
        }
        return '';
      case 'GeoFence':
        switch (event['status']) {
          case 'in':
            return 'assets/leaflet-icons/geofenceIn.png';
          case 'out':
            return 'assets/leaflet-icons/geofenceOut.png';
        }
        return '';
      case 'Idling Event':
        switch (event['status']) {
          case 'Idling Started':
            return 'assets/leaflet-icons/idlingStarted.png';
          case 'Idling Ended':
            return 'assets/leaflet-icons/idlingEnded.png';
        }
        return '';
      case 'Obd Device':
        switch (event['status']) {
          case 'plugged':
            return 'assets/leaflet-icons/obdDevicePlugged.png';
          case 'unPlugged':
            return 'assets/leaflet-icons/obdDeviceUnPlugged.png';
        }
        return '';
      case 'Tracker':
          switch (event['status']) {
            case 'Long Stop':
              return 'assets/leaflet-icons/harshEvents2.png';
            case 'online':
              return 'assets/leaflet-icons/carOn.png';
            case 'Offline':
              return 'assets/leaflet-icons/carOff.png';
        }
        return '';
      case 'Cold Chain Box Lid':
        switch (event['status']) {
          case 'open':
            return 'assets/leaflet-icons/harshEvents3.png';
          case 'close':
            return 'assets/leaflet-icons/harshEvents4.png';
          case 'longOpen':
            return 'assets/leaflet-icons/harshEvents2.png';
          default:
            return 'assets/exclamation-red.png';
        }
      case 'Delivery':
          switch (event['status']) {
            case 'On Duty':
              return 'assets/leaflet-icons/obdDevicePlugged.png';
            case 'Off Duty':
              return 'assets/leaflet-icons/obdDeviceUnPlugged.png';
            case 'In Queue':
              return 'assets/leaflet-icons/idlingStarted.png';
            case 'Not In Queue':
              return 'assets/leaflet-icons/idlingEnded.png';
            default:
              return 'assets/exclamation-red.png';
        }
      default:
        return 'assets/exclamation-red.png';
    }
  }

   // generic interval function
  // start value , end value , gap
  buildIntervalList(start: number, gap: number, end: number) {

    let intervalArray = []

    for (let i = start; i <= end; i += gap) {
      let intervalObj = { value: i, name: i + ' ' + 'Minutes' };
      intervalArray.push(intervalObj);
    }
    return intervalArray;
  }

}
<div class="grid mx-0 my-1 py-1 justify-content-between align-items-center">

  <!--  Drop-down Menu  -->
  <div class="dropdown col-6 sm:col-3 lg:col-5 xl:col-3">
    <p-dropdown [options]="linkableAssetsLabelWithType" [(ngModel)]="assetTypeForSearch" optionLabel="label"
                (ngModelChange)="setSelectedAssetType($event)" placeholder="{{ 'Select an Asset Type' | translate }}"
                appendTo="body" [style]="{'width': '100%'}">
      <ng-template let-option pTemplate="selectedItem">
        <div class="grid dropdownBtn-inner m-1 justify-content-around align-items-center" style="height: 100%">
          <div class="img-container" [ngStyle]="{'background-color' : option?.['color'] ? option['color'] : ''}">
            <img [src]="option?.icon">
          </div>
          <span class="dropdown-btn-label">{{ option?.label | translate }}</span>
        </div>
      </ng-template>

      <ng-template let-option pTemplate="item">
        <div class="grid dropdownBtn-inner m-1 align-items-center" style="height: 100%">
          <div class="img-container" [ngStyle]="{'background-color' : option?.['color'] ? option['color'] : ''}">
            <img [src]="option?.icon">
          </div>
          <span class="dropdown-btn-label">{{ option?.label | translate }}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <!--  Search Box-->
  <div class="input-outer-grid col-6 sm:col-9 lg:col-7 xl:col-9">
    <div class="input-icon-wrapper PR">
      <input type="text" placeholder="{{'sys.search-asset-in' | translate}} {{defaultAssetForDropDown['assetLabel'] | translate }}"
             class="form-control" [autocomplete]="false" name="searchForAsset"
             [(ngModel)]="searchForAsset" (ngModelChange)="searchAsset($event)"/>
      <span><i class="fa fa-search"></i></span>
    </div>
  </div>
</div>

@if (assetNotFound && defaultAssetForDropDown['assetLabel'] !== '') {
  <div class="grid mx-0" style="height: 50%"><h3 class="asset-not-found m-auto">
    <span>&ldquo;</span>{{ 'sys.asset-not-found-selected-type' | translate }} {{ assetLabel | translate }}
    <span>&rdquo;</span></h3></div>
}

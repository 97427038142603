import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IotzenPillType } from './iotzen-pill-type';
import {LangUtilService} from '../../util/lang-util.service';

@Component({
  selector: 'app-iotzen-pills',
  templateUrl: './iotzen-pills.component.html',
  styleUrls: ['./iotzen-pills.component.scss']
})
export class IotzenPillsComponent implements OnChanges, OnInit {

  public activePillColour: string;
  public defaultActivePillColour: string;
  public activePill: any;
  @Input() defaultActivePillIndex: number;
  @Input() pillActiveColour: string;
  @Input() arrayOfPills: Array<IotzenPillType>;
  @Input() isPillTabToggleRequired: boolean;
  @Output() activePillValue: EventEmitter<string> = new EventEmitter();
  @Input() isUserProfileOpened: boolean;
  @Input() isDisableUnactivePills: boolean;
  @Input() isdropDownPillRequired: boolean;

  constructor(private lang: LangUtilService) {
    this.defaultActivePillColour = sessionStorage.getItem('theme') === 'dark-theme' ? 'white' : '#6D7FCC';
    this.isPillTabToggleRequired = false;
    this.isDisableUnactivePills = false;
  }

  ngOnInit() {
    if ( this.defaultActivePillIndex && this.arrayOfPills
      && this.arrayOfPills.length > 0
      && this.defaultActivePillIndex < this.arrayOfPills.length) {
    } else {
      if (!this.isUserProfileOpened) {
        this.defaultActivePillIndex = 0;
      } else {

        let theme;
        theme = sessionStorage.getItem('theme');
        theme === 'dark-theme' ? this.defaultActivePillIndex = 1 : this.defaultActivePillIndex = 0;
      }
      }
    this.activePill = this.arrayOfPills[this.defaultActivePillIndex];
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'pillActiveColour') {
        if (change.currentValue !== change.previousValue) {
          this.setPillActiveColour();
        }
      }
      if (propName === 'defaultActivePillIndex') {
        if (change.currentValue !== change.previousValue) {
          this.activePill = this.arrayOfPills[this.defaultActivePillIndex];
        }
      }
    }
  }

  setPillActiveColour() {
    if (this.pillActiveColour) {
      this.activePillColour = sessionStorage.getItem('theme') === 'dark-theme' ? 'white' : this.pillActiveColour;
    } else {
      this.activePillColour = this.defaultActivePillColour;
    }
  }

  onPillSelected(activePill) {
    this.activePill = activePill;
    this.activePillValue.emit(this.activePill.id);
  }

  isPillActive(id) {
    if(id) {
      if (id === this.activePill.id) { return true;
      } else { return false; }
    }
    
  }

  getBackGroundColour(id) {
    if(id) {
      if (id === this.activePill.id) { return this.activePillColour;
      } else { return 'inherit'; }
    }
  }

  restrictToggleLabelLength(label: string) {
    if (label && label.length > 3) {
      label = label.substring(0, 3) + '..';
    }
    return label;
  }

  getLangLabel(label: string) {
    if (label) {
      if ( ['gauge', 'graph'].indexOf(label.toLowerCase()) > -1) {
        let mappingLabel = 'app.core.custom-modal.' + label.toLowerCase();
        return this.lang.getTranslation(mappingLabel);
      } else {
        return this.lang.getTranslation(label);
      }
    }
  }
}

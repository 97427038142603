/**
 * Created by chandru on 5/12/18.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class HealthCheckService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }
  getAssetStatus(startTime, endTime, id?: any) {
    if (id) {
      return this.http.get(this.configService.appConfig.appBaseUrl + 'asset/stats/' + startTime + '/' + endTime + '/' + id );
    } else {
      return this.http.get(this.configService.appConfig.appBaseUrl + 'asset/stats/' + startTime + '/' + endTime);
    }
  }
}

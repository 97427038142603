<div class="grid mx-1 my-1 py-1 justify-content-between align-items-center bg-white" *ngIf="displayElementOnUI">
<!--  Drop-down Menu  -->
  <p-dropdown class="dropdown full-width col-6 sm:col-3 pl-3 lg:col-5 xl:col-3" [options]="linkableAssetsLabelWithType" 
  (onChange)="setSelectedAssetType($event)"
  optionLabel="label">
    <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="defaultAssetForDropDown">
          <div class="img-container" [ngStyle]="{'background-color' : defaultAssetForDropDown['color'] ? defaultAssetForDropDown['color'] : ''}"> 
            <img src="{{defaultAssetForDropDown.icon}}" class="asset-icon-color"/>
          </div>
            <div>{{defaultAssetForDropDown['assetLabel'] | translate}}</div>
        </div>
    </ng-template>
    <ng-template let-assetType pTemplate="item">
        <div class="flex align-items-center gap-2" >
          <div [ngStyle]="{'background-color' : assetType['color'] ? assetType['color'] : ''}" class="img-container">
            <img src="{{assetType.icon}}" class="asset-icon-color"/>
          </div>
            <div>{{ assetType.label | translate}}</div>
        </div>
    </ng-template>
</p-dropdown>

<!--  Search Box-->
<div class="input-outer-grid py-3 col-12 md:col-6 lg:col-6 xl:col-6">
  <span class="full-width" [ngClass]="{'p-input-icon-left':!searchForAsset,'p-input-icon-right':searchForAsset}">
  <i class="pi pi-search" *ngIf='!searchForAsset'></i>
  <i class="pi pi-times cursorPointer"(click)="clearSearchedText()" *ngIf='searchForAsset' ></i>
  <input class="full-width asset-seacrh-class" type="text" pInputText placeholder="{{'sys.search-asset-in' | translate}} {{defaultAssetForDropDown['assetLabel'] | translate}}"
  [(ngModel)]="searchForAsset" (ngModelChange)="searchAsset($event)" >
  
  </span>
</div>

<div class = "grid mx-0 no-asset-class" *ngIf="assetNotFound && defaultAssetForDropDown['assetLabel'] !== ''" style="height: 50%"><h3 class="asset-not-found m-auto"><span>&ldquo;</span>{{'sys.asset-not-found-selected-type' | translate}} {{assetLabel | translate}}<span>&rdquo;</span></h3></div>

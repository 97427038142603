export function handleMapResize(map, id) {
  const mapElement = document.getElementById(id);
  if (mapElement) {
    const resizeObserver = new ResizeObserver(() => {
      try {
        map?.invalidateSize();
      } catch (e) {
        resizeObserver.unobserve(mapElement);
      }
    });
    resizeObserver.observe(mapElement);
  }
}

import {Injectable, ViewChild} from '@angular/core';
declare var $: any;
import moment from 'moment-timezone';
import {DaterangepickerDirective} from '../app/date-range-picker';
import {LangUtilService} from '../app/util/lang-util.service';
enum SideEnum {
  left = 'left',
  right = 'right'
}
@Injectable({
  providedIn: 'root'
})
export class DateRangePickerService {
  analyticsData = {};
  singleDatePicker_Variabes = {
    singleDatePicker : true,
    timePicker: true,
    showDropdowns: true,
    autoApply : true,
    locale : {'format' : 'DD/MM/YYYY', 'customRangeLabel': this.lang.getTranslation('app.report.custom-range')},
    getMinDate() {
      return moment().subtract(70, 'years');
    },
    getMaxDate() {
      return moment().add(10, 'years');
    },
    getTodayAsMaxDate() {
      return moment();
    },
    getTodayAsMinDate() {
      return moment();
    },
    getTomorrowAsMaxDate() {
      return moment().add(1, 'days').endOf('days');
    }
  };

  @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;
  constructor(private lang: LangUtilService) { }
  getRangeClickedEvent(datePicker, event, isUpdateTimePickerRequired?) {
    if (event.label === this.lang.getTranslation('app.report.today')) {
      event.dates = [moment().startOf('days'), moment(new Date)];
      // const updatedDate = {startDate: event.dates[0], endDate: event.dates[1]}
    } else if (event.label === this.lang.getTranslation('app.report.yesterday')) {
      event.dates = [moment().subtract(1, 'days').startOf('days'), moment().subtract(1, 'days').endOf('days')];
    } else if (event.label === this.lang.getTranslation('app.report.last-7-days')) {
      event.dates = [moment().subtract(6, 'days').startOf('days'), moment()];
    } else if (event.label === this.lang.getTranslation('app.report.last-30-days')) {
      event.dates = [moment().subtract(29, 'days').startOf('days'), moment()];
    } else if (event.label === this.lang.getTranslation('app.report.this-month')) {
      event.dates = [moment().startOf('month'), moment()];
    } else if (event.label === this.lang.getTranslation('app.report.last-month')) {
      event.dates = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    }
    this.analyticsData['startDate'] = event.dates[0];
    this.analyticsData['endDate'] = moment(event.dates[1]).set('second', 0);
    if ((isUpdateTimePickerRequired === undefined) || (isUpdateTimePickerRequired === true) ) {
      this.updateTimePickers(datePicker, event.label);
    }
    return this.analyticsData;
  }

  getNextRangeClickedEvent(_, event) {
    if (event.label === this.lang.getTranslation('app.report.today')) {
      event.dates = [moment().startOf('days'), moment().endOf('days')];
      // const updatedDate = {startDate: event.dates[0], endDate: event.dates[1]}
    } else if (event.label === this.lang.getTranslation('app.report.tomorrow')) {
      event.dates = [moment().add(1, 'days').startOf('days'), moment().add(1, 'days').endOf('days')];
    } else if (event.label === this.lang.getTranslation('app.report.next-7-days')) {
      event.dates = [moment().startOf('days'), moment().add(6, 'days').endOf('days')];
    } else if (event.label === this.lang.getTranslation('app.report.next-30-days')) {
      event.dates = [moment().startOf('days'), moment().add(29, 'days').endOf('days')];
    } else if (event.label === this.lang.getTranslation('app.report.next-month')) {
      event.dates = [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')];
    }
    this.analyticsData['startDate'] = event.dates[0];
    this.analyticsData['endDate'] = moment(event.dates[1]).set('second', 0);
    // this.updateTimePickers(datePicker, event.label);
    return this.analyticsData;
  }

  updateTimePickers(datePicker, label) {
    // Getting the time from AnalyticsData
    const timeData = {
      startTime: this.analyticsData['startDate'].format('hh:mm:ss a'),
      endTime: this.analyticsData['endDate'].format('hh:mm:ss a')
    }
    datePicker.maxDate = moment();
    datePicker.startDate = this.analyticsData['startDate'];
    datePicker.endDate = this.analyticsData['endDate'];
    datePicker.chosenRange = label;
    datePicker.updateElement();
    // Setting the StartTime in Left time picker
    if(datePicker?.timepickerVariables?.[SideEnum.left]){
      datePicker.timepickerVariables[SideEnum.left].selectedHour = parseInt(this.analyticsData['startDate'].format('HH'), 10);
      datePicker.timepickerVariables[SideEnum.left].selectedMinute = parseInt(this.analyticsData['startDate'].format('mm'), 10);
      datePicker.timepickerVariables[SideEnum.left].selectedSecond = parseInt(this.analyticsData['startDate'].format('ss'), 10);
      datePicker.timepickerVariables[SideEnum.left].ampmModel = this.analyticsData['startDate'].format('a');
    }
    datePicker.timeChanged(timeData.endTime, SideEnum.left);

    // Setting the EndTime in Right time picker
    if(datePicker?.timepickerVariables?.[SideEnum.right]){
      datePicker.timepickerVariables[SideEnum.right].selectedHour = parseInt(this.analyticsData['endDate'].format('HH'), 10);
      datePicker.timepickerVariables[SideEnum.right].selectedMinute = parseInt(this.analyticsData['endDate'].format('mm'), 10);
      datePicker.timepickerVariables[SideEnum.right].selectedSecond = parseInt(this.analyticsData['endDate'].format('ss'), 10);
      datePicker.timepickerVariables[SideEnum.right].ampmModel = this.analyticsData['endDate'].format('a');
    }

    // Updating the calender
    datePicker.timeChanged(timeData.endTime, SideEnum.right);
    this.addActiveClassOnClickedRange(label);
  }

  addActiveClassOnClickedRange(label) {
    setTimeout(() => {
      $('.ng-date-picker-container .md-drppicker .ranges ul li').each(function(  ) {
        if ($( this ).text() === label) {
          $(this).find(':button').addClass('active');
        } else {
          $(this).find(':button').removeClass('active');
        }
      });
    }, 10);
  }

  activateCustomRangeLabel() {
    const localThis = this;
    setTimeout(() => {
      $('.ng-date-picker-container .md-drppicker .ranges ul li:last-child button').attr('id', 'customRangeLabel');
      $('.table-condensed tbody td.available, .table-condensed tbody tr td.ng-star-inserted, #customRangeLabel').click(function() {
        localThis.addActiveClassOnClickedRange('Custom range');
      });
    }, 10);
  }

  getTimeDuration(startTime, endTime) {
    let duration: any;
    duration = moment.duration(endTime.diff(startTime));
    return duration;
  }

  setTimeDurationValue(duration, isSeondsRequired?: boolean) {
    let timeDuration = '';
    if (duration && duration._data) {
      if (duration._data.months !== 0) {
        if (duration._data.months === 1) {
          timeDuration = timeDuration + duration._data.months + ' month ';
        } else {
          timeDuration = timeDuration + duration._data.months + ' months ';
        }
      }
      if (duration._data.days !== 0) {
        if (duration._data.days === 1) {
          timeDuration = timeDuration + duration._data.days + ' day ';
        } else {
          timeDuration = timeDuration + duration._data.days + ' days ';
        }
      }
      if (duration._data.hours !== 0) {
        if (duration._data.hours === 1) {
          timeDuration = timeDuration + duration._data.hours + ' hr ';
        } else {
          timeDuration = timeDuration + duration._data.hours + ' hrs ';
        }
      }
      if (duration._data.minutes !== 0) {
        if (duration._data.minutes === 1) {
          timeDuration = timeDuration + duration._data.minutes + ' min ';
        } else {
          timeDuration = timeDuration + duration._data.minutes + ' mins ';
        }
      }
      if (isSeondsRequired) {
        if (duration._data.seconds !== 0) {
          if (duration._data.seconds === 1) {
            timeDuration = timeDuration + duration._data.seconds + ' sec ';
          } else {
            timeDuration = timeDuration + duration._data.seconds + ' secs ';
          }
        }
      }
      if (duration._data.months === 0 && duration._data.days === 0 && duration._data.hours === 0 && duration._data.minutes === 0 && duration._data.seconds === 0) {
        timeDuration = '0 mins';
      }
    }
    return timeDuration;
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class FormService {

  constructor() { }
  formatAssetConfig(assetConfig) {
    const arr = []
    for (const key in assetConfig) {
      if (assetConfig.hasOwnProperty(key)) {
        arr.push(assetConfig[key])
      }
    }
    return arr;
  }

  formatEditAssetConfig(assetConfig, assetDetails) {
    const isArray: boolean = Array.isArray(assetConfig);
    if (isArray) {
      assetConfig.forEach(value=>{
        value.fieldValue = assetDetails?.[value.field];
      })
      return assetConfig;
    } else {
      const arr = [];
      for(let key in assetConfig) {
        if (assetConfig.hasOwnProperty(key)) {
          assetConfig={...assetConfig,[key]:{...assetConfig[key],fieldValue:assetDetails?.[key]}}
          arr.push(assetConfig[key]);
        }
      }
      return arr;
    }
  }

  formatEditAssetDetails(assetDetails){
    const obj={}
    for (const key in assetDetails) {
      if (assetDetails.hasOwnProperty(key)) {
        obj[assetDetails[key].field]=assetDetails[key].fieldValue
      }
    }
    return obj
  }

  formatAssetSaveDetails(assetDetails){
   const  assetModifiedObj={};
    for (const key in assetDetails) {
      if (assetDetails.hasOwnProperty(key) && typeof  assetDetails[key]==='string') {
        assetModifiedObj[key]=assetDetails[key]
      }
    }
    return assetModifiedObj;
  }
}

import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import {EchartDoughnutData} from './echart-doughnut-data-type';

@Component({
  selector: 'app-echart-doughnut-chart',
  templateUrl: './echart-doughnut-chart.component.html',
  styleUrls: ['./echart-doughnut-chart.component.scss']
})
export class EchartDoughnutChartComponent implements OnInit, OnChanges {
  @Input() chartData: EchartDoughnutData;
  @Input() chartName: string;
  @Input() isSmallEChart: boolean
  @Output() sendDataToNavigateToSelectedComponent: EventEmitter<any> = new EventEmitter();
  @Output() sendDataToOpenInModal: EventEmitter<any> = new EventEmitter();

  options = null;
  isLoading = true;
  showGraph = false;
  showDataUnavailabe = false;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'chartData') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          let data = [];
          data = this.chartData?.data;
          data = data?.map((r) => {
            if(r?.name)
            r={...r,name : r.name.charAt(0).toUpperCase() + r.name.slice(1)}
          })
          this.updateGraph(this.chartData);
        }
      }
    }
  }

  capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }
  onChartClick(event: any) {
    this.sendDataToNavigateToSelectedComponent.emit(event);
    this.sendDataToOpenInModal.emit(event);
  }
  updateGraph(chartData: EchartDoughnutData) {
     if(chartData.data[0].name === ""){
      this.showDataUnavailabe = true;
     }
    this.options = {
      tooltip: {
        trigger: 'item',
        appendToBody: true,
        formatter:  (params)=>{
          let additionalTooltip=''
          const rPlans=params?.data?.addDataForTooltip?.routePlan?.[params?.name]
          
           additionalTooltip=rPlans?.length ? '<br>' : ''
            
          rPlans?.forEach((plan,i:number)=>{
            if(i%3 || i===0){
              additionalTooltip += plan + (', ')
            }else{
              additionalTooltip += plan + ('<br>')
            }
          })
        
              return `<B>${this.capitalizeFirstLetter(params.name)} : ${params.value} (${params.percent}%)</B>  ${additionalTooltip}`;
            }
      },
      legend: {
        type: 'scroll',
        orient: chartData?.data?.[0]?.legendOrientation || 'horizontal',
        x: 'left',
        data: chartData.labels,
        textStyle: {
          color: sessionStorage.getItem('theme') === 'dark-theme' ? 'white' : 'black'
        },
        formatter: function (name) {
          const result = name;
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          return finalResult;
        },
      },
      title: {
        text: this.chartName,
        textStyle: {
          color : (sessionStorage.getItem('theme')) === 'dark-theme' ? '#d0d0d0' : '#212529',
          fontSize: 17,
          fontWeight: '500',
          fontFamily: '"robotomedium", sans-serif',
        }
      },
      series: [
        {
          name: this.chartName,
          type: 'pie',
          radius: '55%',
          center: ['50%', '60%'],
          label: {
            show: true,
            formatter: '{b} ({c}) : {d}%'
          },
          selectedMode: 'single',
          /* avoidLabelOverlap: true,name
          label: {
            fontWeight: 'bold',
            normal: {
              show: true,
              position: 'left'
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '16',
                fontWeight: 'bold'
              }
            }
          },
          labelLine: {
            show: true,
            length: 30,
            smooth: true,
          }, */
          data: chartData.data
        }
      ]
    };
    this.showGraph = true;
    this.isLoading = false;
  }

}

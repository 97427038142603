/**
 * Created by suhas on 11/6/20.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';

@Injectable()
export class EventManagementService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  getAllEventTypesByModule( module: string, isNotification?: boolean, isEvent?: boolean) {
    let url = this.configService.appConfig.appBaseUrl + 'eventMetaData/allTypes?module=' + module;
    if(isNotification){
      url = url + '&isNotification=' + isNotification
    }
    if (isEvent) {
      url = url + '&isEvent=' + isEvent;
    }

    return this.http.get(url);
  }

  getFormByModuleAndType( module: string, type: string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + `eventMetaData/form/module/${module}/type/${type}`);
  }

  saveEvent( eventdata: object ) {
    const url = this.configService.appConfig.appBaseUrl + 'events/save';
    return this.http.post(url, eventdata);
  }
  updateEvent( id, eventdata: object ) {
    const url = this.configService.appConfig.appBaseUrl + 'events/id/' + id;
    return this.http.put(url, eventdata);
  }
  genericPostApiCall( url: string ,  payload: object ) {
    return this.http.post(this.configService.appConfig.appBaseUrl + url, payload);
  }
  genericGetApiCall( url: string ) {
    const apiUrl = this.configService.appConfig.appBaseUrl + url;
    return this.http.get( apiUrl );
  }

  getEventById(id: string) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'events/id/' + id);
  }

  deleteEvent(id) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'events/id/' + id);
  }

  getNotificstionsEventsByModule(module: string, isCount: boolean, skip?: number, limit?: number, isNotification?: boolean, isEvent?: boolean, eventType?: string, sensortype?: string) {
    let url = this.configService.appConfig.appBaseUrl + 'events/all?module=' + module;
    if(isCount){
      url = url + '&isCount=' + isCount
    }
    if(skip || (skip === 0)){
      url = url + '&skip=' + skip
    }
    if (limit) {
      url = url + '&limit=' + limit;
    }
    if(isNotification){
      url = url + '&isNotification=' + isNotification
    }
    if (isEvent) {
      url = url + '&isEvent=' + isEvent;
    }
    if (eventType) {
      url = url + '&eventType=' + eventType;
    }
    if(sensortype){
        url = url + '&sensorType='+ sensortype
    }
    return this.http.get(url);
  }

  linkEvents(ids, eventdata: LinkEventsPayload){
    const url = this.configService.appConfig.appBaseUrl + 'events/linkEvents/eventIds/' + ids;
    return this.http.put(url, eventdata);
  }

  searchValueInAssetConifg(queryUrl: string) {
    const url = this.configService.appConfig.appBaseUrl + queryUrl
    return this.http.get(url);
  }

}


export interface LinkEventsPayload {
  linkedAssets: string[];
  linkedType: string;
}
<!--circularLoader-->
<div class="circularMain">
  <div class="circularG circularG_1"></div>
  <div class="circularG circularG_2"></div>
  <div class="circularG circularG_3"></div>
  <div class="circularG circularG_4"></div>
  <div class="circularG circularG_5"></div>
  <div class="circularG circularG_6"></div>
  <div class="circularG circularG_7"></div>
  <div class="circularG circularG_8"></div>
</div>

<div class="custom-modal-dialog"></div>
<p-sidebar
  class="info-prime-sidebar"
  position="right"
  (onShow)="isSidebarClosed = false"
  (onHide)="closePopup()"
  [fullScreen]="(sidebarIsFullScreen$ | async)"
  [(visible)]="isCurrentIdActive"
  [dismissible]="false"
>
  <ng-template pTemplate="header">
    <div class="custom-modal-header">
      <h5 class="modal-title TTC">@if (!isEventSelectedToEdit) {
        <span translate>sys.add</span>
      } {{ formLabel | translate | firstUpperCase }}</h5>
    </div>
  </ng-template>
  <div
    class="custom-modal create-modal"
    [ngStyle]="{'display':'block'}"
    [ngClass]="{'custom-modal-view':true}"
    [id]="sidebarId"
  >
    <div class="custom-modal-content">
      <div class="custom-modal-body" >

        <app-event-form
          [formConfigData]="formDataForRendering"
          (formSubmitHandler)="formSubmitHandler($event)"
          (closeModalEvent) ="closePopup()"
          [resetFormData]="resetForm"
          [isEditForm]="isEventSelectedToEdit"
          [showUpdateBtn]="showUpdateBtn"
          [isCloneBtnReq]="isCloneBtnReq"
          [eventToEdit]="eventToEdit"
          [selectedAccountId]="selectedAccountId"
          (assetRegistrationErrorMsg)="assetRegistrationErrorMsg($event)"
          [assetTypeToSend]="assetTypeToSend"
        ></app-event-form>

      </div>
    </div>
  </div>
</p-sidebar>
<!-- </ng-container> -->

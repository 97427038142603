<div
  class="search-box"
[ngClass]="{
    'search-box-focused': openSearchBar,
    'search-box-aligned': !showGeoFenceEdit
}"
  (mouseenter)="openSearchBar= true"
  (mouseleave)="openSearchBar = searchedPlaceTitle?.trim()?.length">

  <input
    class="search-input"
    [ngClass]="{ 'search-input-focused': openSearchBar}"
    [(ngModel)]="searchedPlaceTitle"
    (input)="suggstPlaces($event)"
    type="text"
    placeholder="Search for location..."
    autocomplete="off">

  <p-button
    icon="pi pi-search"
    class="search-button"
    styleClass="p-button-rounded"></p-button>

  @if (searchedPlaceTitle?.trim()?.length) {
    <i
      class="pi pi-times"
      [ngClass]="{ 'hide-clear-icon': !openSearchBar,'show-clear-icon': openSearchBar}"
      (click)="searchedPlaceTitle='';searchPlaces('');openSearchBar = searchedPlaceTitle?.trim()?.length"></i>
  }
</div>

<div class="search-results-container" [ngClass]="{
    'search-results-container-aligned': !showGeoFenceEdit
}">
  <div id="results" #resultContainer></div>
</div>

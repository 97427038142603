import {Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {Group} from '../../../interfaces/group';
import {AssetConfigService} from '../../../services/asset-config.service';
import {Subject, Actions} from '../../../auth/rules';
import {DateFormatterService} from '../../../services/dateformat';
import {forEach} from 'lodash';
declare let $: any;
import {LangUtilService} from '../../util/lang-util.service';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit, OnChanges {
  SUBJECT = Subject;
  ACTIONS = Actions;
  @Input() groupData: Group;
  @Input() fieldsToBeDisplayed: any;
  @Input() cardLabel: string;
  @Input() isCardClickable: boolean;
  @Input() canAddToAsset: boolean;
  @Input() showDeleteButton: boolean;
  @Output() editCardDataEvent: EventEmitter<any> = new EventEmitter();
  @Output() linkGroupDetails: EventEmitter<any> = new EventEmitter();
  @Output() deleteGroupClickedEvent: EventEmitter<Group> = new EventEmitter();
  public embossedFlag: boolean;
  constructor(public assetConfigService: AssetConfigService,
    private lang: LangUtilService,
     public dateFormatter: DateFormatterService) {
    this.embossedFlag = false;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'groupData') {
        if (change.currentValue !== change.previousValue) {
          this.embossedFlag = false;
          this.cardLabel = this.groupData[this.cardLabel];
          if (this.groupData.assetTypes && this.groupData.assetTypes.length > 0) {
            this.groupData['assetTypesLabels'] = this.formateAssetTypesArray(this.groupData.assetTypes);
          }
        }
      }
    }
  }

  editCardData(groupData) {
    if (this.isCardClickable) {
      this.editCardDataEvent.emit(groupData);
    }
  }
  checkType(field) {
    return typeof(field);
  }
  selectedGroupsForLinking() {
    if (this.canAddToAsset) {
      this.embossedFlag = this.groupData['isGroupSelected'] ? this.groupData['isGroupSelected'] : false;
      // let obj = {};
      // obj['isGroupSelected'] = this.embossedFlag;
      // obj['groupData'] = this.groupData;
      // this.linkGroupDetails.emit(obj);
      this.groupData['isGroupSelected'] = !this.embossedFlag;
      this.linkGroupDetails.emit(this.groupData);
    }
  }
  deleteGroupClicked(group) {
        this.deleteGroupClickedEvent.emit(group);
  }
  formateAssetTypesArray(assetTypes: Array<string>) {
       let that = this;
       let labelArray: Array<string>;
       labelArray = [];
      forEach(assetTypes, function(value) {
        let label: string;
        label = that.assetConfigService.returnAssetTypeLabel(value);
        labelArray.push(label);
      });
       return labelArray;
  }
  getLangLabels(labelCodes: any) {
   labelCodes=labelCodes.map((label:string)=>{
      if(label){
        return this.lang.getTranslation(label)
      }
    })
    return labelCodes.join(',');
  }
}

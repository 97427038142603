<p-dialog
  [header]="mapHeader"
  (onShow)="initializingMap()"
  (onHide)="hideMapView.emit()"
  [(visible)]="mapVisible"
>
  <app-auto-complete-address
    (emittingLatLng)="emittingLatLng($event)"
    [showGeoFenceEdit]="showGeoFenceEdit"
  ></app-auto-complete-address>

  @if (showGeoFenceEdit) {
    <div class="edit-radius-container">
      <p-button (click)="panToGeoFence(geoFencesForAsset?.geoFenceCoordinates?.[0])" pTooltip="Pan to Geofence"
                icon="pi pi-map" styleClass="p-button-rounded " tooltipPosition="bottom"></p-button>
      <p-button (click)="geoFenceFormDialog=true" pTooltip="Edit Geo-Fence" icon="pi pi-pencil"
                styleClass="p-button-rounded " tooltipPosition="bottom"></p-button>
    </div>
  }


  <div class="map-container">
    <div class="map-frame">
      <div #map id="map-standalone" class="custom-class"></div>
    </div>
  </div>
</p-dialog>

@if (showGeoFenceEdit) {
  <p-dialog class="edit-radius-dialog" header="Geo-Fence Details" [(visible)]="geoFenceFormDialog"
            [style]="{ width: 'fit-content' }"
            (onShow)="closePopups()">
    <div>
      <div class='geo-fence-edit-input'>
        <div class='geo-fence-edit-title'>
          <p>Radius:</p>
        </div>
        <div class='geo-fence-radius-input'>
          <span class="full-width">
            <p-inputNumber id="number-input" [(ngModel)]="geoFenceRadius"></p-inputNumber>
          </span>
        </div>
        <div class='geo-fence-dialog-buttons'>
          <p-button (click)="geoFenceFormDialog=false" icon="pi pi-times"
                    styleClass="p-button-rounded p-button-danger p-button-outlined"></p-button>
          <p-button (click)="updateGeoFenceRadius()" icon="pi pi-check" styleClass="p-button-rounded"></p-button>
        </div>
      </div>
    </div>
  </p-dialog>
}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {MenuItem} from 'primeng/api';
import {SetCssPropertiesService} from '../../../services/set-css-properties.service';

@Component({
  selector: 'app-chart-filter-menu',
  templateUrl: './chart-filter-menu.component.html',
  styleUrls: ['./chart-filter-menu.component.scss']
})
export class ChartFilterMenuComponent implements OnInit {
  defaultActivePillColour: string;
  items: MenuItem[];
  groupByFilterTypeArray: any = [];
  selectedObj = {} ;
  @Input() isGraphStackable: boolean;
  @Input() series: string;
  @Input() filterMenuOptions: Array<any>;
  @Input() pillActiveColour: string;
  @Output() selectedFilterType: EventEmitter<any> = new EventEmitter();

  constructor(public setCssProperties: SetCssPropertiesService,) {
    this.defaultActivePillColour = sessionStorage.getItem('theme') === 'dark-theme' ? 'white' : '#6D7FCC';
   }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'filterMenuOptions') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.buildFilterMenuOptions()
        }
      }
    }
  }

  // buildFilterMenuOptions() {
  //   const _this = this;
  //   this.items = [];
  //   let groupByFilterType = _.groupBy(this.filterMenuOptions, 'filterType');
  //   this.groupByFilterTypeArray = _.values(groupByFilterType);
  //   _.forEach(groupByFilterType, function(items, key) {
  //     let outerObj = {
  //       label: null,
  //       items: []
  //     }
  //     outerObj.label = _.cloneDeep(_this.setCssProperties.formateCamelCaseToSentence(key));
  //     let indItems = [...items];
  //     indItems.forEach((value,index)=> {
  //     const val = {...value}
  //       val['icon'] = _this.getIcon(value?.id);
  //       val['label'] = _this.getLabel(value);
  //       val['command'] = _this.getCommand(value);

  //       indItems[index] = val;
  //     });
  //     outerObj.items = _.cloneDeep(indItems)
  //     _this.items.push(outerObj);
  //   });
  // }

  buildFilterMenuOptions() {
    this.items = [];
  
    // Group by 'filterType' without lodash
    const groupByFilterType = this.filterMenuOptions.reduce((result, item) => {
      (result[item.filterType] = result[item.filterType] || []).push(item);
      return result;
    }, {});
  
    this.groupByFilterTypeArray = Object.values(groupByFilterType);
  
    Object.entries(groupByFilterType).forEach(([key, items]:any) => {
      let outerObj = {
        label: this.setCssProperties.formateCamelCaseToSentence(key),
        items: []
      };
  
      let indItems = items.map(value => {
        const val = {
          ...value,
          icon: this.getIcon(value.id),
          label: this.getLabel(value),
          command: ()=>this.getCommand(value)
        };
        return val;
      });
  
      outerObj.items = indItems;
      this.items.push(outerObj);
    });
  }
  
  
  getSelectedType(selectedObj) {
    const vm = this;
    _.forEach(vm.filterMenuOptions, (optionObj) => {
      if((optionObj.filterType === selectedObj.filterType) && (selectedObj.filterType !== 'download')) {
        optionObj['active'] = optionObj['id'] === selectedObj['id'] ? true : false;
      }
    })
  }

  getOptionColor(option) {
    if(option.active) {
      return this.pillActiveColour ? (sessionStorage.getItem('theme') === 'dark-theme' ? 'white' : this.pillActiveColour) : 
        this.defaultActivePillColour
    } else {
      return 'inherit'
    }
  }

  getOptionLabel(option){
    let lab = '';
   if (option.label) {
    lab = option.label;
   } else if (option.id) {
    lab = option.id;
   }
     return lab;
  }

  getIcon(option) {
    switch (option) {
      case 'bar':
        return 'pi pi-chart-bar';
      case 'line':
        return 'pi pi-chart-line';
      case 'pie':
        return 'pi pi-chart-pie';
      case 'xl':
        return 'pi pi-file-excel';
      default :
        return '';
    }
  }

  getLabel(option) {
    let id = option?.id;
    let label = option?.label;
    switch (id) {
      default :
        return label ? label : this.setCssProperties.formateCamelCaseToSentence(id ? id : '');
    }
  }

  getCommand(option) {
    option['graphType'] = option.id
    option['isStacked'] = this.items[0].items.find(({id})=>(id===option.id))?.isStacked
    this.selectedFilterType.emit(option)
  }
}

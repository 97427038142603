<p-sidebar class="multiple-assets-creation-sidebar" position="right" (onShow)="isSidebarClosed.set(false)"
  (onHide)="closePopup()" [fullScreen]="true" [(visible)]="showSidebarForm"  [dismissible]="false">
  <ng-template pTemplate="header">
    <div class="custom-modal-header">
      <h5 class="modal-title TTC"><span>{{ getLangLabel('sys.add') + ' Multiple'}}</span> {{ getLangLabel(formLabel()) }}</h5>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    @if(showSidebarForm){
  <p-table [value]="multipleAssets()">
    <ng-template pTemplate="header">
      <tr>
        <th>Sr. No.</th>
        @if(true){
          @for (groupedFields of formDataForRendering(); track groupedFields;let index=$index) {
            <!-- <th>
          @for (field of groupedFields; track field;let index=$index) {
          @if(!isTypeToHide(field?.type)){
          {{ field.label | translate }} {{(index+1)!==groupedFields?.length ? ',' : ''}}
          }
          }
          </th> -->
          <th>{{getLangLabel(formLabel())}} Details</th>
          }
        }
        <th>Remove</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-asset>
      <tr [ngClass]="{'validated-asset-data':asset.isValid}">
        <td>{{asset.assetIdx+1}}</td>
        @for (groupedFields of formDataForRendering(); track groupedFields) {
          <td>
            <div class="row-input-fields">
        @for (field of groupedFields; track field) {
        @if(!isTypeToHide(field?.type)){
       
          @if ((field.type === 'text') || (field.type === 'email') || (field.type === 'preFill')) {

          <span class="p-float-label">
            <input [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" type="{{ field.type }}" class="full-width text-field-type" id="textInput"
             ngDefaultControl pInputText [ngClass]="{'input-disable': field.disabled && field.disabled === true}" placeholder="{{ field.description | translate }}" [disabled]="field.disabled" [required]="field.required" />
            <label for="textInput">{{ field.label | translate }} @if (field.required) {
              <span> *</span>
              }</label>
          </span>

          } @else if (field.type === 'number') {
          <span class="p-float-label">
            <p-inputNumber [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" class="full-width number-field-type" placeholder="{{ field.description | translate }}"
              mode="decimal"ngDefaultControl  [showButtons]="true" [minFractionDigits]="2"
              [maxFractionDigits]="14"   [disabled]="field.disabled"/>
            <label>{{ field.label | translate }} @if (field.required) {
              <span> *</span>
              }</label>
          </span>

          } @else if (field.type === 'textarea') {
          <span class="p-float-label">
            <textarea [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" pInputTextarea ngDefaultControl 
              class="full-width textarea-field-type" rows="3" cols="15" 
              placeholder=" {{ field.description | translate }}" [required]="field.required" [disabled]="field.disabled"></textarea>
            <label >{{ field.label | translate }} @if (field.required) {
              <span> *</span>
              }</label>
          </span>
          } @else if (field.type === 'dropDown') {
          <span class="p-float-label">
            <p-dropdown [(ngModel)]="asset[field.field]"
              (ngModelChange)="handleTriggerDropdownChange($event,field,asset);isValidSingleAsset(asset)" (onFilter)="handleDataFilterInDropDown($event,field,asset)"
              class="full-width dropDown-field-type" [autoDisplayFirst]="false"
             ngDefaultControl  [filter]="true" filterBy="label" 
              placeholder="{{ field.description | translate }}" [required]="field.required"
              [options]="field?.apiDetails?.attachToUrl ? (asset[field.field+'_dropDownValues']) : field.dropDownValues" optionLabel="label" optionValue="value" appendTo="body" [disabled]="field.disabled"/>
            <label >{{ field.label | translate }} @if (field.required) {
              <span> *</span>
              }</label>
              @if (!valueExistMessage?.[field.field]?.[asset?.assetIdx]?.['isUnique'] &&
              valueExistMessage?.[field?.field]?.[asset?.assetIdx]?.assetIndex == asset?.assetIdx){
              <small id="basicInput" class="smallErrorMsg">{{ valueExistMessage?.[field.field]?.[asset?.assetIdx]?.isValueExistMessage
                }}</small>
              }
          </span>
          } @else if (field.type === 'multiSelect') {
          <span class="p-float-label">
            <p-multiSelect [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" [options]="field.dropDownValues" class="multiSelect-field-type" 
             ngDefaultControl  [filter]="true" filterBy="label"  optionLabel="label" optionValue="value"
              appendTo="body" placeholder="{{field.description | translate}}" [required]="field.required" [disabled]="field.disabled"/>
          
            <label >{{ field.label | translate }} @if (field.required) {
              <span> *</span>
              }</label>
          </span>
        }@else if (field.type === 'colorPicker') {
          <div style="display: flex;">
            <span class="p-float-label">
              <input [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" class="full-width" placeholder="Pick a color"ngDefaultControl 
                     required type="text" id="color-text" value={{foreColor.inputBgColor}}  pInputText [disabled]="field.disabled">
              <label for="color-text">{{ 'sys.color' | translate }}@if (field.required) {
                <span> *</span>
                }</label>
            </span>
                <p-colorPicker [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" appendTo="body" class="full-width" ngDefaultControl  #foreColor [disabled]="field.disabled"/>
              </div>
        }@else if (field.type === 'telephone') {
            <span class="p-float-label full-width">
              <p-inputNumber [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" styleClass="full-width" class="full-width"ngDefaultControl 
                             placeholder="{{field.description | translate}}" 
                             mode="decimal"  [useGrouping]="false" [disabled]="field.disabled"/>
              <label >{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
            </span>
        } @else if (field.type === 'image') {
            <div class="image-upload-container">          
              <div class="image-gallery-container">
                  <h6>{{field.description}}</h6>
              </div>
              <button pButton icon="pi pi-upload" id="thumbnail-img-upload-btn" (click)="uploadIconClicked(asset.assetIdx)"
                      pTooltip="Upload Images" tooltipPosition="bottom" [label]="asset.imageCount ? ('('+asset.imageCount+')') : ''"></button>
                      @if(asset.assetIdx === currentAssetIdx()){
              <p-dialog header="Upload" [(visible)]="uploadImageDialog" [modal]="true" class="file-upload-modal"
                        [baseZIndex]="10000" closable="false" [draggable]="false" [resizable]="false">
                        <ng-template pTemplate="header">
                          <div class="dialog-header">
                            <p>Upload Image</p>
                            <i class="pi pi-times cursorPointer" (click)="clearSelectedImages()"></i>
                          </div>
                        </ng-template>
                @for (uploadItem of uploadItemList(); track uploadItem) {
                  <div class="p-field-radiobutton">
                    <p-radioButton  [(ngModel)]="selectedUploadItem" 
                    [inputId]="uploadItem.type" 
                    name="uploadItem" 
                    [value]="uploadItem" 
                    />
                <label [for]="uploadItem.type" class="ml-2">
                    {{ uploadItem.name }}
                </label>
                  </div>
                }
             
                  <p-fileUpload accept="image/*"             
                                name="file"
                                (onRemove)="removeImage($event)"
                                (onClear)="confirmImagesForUpload(asset.assetIdx)"
                                (onSelect)="storeImagesForUpload($event)"
                                [showUploadButton]="false"
                                [cancelLabel]="'Confirm'"
                                [cancelIcon]="'pi pi-check'"
                                [multiple]="selectedUploadItem()?.multiple"
                                />
                    @if(asset.imageCount){
                      <div class="asset-selected-images">
                        @for (imgType of asset.image | keyvalue; track imgType) {
                        <div >
                          <p class="image-type">{{getImageTypeLabel(imgType?.key)}}</p>
                          @for (labelSrc of asset.image[imgType?.key] | keyvalue; track labelSrc) {
                          <div class="selected-image">
                           <img [src]="labelSrc.value" alt="">
                           <p>{{labelSrc.key}}</p>
                           <i class="pi pi-times cursorPointer" (click)="removeSelectedImage(imgType?.key,labelSrc?.key,asset.assetIdx)"></i>
                         </div>
                        }
                        </div>
                      }
                   </div>
                    }
                 
             
              </p-dialog>
            }
            </div>
        } @else if (field.type === 'accordian') {
          <div>
      <p-accordion >
        <p-accordionTab [header]="(field?.label | translate)">
          @for (accordian of field?.accordianData; track accordian) {
            <div>
              @if (accordian?.type === 'accordian') {
                <div>
                  <p-accordion>
                    <p-accordionTab [header]="(accordian?.label | translate)">
                      <div >
                        @for (child of accordian?.childData; track child) {
                          <div>
                            <span class="p-float-label mt-4">
                              <input class="full-width" [id]="'accordianTextInput'" pInputText 
                                     [placeholder]="child.description | translate" [required]="child.required">
                              <label [for]="'accordianTextInput'">{{ child.label | translate }} @if (child.required) {
                                <span> *</span>
                              }</label>
                            </span>
                          </div>
                        }
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
              }
              @else if (accordian?.type === 'text' || accordian?.type === 'password') {
                <div>
                  <span class="p-float-label mt-4">
                    <input class="full-width" type="{{accordian?.type}}" id="basicInput"
                           [placeholder]="accordian.description | translate" (ngModelChange)="isValidSingleAsset(asset)" [(ngModel)]="asset[field.field][accordian.field]"
                           pInputText>
                    <label for="basicInput">{{ accordian.label | translate }} @if (accordian.required) {
                      <span> *</span>
                    }</label>
                  </span>
                </div>
              }
              @else if (accordian?.type === 'boolean') {
                <div>
                  <span class="flex justify-content-between mt-4">
                    <label class="full-width"
                           for="basicInput">{{ accordian.label | translate }} @if (accordian.required) {
                      <span> *</span>
                    }</label>
                    <p-inputSwitch class="full-width flex justify-content-end" (ngModelChange)="isValidSingleAsset(asset)" [(ngModel)]="asset[field.field][accordian.field]"/>
                  </span>
                </div>
              }
              @else if (accordian?.type === 'dropDown') {
                <div>
                  <span class="p-float-label full-width mt-4">
                    <p-dropdown class="full-width TTC dropDown-field-type"  (ngModelChange)="isValidSingleAsset(asset)" [autoDisplayFirst]="false" [(ngModel)]="asset[field.field][accordian.field]"
                    [filter]="true" filterBy="label" placeholder="{{accordian.description | translate}}"
                                 [required]="accordian.required"
                                [options]="accordian.dropDownValues" optionLabel="label" optionValue="value"/>
                    <label class="full-width"
                           >{{ accordian.label | translate }} @if (accordian.required) {
                      <span> *</span>
                    }</label>
                  </span>
                </div>
              }
            </div>
          }
        </p-accordionTab>
      </p-accordion>
    </div>
        } @else if (field.type === 'multiText') {
          <div>
            <span class="p-float-label full-width">
              <p-chips  [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" styleClass="full-width"
                       [separator]="separatorExp()" placeholder="{{field.description | translate}}" [disabled]="field.disabled"/>
              <label >{{ field.label | translate }} @if (field.required) {
                <span> *</span>
              }</label>
            </span>
          </div>
        } @else if (field.type === 'date') {
          <div>
            <span class="p-float-label full-width">
            <p-calendar [(ngModel)]="asset[field.field]" (ngModelChange)="isValidSingleAsset(asset)" class="full-width" placeholder="{{field.description}}"
             appendTo="body" [disabled]="field.disabled"/>
            <label>{{ field.label | translate }} @if (field.required) {
              <span> *</span>
            }</label>
          </span>
          </div>
        }@else { -}
       
        } }
      </div>
      </td>
      }

        <td>
          <p-button (onClick)="removeAsset(asset.assetIdx,asset)" icon="pi pi-times" [disabled]="multipleAssets()?.length===1" [rounded]="true" severity="danger"
            [outlined]="false" />

        </td>
      </tr>
    </ng-template>
  </p-table>
}
  <div class="multiple-assets-action">
    <p-button label="New" icon="pi pi-plus" (onClick)="createNewAssetModel(true,1)" />
    <p-button label="Cancel" icon="pi pi-times" [outlined]="true" (onClick)="closePopup()" />
    <p-button [label]="('Submit ' + (countValidAssetsData() || '') +' ' + getLangLabel(formLabel()))" icon="pi pi-check" (onClick)="submitAssetsCreation()"
    [disabled]="!multipleAssets()?.length || !countValidAssetsData()" />
  </div>
</ng-template>
</p-sidebar>

@if(showLoader()){<app-loader/>}



import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CarMeterGaugeType} from './Car-meter-gauge-type';
import {forEach} from 'lodash';
@Component({
  selector: 'app-car-meter-gauge',
  templateUrl: './car-meter-gauge.component.html',
  styleUrls: ['./car-meter-gauge.component.scss']
})
export class CarMeterGaugeComponent implements OnChanges, OnInit {
  @Input() rawGpsData;
  public showGauge: boolean;
  @Input() resetRandomNumber: number;
  @Input() carGaugeOptions: CarMeterGaugeType;
  constructor() {
    this.showGauge = false;
  }
  public options;
  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === 'rawGpsData') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.drawCarMeterGauge();
        }
      }
      if (propName === 'resetRandomNumber') {
        if (change.currentValue && change.currentValue !== change.previousValue) {
          this.resetValues();
        }
      }
    }
  }

  drawCarMeterGauge() {
    this.showGauge = true;
    this.options = {
      tooltip : {
        formatter: "{a} <br/>{c} {b}"
      },
      toolbox: {
        show: true,
        /*feature: {
         restore: {show: true},
         saveAsImage: {show: true}
         }*/
      },
      series : [
        {
          name: ((this.carGaugeOptions && this.carGaugeOptions.name) ? this.carGaugeOptions.name : ''),
          type: 'gauge',
          // center: ['17%', '55%'],
          radius: this.carGaugeOptions.radius,
          min: this.carGaugeOptions.min,
          max: this.carGaugeOptions.max,
          /*endAngle:45,
           splitNumber:7,*/
          axisLine: {
            lineStyle: {
              width: 8,
              color: this.setAxisColors()
            }
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto'
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto'
            }
          },
          pointer: {
            width: 5
          },
          title: {
            offsetCenter: [0, '-30%'],
          },
          detail: {
            fontWeight: 'bolder'
          },
          data: [{value: this.carGaugeOptions.data[0].value, name: this.carGaugeOptions.data[0].name}]
        }
      ]
    };
  }

  resetValues() {
    if (this.options) {
      this.showGauge = false;
    }
  }

  setAxisColors() {
    let colorsArray = [];
    forEach(this.carGaugeOptions.axisLineColor, function(options) {
       let arr = [];
       arr.push(options.position);
       arr.push(options.color);
       colorsArray.push(arr);
    });
    return colorsArray;
  }

}

<div class="assetListGrid" id="assetListingPopup">
  <!--  <div class="grid noMargin">-->
  <!--    <div class="md:col-12">-->
  <!--      <div class="backBtn">-->
  <!--        <button mat-raised-button color="primary" id="assetListBackBtn" (click)="resetSearchedAssets()">Cancel</button>-->
  <!--        <button mat-raised-button color="accent" type="button" class="linkBtn showLinkAlertPopupBtn buttonEnable"-->
  <!--                *ngIf="linkAssetsIds.length > 0">Link <i class="fa fa-link" aria-hidden="true"></i></button>-->
<!--      </div>-->
<!--      -->
<!--    </div>-->
<!--  </div>-->
  <div class="header-container">
    <app-search-for-asset [allAssetTypes]="allAssetTypes" [assetData]="assetData" [config]="config"
                          (searchedAssets)="searchedAssetsFound($event)" (assetTypeChange)="resetAssetVisits()"
                          [resetSearchedAssetOnBackBtn]="resetSearchedAssetOnBackBtn"
                          [resetSearchedAssetOnPlusClick]="resetSearchedAssetOnPlusClick" [assetsLimit]="assetsLimit"
                          (searchingAsset)="getSearchVal($event)"></app-search-for-asset>

    <div class="btn-cancel-container">
      <div class="grid mx-0 py-1">
        <div class="col-12">
          <div class="grid mx-0 justify-content-start">
            <p-confirmPopup key="assetsForLinkingPopup"></p-confirmPopup>
            @if (linkAssetsIds.length > 0) {
              <p-button color="accent"
                        label="{{linkAssetsIds.length === 1 ? getLanglabel('sys.link-asset') : getLanglabel('sys.link-assets')}}"
                        icon="pi pi-link" class="linkBtn p-button-secondary buttonEnable mx-1"
                        (click)="showPopupBtn($event)">
              </p-button>
            }
            <button pButton pRipple type="button" label="{{'sys.cancel' |translate}}" color="warn" id="assetListBackBtn"
                    class="mx-1 p-button-danger"
                    (click)="resetSearchedAssets()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid mx-1 mt-1 assetListContainer"
       [ngStyle]="{'height' : searchedAssetForLinking && searchedAssetForLinking.length !== 0 ? '52%' : '0%'} ">
    @for (asset of searchedAssetForLinking; track asset; let i = $index) {
      <div class="relative xl:col-6">
      <div  [id]="'position_'+(i+1)">
        <app-thing-card [assetData]="asset" [config]="config" [assetConfigDetails]="allAssetTypes"
                        [showCloseBtn]="false" [showGroupBtn]="true" [isLinkable]="true"
                        [isLinkableTooltipValue]="getLanglabel('sys.click-to-select-and-link-asset')"
                        [isCardClickable]="false" [showLinkBtn]="true" (linkAssetDetails)="linkAssetDetails($event)"
                        [imageDisplayStatus]="(cardsImageDisplayStatus$ | async)?.[asset?.assetType]"></app-thing-card>
      </div>
    </div>
    }
    @if (showCirularLoader) {
      <div class="bottom-circular-loader">
      <app-circular-loader></app-circular-loader>
    </div>
    }
  </div>

</div>

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {AssetListComponent} from '../app/asset-list/asset-list.component';
import {AuthGuard} from '../auth/auth.guard';
import {Router} from '@angular/router';

@Injectable()
export class LoginServiceService {
  logo: string;

  // public authToken: Subject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, public configService: ConfigService, public router: Router) { }

  //
  // verifyUserDetails(userDetails) {
  //   return this.http.post(this.configService.appConfig.loginBaseURL + '/authentication/login', userDetails);
  // }
  //
  // roleMatch(allowedRoles) {
  //   let isMatch: boolean;
  //   isMatch = false;
  //   let userRoles: string[];
  //   userRoles = JSON.parse(sessionStorage.getItem('userRoles'));
  //   allowedRoles.forEach(element => {
  //     if (userRoles.indexOf(element) > -1) {
  //        isMatch = true;
  //        return false;
  //     }
  //   });
  //   return isMatch;
  // }
  //
  // updateLogoToSideMenu(logo) {
  //   this.logo = logo;
  // }
  //
  // getAndSendToken(tokenFromLogin?) {
  //   if (tokenFromLogin) {
  //     this.authToken.next(tokenFromLogin);
  //   } else {
  //     this.authToken.next(null);
  //   }
  // }

  getLogo() {
    return this.http.get(`${this.configService.appConfig.appBaseUrl}account/self/logo`);
  }

  getKioskSubQrCode(){
    return this.http.get(`${this.configService.appConfig.appBaseUrl}sokSubscription/qrCodePayment`)
  }


  buildRoutesForAssetTypes(assetTypes: Array<unknown>) {
    this.router?.config?.forEach((route) => {
      const childRoutes = new Array<unknown>();
      if (route['path'] === 'asset') {
        assetTypes?.forEach((assetType) => {
          if (assetTypes[0] === assetType) {
            childRoutes.push({ path: '', redirectTo: assetType, pathMatch: 'full'});
          }
          childRoutes.push({ path: assetType, component: AssetListComponent, canActivate: [AuthGuard]});
        });
        route['children'] = childRoutes;
      }
    });
  }

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'firstUpperCase',
})
export class FirstUpperCaseFilter implements PipeTransform {
  transform(str: string): string {
    return str ? str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    ) : '';
  }
}

<div class="custom-modal-dialog"></div>
<p-sidebar
  class="info-prime-sidebar"
  position="right"
  (onShow)="isSidebarClosed = false"
  (onHide)="closeAssetPopup()"
  [fullScreen]="(sidebarIsFullScreen$ | async)"
  [(visible)]="isCurrentIdActive"
  [dismissible]="false"
  [modal]="false"
>
  <ng-template pTemplate="header">
    <div class="grid mx-0 align-items-center">
      @if (assetData) {
        <h5 class="modal-title">{{ assetData.name ? assetData.name : getLangLabel(modalTitle) }}</h5>
      }
      <!--Follow Me Button-->
      @if (showFollowMeBtn) {
        <button color="primary" mat-raised-button class="btn followMeBtn ml-3"
                (click)="followMeBtnClicked()">
          <mat-icon>{{ assetData && assetData.isCarSelectedToTrack ? 'gps_off' : 'my_location' }}</mat-icon>
          {{ assetData && assetData.isCarSelectedToTrack ? getLangLabel('sys.unfollow') : getLangLabel('sys.follow') }}
        </button>
      }
    </div>
  </ng-template>
  <div
    class="custom-modal"
      style="display:block"
    [ngClass]="{'custom-modal-view':true}"
      [id]="sidebarId"
  >
      <div class="custom-modal-content PR">
        @if (IstoggleButtonRequired) {
          <div class="panel-toggle-button" id="modal-close" data-dismiss="modal" (click)="closeAssetPopup()">
            <span class="arrow-icon fas fa-chevron-right"></span>
          </div>
        }
        @if (IstoggleButtonRequired) {
          <div class="mobile-close" (click)="closeAssetPopup()">
            <mat-icon class="mob-icon">close</mat-icon>
          </div>
        }
        <!-- </div> -->
        <div class="custom-modal-body">

          <div class="alert alert-danger alert-dismissible imageUploadError" role="alert">
            <strong> {{ imageuploadError }} </strong>
            <button aria-label="close" class="close" onclick="$('.alert').hide()" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="grid relative">
            <!--          <div class="col-12 md:col-6 lg:col-6 xl:col-6">-->
            <!--            <app-thing-card *ngIf="assetData && assetData.assetType" [assetData]="assetData" [isCardHeightFixed]="true" [showCloseBtn]="false" [showLinkBtn]="false"-->
            <!--                            [isLinkable]="false" [isCardClickable]="false" [showGroupBtn]="true" [upadtedCartSpecificDetails]="assetUpdatedData"-->
            <!--                            [assetConfigDetails]="assetConfigDetails" [serviceAssetConfigDetails]="serviceAssetConfigDetails"-->
            <!--                            [resetFormData]="resetFormData" [config]="assetConfigDetails" [showStatusOnAssetCard]="showStatusOnAssetCard"-->
            <!--            ></app-thing-card>-->

            <!--            <app-thing-card *ngIf="assetData && assetData.serviceType" [assetData]="assetData" [isCardHeightFixed]="true" [showCloseBtn]="false" [showLinkBtn]="false"-->
            <!--                            [isLinkable]="false" [isCardClickable]="false" [showGroupBtn]="true" [upadtedCartSpecificDetails]="assetUpdatedData"-->
            <!--                            [assetConfigDetails]="assetConfigDetails" [serviceAssetConfigDetails]="serviceAssetConfigDetails"-->
            <!--                            [resetFormData]="resetFormData" [config]="serviceAssetConfigDetails" [showStatusOnAssetCard]="showStatusOnAssetCard"-->
            <!--            ></app-thing-card>-->
            <!--          </div>-->

            <!-- Uploading an Image -->
            <!-- <div class="col-12 md:col-6 lg:col-6 xl:col-6 image-upload-main-container" *ngIf="assetData && (isprofilePhotoAccepted || isThumbnailAccepted)"> -->
            <!-- <div class="grid mx-0 justify-content-center"> -->

            <!-- <div class="col-12 px-0">
            <div class="grid justify-content-center">
              <div class="image-upload-container border">
                <div *ngIf="showCircularLoaderForImg">
                  <app-circular-loader></app-circular-loader>
                </div> -->
            <!--<span class="image-edit cursorPointer" matTooltip="Image edit" matTooltipPosition="left" *ngIf="profileImageUpload.isBtnDisabled" (click)="profileImageUpload.isBtnDisabled = false"><img class="disable-btn" src="../../../assets/editButtonDisabled.png" alt="editBtn"></span>
            <span class="image-edit" matTooltip="Image edit active" matTooltipPosition="left" *ngIf="!profileImageUpload.isBtnDisabled" ><img src="../../../assets/editButtonActive.png" alt="editBtn"></span>-->
            <!-- <img class="profile-image" [src]="profileImageUpload.imagePreview" alt="img" *ngIf="profileImageUpload.selectedFile" [ngStyle]="{'opacity': showCircularLoaderForImg ? 0.1 : 1}"> -->
            <!--<img [src]="faceMatchService.getPersonProfilePic(assetData._id,'profilePhoto')" alt="img">-->
            <!-- <img class="profile-image thumbnail-Img" id="{{'thumbnailImage'+assetData._id}}" *ngIf="!profileImageUpload.selectedFile" src="{{profileImageUpload.url}}" alt="img" [ngStyle]="{'opacity': showCircularLoaderForImg ? 0.1 : 1, 'cursor': 'pointer'}"
            data-toggle="modal" data-target="#profileImgModal" data-keyboard="false" (click)="getProfileImageForModel()"> -->
            <!--<img class="profile-image" *ngIf="!profileImageUpload.profilePhoto && !profileImageUpload.selectedFile" src="../../../assets/img-person-placeholder.jpg" alt="img" [ngStyle]="{'opacity': showCircularLoaderForImg ? 0.1 : 1}">-->
            <!-- </div>
          </div>
        </div> -->

            <!-- <div class="col-12 px-0 uploadBtnsContainer">
            <div class="mx-0">
              <input #imageInp type="file" class="upload-text border" id="file" accept="image/*" name='file' ng2FileSelect [uploader]="uploader" (change)="onSelectFile($event)"/> -->

            <!-- <button *ngIf="profileImageUpload.isBtnDisabled && !profileImageUpload.imageSelected && ACTIONS.UPDATE | able: SUBJECT.ASSET"
            class="btn btn-primary upload-btn"
            (click)= "imageInp.click()"
          >{{'sys.select-image' | translate}}</button> -->

            <!-- <button pButton *ngIf="profileImageUpload.isBtnDisabled && !profileImageUpload.imageSelected && ACTIONS.UPDATE | able: SUBJECT.ASSET"
          class="p-button full-size-btn upload-btn"  (click)= "imageInp.click()">{{'sys.select-image' | translate}}</button> -->


            <!-- <button class="btn btn-success upload-btn" (click)="showCircularLoaderForImg = true;uploader.uploadAll()" *ngIf="profileImageUpload.imageSelected" [ngClass]="activateUploadingBtn ? 'disabled' : ''">{{activateUploadingBtn ? 'UPLOADING...' : 'UPLOAD IMAGE'}}</button> -->
            <!-- <button pButton class="p-button full-size-btn p-button-success" (click)="showCircularLoaderForImg = true;uploader.uploadAll()" *ngIf="profileImageUpload.imageSelected" [ngClass]="activateUploadingBtn ? 'disabled' : ''">{{activateUploadingBtn ? 'UPLOADING...' : 'UPLOAD IMAGE'}}</button>  -->

            <!--<div class="delete-icon-grid" (click)="deleteProfilePic()"><i class="zmdi zmdi-delete delete-icon"></i></div>-->
            <!--
            </div>
            </div>
            </div> -->


            <!-- <div class="messagePopup imageuploaded">
            {{'sys.image-load-success' | translate}} &#x2713;
            </div> -->
            <!--            <div class="errMessagePopup imageUploadError">-->
            <!--              {{imageuploadError}}-->
            <!--            </div>-->

            <!--<input #imageInp type="file" class="upload-text border" id="file" accept="image/*" name='file' ng2FileSelect [uploader]="uploader" (change)="onSelectFile($event)">
            &lt;!&ndash; <input #imageInp type ="file" accept= "image/*" class="upload-text border" name="file" (change) = "onSelectFile($event)">&ndash;&gt;
            &lt;!&ndash;<button class="btn btn-primary upload-btn" (click)= "imageInp.click()">{{(profileImageUpload.imageSelected ? 'Image Selected' : 'Select Image')}}</button>&ndash;&gt;

            <button class="btn btn-primary upload-btn" [disabled]="profileImageUpload.isBtnDisabled" (click)= "imageInp.click()" *ngIf="!profileImageUpload.imageSelected">Select Image <div class="delete-icon-grid" (click)="deleteProfilePic()"><i class="zmdi zmdi-delete delete-icon"></i></div></button>
            <button class="btn btn-success upload-btn" (click)="uploader.uploadAll()" *ngIf="profileImageUpload.imageSelected">Upload Image</button>-->

            <!-- </div> -->

            <!-- Qr code Image -->
            <!-- <div class="col-12 md:col-6 lg:col-6 xl:col-6 image-upload-main-container" *ngIf="assetData && isQrCodeRequired">
            <div class="grid mx-0 justify-content-center">

              <div class="col-12 px-0">
                <div class="grid justify-content-center">
                  <div class="image-upload-container border">
                    <img class="profile-image thumbnail-Img" [src]="assetService.getAssetQrCode(assetData._id)" alt="Qr code">
                  </div>
                </div>
              </div>
              <div class="col-12 px-0 uploadBtnsContainer">
                <div class="grid mx-0">
                  <button pButton label="Print Qr Code" icon="pi pi-print" class="p-button full-size-btn printQRCode-btn" (click)="printQrCode(assetService.getAssetQrCode(assetData._id))"></button> -->
            <!--<a  class="FR anchor" style="margin-top: 2px" (click)="printQrCode(assetService.getAssetQrCode(assetData._id))">Print Qr Code</a>-->
            <!-- </div>
          </div>

        </div>

        </div> -->

            <!------------------>
            <!--          &lt;!&ndash;edit button for Asset&ndash;&gt;-->
            <!--          <div class="plusIcon" id="edit-asset-form" (click)="editOptionClicked()"-->
            <!--               *ngIf="assetData && assetData.assetType && (!isAssetDetailsInReadMode && assetFromConfig.length > 0 ) && ACTIONS.UPDATE | able: SUBJECT.ASSET">-->
            <!--            <a href="#" onclick="return false;" data-toggle="tooltip" data-placement="top" [matTooltip]="!isEditOptionClicked ? 'Edit' : ''" matTooltipPosition="before" class="btn-floating btn-lg waves-effect waves-light plus">-->
            <!--              <i class="fa fa-pen"></i><span class="slash-line" *ngIf="isEditOptionClicked"></span></a>-->
            <!--          </div>-->

            <!--          &lt;!&ndash;edit button for Service&ndash;&gt;-->
            <!--          <div class="plusIcon" id="edit-service-form" (click)="editOptionClicked()"-->
            <!--               *ngIf="assetData && assetData.serviceType && (!isAssetDetailsInReadMode && assetFromConfig.length > 0 ) && ACTIONS.UPDATE | able: SUBJECT.SERVICE">-->
            <!--            <a href="#" onclick="return false;" data-toggle="tooltip" data-placement="top" [matTooltip]="!isEditOptionClicked ? 'Edit' : ''" matTooltipPosition="before" class="btn-floating btn-lg waves-effect waves-light plus">-->
            <!--              <i class="fa fa-pen"></i><span class="slash-line" *ngIf="isEditOptionClicked"></span></a>-->
            <!--          </div>-->


            <!--          &lt;!&ndash;Follow Me Button&ndash;&gt;-->
            <!--          <div *ngIf="showFollowMeBtn" (click)="followMeBtnClicked()">-->
            <!--            <button mat-mini-fab [color]="assetData && assetData.isCarSelectedToTrack ? 'accent' : ''"  class="followMeBtn"-->
            <!--          </div>-->
          </div>
          @if (sideBarMenuItems) {
            <p-tabMenu
              [model]="sideBarMenuItems"
              [scrollable]="true"
              [activeItem]="sideBarMenuItems[0]"
            ></p-tabMenu>
          }
          <!-- <div mat-tab-nav-bar class="tab right-slider-tabs" id="sidebar-tabs-container" [ngStyle]="{'margin-top': '0px'}">

          <button mat-tab-link class="tablinks defaultTab" (click)="openTabContent($event, 'assetInfo')"  [ngClass]="{'active' : true}">{{infoLabel | translate}}</button>
          <button mat-tab-link class="tablinks carLiveDataTab" (click)="openTabContent($event, 'carLiveData');  getCarLiveData()" *ngIf="carLive && carLive['showCarLive']">{{carLive['showCarLive']}}{{'sys.live' | translate}}</button>
                      <button mat-tab-link class="tablinks linkTab" (click)="openTabContent($event, 'link')" *ngIf="assetData && assetData.serviceType && isAssetLinkable
                        && ACTIONS.READ |able: SUBJECT.ASSET">{{'sys.linked' | translate}}</button>
                      <button mat-tab-link class="tablinks serviceManageTab" (click)="openTabContent($event, 'manageService')" *ngIf="assetData && assetData.serviceType &&
                        isServiceManageable && ACTIONS.WRITE |able: SUBJECT.SERVICE">{{'sys.manage' | translate}}</button>

                      <button mat-tab-link class="tablinks linkTab" (click)="openTabContent($event, 'link')" *ngIf="assetData && assetData.assetType && isAssetLinkable &&
                      ACTIONS.READ |able: SUBJECT.ASSET">{{'sys.linked' | translate}}</button>
                      <button mat-tab-link class="tablinks groupsTab" (click)="openTabContent($event, 'groups')" *ngIf="isGroupTabRequired && assetData && assetData.assetType &&
                      ACTIONS.READ |able: SUBJECT.GROUP">{{'sys.groups' | translate}}</button>
                      <button mat-tab-link class="tablinks serviceTab" (click)="openTabContent($event, 'service');_serviceLinkMethods.showLink = true;_serviceLinkMethods.showDeploy = false;
                      _serviceLinkMethods.activePillIndex = 0; _serviceDeploy.selectedService = null;" *ngIf="assetData && assetData.assetType &&
                        isServiceLinkable && ACTIONS.READ |able: SUBJECT.SERVICE">{{'sys.service' | translate}}</button>
          <button mat-tab-link class="tablinks statusTab" (click)="openTabContent($event, 'status'); getServerAnalytics(); showStatsGraphTabs = false" *ngIf="showStatus">{{'sys.stats' | translate}}</button>
          <button mat-tab-link class="tablinks carStatusTab" (click)="openTabContent($event, 'carStatus'); getCarStatus()" *ngIf="showCarStatus && ACTIONS.READ |able: SUBJECT.TRACKER">{{'sys.stats' | translate}}</button>
          <button mat-tab-link class="tablinks carEventDataTab" (click)="openTabContent($event, 'carEventData');  getCarEventData()" *ngIf="showCarStatus">{{'sys.events' | translate}}</button>
          <button mat-tab-link class="tablinks cameraLiveTab" (click)="openTabContent($event, 'cameraLive'); getCameraLiveStream();" *ngIf="cameraLive.showCameraLive && ACTIONS.LIVE |able: SUBJECT.ASSET">{{'sys.camera-live' | translate}}</button>
          <button mat-tab-link class="tablinks serverLiveTab" (click)="openTabContent($event, 'serverLive'); getServerLiveStream();" *ngIf="serverLive.show">{{'sys.live' | translate}}</button>
          </div> -->

          <div id="assetInfo" class="tabcontent" style="display: block">

            <div class="grid" style="position: relative">

              <!--  -->
              <!-- caroseul -->


              <div class="col-12 px-0">
                <div class="grid justify-content-center">
                  @if ((images.length) > 0) {
                    <div class="prime-carousel-container">
                      <!-- prime carousel galary  advanced-->
                      <p-galleria #galleria [(value)]="images" [(activeIndex)]="activeIndex" [numVisible]="5"
                                  [containerStyle]="{'max-width':'280px'}" [containerClass]="galleriaClass()"
                                  [showThumbnails]="showThumbnails"
                                  [showItemNavigators]="true" [showItemNavigatorsOnHover]="true" [circular]="true"
                                  [autoPlay]="false" [transitionInterval]="3000"
                                  [showItemNavigators]="images.length>1 ? true : false ">
                        <ng-template pTemplate="item" let-item>
                          <img class="carousel-img" [src]="item.previewImageSrc"
                               [ngClass]="{'carousel-full-screen': (fullscreen ? true: false)}"/>
                        </ng-template>
                        <ng-template pTemplate="thumbnail" let-item>
                          <div class="grid grid-nogutter justify-content-start">
                            <img [src]="item.thumbnailImageSrc" width="50px"/>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="footer" let-item>
                          <div class="custom-galleria-footer">
                            <button type="button" pButton icon="pi pi-list" (click)="onThumbnailButtonClick()"></button>
                            @if (images) {
                              <span class="title-container">
                      <span>{{ activeIndex + 1 }}/{{ images.length }}</span>
                      <span class="title">{{ images[activeIndex].title }}</span>
                      <span>{{ images[activeIndex].alt }}</span>
                    </span>
                            }
                            <button type="button" pButton [icon]="fullScreenIcon()" (click)="toggleFullScreen()"
                                    class="fullscreen-button"></button>
                            <button id="custom-galleria-delete" type="button" pButton icon="pi pi-trash"
                                    (click)="onDeleteCarousleItem($event, images[activeIndex].assetId)"
                                    style="color:red"></button>
                          </div>
                        </ng-template>
                      </p-galleria>
                      <!-- prime carousel galary  advanced-->
                      <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
                      <p-confirmPopup key="imageDeletePopup"></p-confirmPopup>
                    </div>
                  }


                  <!-- attached file count -->

                  <div class="col-12 px-5">
                    <div class="grid justify-content-center .align-items-center">
                      @if (showUploadBtn) {
                        <div class="icons flex align-items-center">
                          <button pButton icon="pi pi-upload" id="thumbnail-img-upload-btn"
                                  (click)="uploadIconClicked()" pTooltip="Upload Files" tooltipPosition="bottom">
                          </button>
                          @if (showUploadBtn && showFileBadge) {
                            <p (click)="filesIconClicked()" style="margin-top: 12px;" pTooltip="List of Attached Files"
                               tooltipPosition="bottom">
                              <mat-icon [matBadge]="file_count" matBadgeColor="warn">attach_file</mat-icon>
                            </p>
                          }
                          <p (click)="qrIconClicked()" pTooltip="QR Code" tooltipPosition="bottom">
                            <mat-icon>qr_code</mat-icon>
                          </p>
                        </div>
                      }

                    </div>
                  </div>

                  <!-- upload file modal -->
                  <p-dialog header="Upload" [(visible)]="uploadImage" [modal]="true" class="file-upload-modal"
                            [baseZIndex]="10000"
                            [draggable]="false" [resizable]="false" (onHide)="uploadModalHideEvent()">

                    @for (uploadItem of uploadItemList; track uploadItem; let i = $index) {
                      <div class="p-field-radiobutton">
                        <p-radioButton [inputId]="uploadItem" name="uploadItem" [value]="uploadItem"
                                       [(ngModel)]="selectedUploadItem"
                                       (onClick)="radioButtonSelected(selectedUploadItem)"></p-radioButton>
                        <label [for]="uploadItem"
                               style="margin-right: 5px;">{{ transformFileName(uploadItem) }} </label>
                        @if (uploadItemDescriton[i]) {
                          <span>
                  <span class="pi pi-question-circle" style="font-size: 1rem" pTooltip="{{uploadItemDescriton[i]}}"
                        tooltipEvent="hover">
                  </span>
                </span>
                        }
                      </div>
                    }
                    <!-- input item name -->
                    @if (containsOthers()) {
                      <div style="margin-top:10px" class="fileNameInputContainer flex gap-3">
                        <input class="p-inputtext-sm" type="text" pInputText [disabled]="HideItemNameInput"
                               placeholder="Enter Item Name" [(ngModel)]="filetypeNameInput"/>
                        <button pButton icon="pi pi-plus"
                                [disabled]="HideItemNameInput || !(filetypeNameInput.length > 0)"
                                (click)="addFileName(filetypeNameInput)"></button>
                      </div>
                    }

                    <ng-template pTemplate="footer">
                      <p-fileUpload accept="image/*" [url]="uploadUrl" (onProgress)='onUploadStarted()'
                                    (onError)="onError($event)" name="file" [disabled]="uploadDisabled"
                                    [showUploadButton]="showFileUploadButton" [multiple]="false" (onSelect)="onSelect()"
                                    (onUpload)="onUpload($event)">

                      </p-fileUpload>
                    </ng-template>
                  </p-dialog>
                  <!-- upload file modal -->


                </div>


              </div>
            </div>


            <div class="grid justify-content-end py-1 my-1 mx-0">
              @if (showEditIconForNonServiceType && assetData && assetData.assetType && (!isAssetDetailsInReadMode && formConfiguration) && (ACTIONS.UPDATE | able: SUBJECT.ASSET)) {
                <div class="tab-specific-button" id="asset-edit-btn"
                >
                  <div class="grid mx-0 justify-content-between"
                       pTooltip="{{'sys.edit' | translate}}" tooltipPosition="top"
                       (click)="editOptionClicked()">
                    <app-edit-icon [isActive]="isEditOptionClicked"></app-edit-icon>
                    <p style="margin-left: 5px; margin-top: 2px">Edit</p>
                  </div>
                </div>
              }

              <!--edit button for Service-->
              @if (assetData && assetData.serviceType && (!isAssetDetailsInReadMode && formConfiguration) && ACTIONS.UPDATE | able: SUBJECT.SERVICE) {
                <div class="tab-specific-button" id="service-edit-btn"
                >
                  <div class="grid mx-0 justify-content-between"
                       pTooltip="{{'sys.edit' | translate}}" tooltipPosition="top"
                       (click)="editOptionClicked()">
                    <app-edit-icon [isActive]="isEditOptionClicked"></app-edit-icon>
                    <p style="margin-left: 5px; margin-top: 2px">Edit</p>
                  </div>
                </div>
              }
            </div>

            <!--<app-asset-edit-from [resetFormData]="resetFormData" [isEditOptionClicked]="isEditOptionClicked" [formConfigData]="assetFromConfig"
            [assetData]="assetData" [assetId]="assetId" (deregisterAsset)="deregisterAsset($event)"
          (updateAssetCart)="updateAssetCart($event)"></app-asset-edit-from>-->

            <app-event-form
              [formConfigData]="formConfiguration"
              (formSubmitHandler)="formSubmitHandler($event)"
              [resetFormData]="resetFormData"
              [isEditForm]="isEventSelectedToEdit"
              [eventToEdit]="eventToEdit"
              [isEditOptionClicked]="isEditOptionClicked"
              [showDeRegisterBtn]="showDeRegisterBtn"
              [showUpdateBtn]="showUpdateBtn"
              [isInputDisabled]="true"
              [isAssetForm]="isAssetForm"
              (deregisterAsset)="deregisterAsset($event)"
              [assetTypeToSend]="assetTypeToSend"
            ></app-event-form>

          </div>

          @if (isAssetLinkable) {
            <div id="link" class="tabcontent">
              <div class="grid my-1 py-1">
                <div class="col-12">
                  @if (assetData && assetData.assetType && !isAssetDetailsInReadMode && ACTIONS.UPDATE |able: SUBJECT.ASSET) {
                    <div class="tab-specific-button FR relative mr-8" id="asset-list-plus"
                         pTooltip="{{'sys.link-assets' | translate}}" tooltipPosition="left"
                         (click)="resetSearchedAssets()"
                    >
                      <div class="mx-0 justify-content-between align-items-center">
                        <button pButton pRipple type="button" class="plus-btn" icon="pi pi-plus" onClick="return false"
                                (click)="resetSearchedAssets()"></button>
                        <span class="mat-slide-toggle-left-content-custom">{{ 'sys.assets' | translate }}</span>
                      </div>
                    </div>
                  }
                  @if (assetData && assetData.serviceType && !isAssetDetailsInReadMode && ACTIONS.UPDATE |able: SUBJECT.SERVICE) {
                    <div class="tab-specific-button FR relative mr-8" id="asset-list-plus"
                         pTooltip="{{'sys.link-assets' | translate}}" tooltipPosition="left"
                         (click)="resetSearchedAssets()"
                    >
                      <div class="mx-0 justify-content-between align-items-center">
                        <button pButton pRipple type="button" class="plus-btn" icon="pi pi-plus"
                                (click)="resetSearchedAssets()"></button>
                        <span class="mat-slide-toggle-left-content-custom">{{ 'sys.assets' | translate }}</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div class="grid mx-0 my-1 py-1 linked-assets-row">
                @for (assetDetails of individualAssetLinkedDetailsArray; track assetDetails) {
                  <div class="col-6 sm:col-3 md:col-3 lg:col-4 xl:col-3 pl-0 linked-asset-deck cursorPointer"
                  >
                    <p-card class="cursorPointer" styleClass="linked-asset-deck-card">
                      <ng-template pTemplate="content">
                        <div>
                          <a class="assetDetails-btn" mat-raised-button
                             pTooltip="{{getLangLabel(assetDetails['label'])}}"
                             tooltipPosition="top"
                             [ngClass]="assetDetails['isClicked'] ? 'active assetDetails-btn-active' : 'assetDetails-btn-inactive'"
                          [ngClass]="{
                            'active assetDetails-btn-active': assetDetails['isClicked'],
                            'assetDetails-btn-inactive': !assetDetails['isClicked'],
                            'primary-bg':!assetDetails['color']
                            }"
                          [ngStyle]="{
                            'background': assetDetails['color'] ? assetDetails['color'] : ''
                            }"
                             (click)="getSelectedLinkedAssetCards(assetDetails)">
                            <div class="label-icon-container">
                              <div class="icon-container">
                                <img src="{{assetDetails['icon']}}" style="display: inline-block" width="20px"
                                     class="assetDetails-icon">
                              </div>
                              <div class="assetDetails-label">{{ getLangLabel(assetDetails['label']) }}</div>
                            </div>
                            <span class="assetDetails-count"
                            [ngStyle]="{
                              'background-color' : assetDetails['highlightedColor']}">
                  {{ assetDetails['count'] > 99 ? '99+' : assetDetails['count'] }}</span>
                          </a>
                        </div>
                      </ng-template>
                    </p-card>
                  </div>
                }
              </div>
              @if (selectedLinkedAsset && selectedLinkedAsset['assetsLinked']) {
                <div class="grid my-1 py-1 linked-asset-cards-container">
                  @for (linkedAsset of selectedLinkedAsset['assetsLinked']; track linkedAsset) {
                    <div class="col-12 sm:col-6 lg:col-12 xl:col-6 mb-4 linked-asset-card relative">
                      <app-thing-card [assetData]="linkedAsset" [showCloseBtn]="isAssetDelinkBtnReqired()"
                                      [isCardClickable]="false" [showGroupBtn]="true"
                                      [assetConfigDetails]="assetConfigDetails"
                                      [isAssetDetailsInReadMode]="isAssetDetailsInReadMode"
                                      [showStatusOnAssetCard]="showStatusOnAssetCard"
                                      (delinkAssetDetails)="assetDetailsToDelink($event)"
                                      [config]="assetConfigDetails"
                                      [imageDisplayStatus]="(cardsImageDisplayStatus$ | async)[linkedAsset?.assetType]"></app-thing-card>
                    </div>
                  }
                </div>
              }
              <div>
                @if (individualAssetLinkedDetailsArray.length === 0) {
                  <h3 class="asset-not-found">
                    <span>&ldquo;</span>{{ 'app.core.custom-modal.no-asset-linked' | translate }}<span>&rdquo;</span>
                  </h3>
                }
              </div>
              <app-assets-for-linking [allAssetTypes]="assetConfig" [config]="assetConfigDetails"
                                      [assetData]="assetData" (linkAssetDetailsToSlider)="assetDetailsToLink($event)"
                                      [resetSearchedAssetOnPlusClick]="resetSearchedAssetOnPlusClick"></app-assets-for-linking>
            </div>
          }

          <div id="groups" class="tabcontent">
            <div class="grid my-1 py-1">
              <div class="md:col-12">
                @if (assetData && assetData.assetType && !isAssetDetailsInReadMode && ACTIONS.UPDATE |able: SUBJECT.GROUP) {
                  <div class="tab-specific-button FR relative mr-8" id="group-list-plus"
                       pTooltip="{{'sys.add-groups' | translate}}" tooltipPosition="left" (click)="getGroups()"
                  >
                    <div class="mx-0 justify-content-between align-items-center">
                      <button pButton pRipple type="button" icon="pi pi-plus" class="plus-btn" onClick="return false"
                              (click)="getGroups()"></button>
                      <span class="mat-slide-toggle-left-content-custom">{{ 'sys.groups' | translate }}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
            @if (assetData && _groupVariables.groups) {
              <div class="grid my-1 py-1 linked-asset-cards-container">
                @for (group of _groupVariables.groups; track group) {
                  <div class="col-12 sm:col-6 lg:col-12 xl:col-6 mb-4 linked-asset-card relative"
                       pTooltip="{{'sys.click-to-remove-things' | translate}}" tooltipPosition="right">
                    <p-card class="asset-mat-card mb-3">
                      <app-group-card [groupData]="group" [fieldsToBeDisplayed]="_groupVariables.fieldsToBeDisplayed"
                                      [isCardClickable]="false"
                                      [canAddToAsset]="isAssetDetailsInReadMode ? false : true"
                                      [cardLabel]="'label'"
                                      (linkGroupDetails)="removeGroupDetails($event)"></app-group-card>
                    </p-card>
                  </div>
                }
              </div>
            }
            <div class="grid">
              <div class="col-12">
                <div class="grid mx-0 my-1 py-1 justify-content-start">
                  @if (!isAssetDetailsInReadMode && _groupVariables.groupsIdsToRemove.length > 0 && ACTIONS.UPDATE | able: SUBJECT.GROUP) {
                    <button pButton type="button" icon="pi pi-minus"
                            label="{{_groupVariables.groupsIdsToRemove.length === 1 ? ' '+getLangLabel('sys.group') : ' '+getLangLabel('sys.groups') }}"
                            color="warn" class="p-button-danger linkBtn showRemoveGroupPopupBtn mx-1"></button>
                  }
                </div>
              </div>
            </div>
            @if (_groupVariables.groups.length === 0) {
              <div class="md:col-12">
                <h3 class="asset-not-found">
                  <span>&ldquo;</span>{{ 'sys.no-groups-added' | translate }}<span>&rdquo;</span>
                </h3>
              </div>
            }
  <!--          <app-groups-for-linking [isGetGroupsInitiated]="_groupVariables.isGetGroupsInitiated" [assetData]="assetData" (addGroupDetailsToSlider)="addGroupDetails($event)"></app-groups-for-linking>-->
          </div>

          @if (isServiceLinkable) {
            <div id="service" class="tabcontent">
              @if (arrayOfServicesLinkMethods.length > 0) {
                <app-iotzen-pills class="gauge-graph-toggle-btn"
                                  [pillActiveColour]="'#6D7FCC'"
                                  [defaultActivePillIndex]="_serviceLinkMethods.activePillIndex"
                                  [arrayOfPills]="arrayOfServicesLinkMethods"
                                  (activePillValue)="getServicesLinkMethodsActivePillValue($event)"
                                  [isPillTabToggleRequired]="true"></app-iotzen-pills>
              }
              @if (_serviceLinkMethods.showLink) {
                <div>
                  <div class="grid justify-content-end py-1 my-1 mx-0">
                    <div class="tab-specific-button relative mr-8" id="service-list-plus">
                      @if (!isAssetDetailsInReadMode && ACTIONS.WRITE |able: SUBJECT.SERVICE) {
                        <div class="grid mx-0 justify-content-between align-items-center"
                             pTooltip="{{'sys.link-services' | translate}}" tooltipPosition="left">
                          <button pButton pRipple class="plus-btn defaultButtonColor" (click)="resetSearchedServices()">
                            <i class="fas fa-plus"></i></button>
                          <span
                            class="mat-slide-toggle-left-content-custom">{{ 'app.sidemenu.services.services' | translate }}</span>
                        </div>
                      }
                    </div>
                  </div>
                  <div class="grid mx-0 my-1 py-1 linked-assets-row">
                    @for (serviceDetails of individualAssetLinkedDetailsArray; track serviceDetails) {
                      <div class="col-12 xl:col-3 pl-0 linked-asset-deck">
                        <a mat-raised-button pTooltip="{{serviceDetails['label'] | translate }}" tooltipPosition="top"
                           [ngClass]="serviceDetails['isClicked'] ? 'active' : ''"
                           [ngStyle]="{'border-bottom' : serviceDetails['isClicked'] && serviceDetails['color'] ? '4px solid ' + serviceDetails['color'] : ''}"
                           (click)="getSelectedLinkedAssetCards(serviceDetails)">
                          <div class="icon-container"
                               [ngStyle]="{'background-color' : serviceDetails['color'] ? serviceDetails['color'] : ''}">
                            <img src="{{serviceDetails['icon']}}" style="display: inline-block" width="20px"></div>
                          <div class="linked-btn-label">{{ serviceDetails['label'] | translate }}</div>
                          <span class="asset-badge" pBadge value="{{serviceDetails['count']}}"
                                [ngStyle]="{'background' : serviceDetails['color'] ? serviceDetails['color'] : 'red'}"></span>
                        </a>
                      </div>
                    }
                  </div>
                  @if (selectedLinkedAsset && selectedLinkedAsset['assetsLinked']) {
                    <div class="grid my-1 py-1 linked-asset-cards-container">
                      @for (linkedAsset of selectedLinkedAsset['assetsLinked']; track linkedAsset) {
                        <div class="col-12 sm:col-6 lg:col-12 xl:col-6 mb-4 linked-asset-card relative">
                          <p-card class="asset-mat-card">
                            <app-thing-card [assetData]="linkedAsset" [showCloseBtn]="false" [isCardClickable]="false"
                                            [showGroupBtn]="true" [showDelinkServiceBtn]="isServiceDelinkBtnReqired()"
                                            [assetConfigDetails]="assetConfigDetails"
                                            [serviceAssetConfigDetails]="serviceAssetConfigDetails"
                                            [isAssetDetailsInReadMode]="isAssetDetailsInReadMode"
                                            [showStatusOnAssetCard]="showStatusOnAssetCard"
                                            (delinkServiceDetails)="serviceDetailsToDelink($event)"
                                            [config]="serviceAssetConfigDetails"
                                            [imageDisplayStatus]="(cardsImageDisplayStatus$ | async)[linkedAsset?.assetType]">
                            </app-thing-card>
                          </p-card>
                        </div>
                      }
                    </div>
                  }
                  <div>
                    @if (individualAssetLinkedDetailsArray.length === 0) {
                      <h3 class="asset-not-found">
                        <span>&ldquo;</span>{{ 'app.core.custom-modal.no-services-linked' | translate }}
                        <span>&rdquo;</span>
                      </h3>
                    }
                  </div>
                  <app-services-for-linking [serviceAssetConfigDetails]="serviceAssetConfigDetails"
                                            [config]="serviceAssetConfigDetails" [assetData]="assetData"
                                            (linkAssetDetailsToSlider)="serviceDetailsToLinkAsset($event)"
                                            [resetSearchedServicesOnPlusClick]="resetSearchedServicesOnPlusClick"></app-services-for-linking>
                </div>
              }
              @if (_serviceLinkMethods.showDeploy) {
                <div class="W100FL MT30">
                  <div class="grid my-1">
                    <div class="py-2 col-12 md:col-6 lg:col-6 xl:col-6" style="float: right">
                      <p-dropdown [options]="_serviceDeploy.allServicesToManage" optionLabel="name"
                                  optionValue="service" [(ngModel)]="_serviceDeploy.selectedService"
                                  (ngModelChange)="getSelectedServiceForm($event)" [style]="{'width':'100%'}"
                                  [placeholder]="'Select service'"></p-dropdown>
                    </div>
                  </div>
                  @if (_serviceDeploy.selectedService) {
                    <div class="grid my-1">
                      <div class="col-12 md:col-12">
                        <div class="grid">
                          <div class="py-2 md:col-12">
                            <input id="configFiles" type="file" multiple (change)="onServiceUploadedFileChange($event)">
                          </div>
                          <div class="md:col-12 remark">
                            {{ _serviceDeploy.hintToUploadFiles }}
                          </div>
                          <div class="py-2 md:col-12 MT30">
                            <button pButton pRipple color="primary" type="submit"
                                    [disabled]="_serviceDeploy.disableSubmit" (click)="submitServiceDeploymentData()">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }

          @if (isServiceManageable) {
            <div id="manageService" class="tabcontent">
              @if (serviceActionsArray.length > 0) {
                <span>
        @for (action of serviceActionsArray; track action) {
          <button pButton pRipple
                  [color]="action['value'] === 'start' ? 'primary' : action['value'] === 'stop' ? 'warn' : action['value'] === 'restart' ? 'accent' : ''"
                  class='MR10 showServerActionBtn'
                  (click)="manageServices(action)">{{ action['label'] }}</button>
        }
      </span>
              }
              <div>
                @if (!isServerAvailable) {
                  <h3 class="asset-not-found">
                    <span>&ldquo;</span>{{ 'app.core.custom-modal.current-server-na' | translate }}<span>&rdquo;</span>
                  </h3>
                }
              </div>
            </div>
          }

          @if (showStatus) {
            <div id="status" class="tabcontent" style="height: 90%">
              <div class="grid justify-content-end py-1 my-1 mx-0">
                <div class="tab-specific-button">
                  <a href="#" onclick="return false;" (click)="getServerAnalytics(); showStatsGraphTabs = false"
                     pTooltip="{{'sys.refresh' | translate}}" tooltipPosition="left"
                     class="btn-floating btn-lg waves-effect waves-light plus">
                    <i class="pi pi-refresh inner-container-header-btn" style="font-size: 24px"></i></a>
                </div>
              </div>
              @if (statsNotFound) {
                <div>
                  <h3 class="asset-not-found">
                    <span>&ldquo;</span>{{ 'app.core.custom-modal.current-serer-stats-na' | translate }}
                    <span>&rdquo;</span>
                  </h3>
                </div>
              }
              @if (eChartGraph['disk'].show) {
                <div class="card-border mt-2 mb-3 PT10">
                  <app-sys-stats-graph [loader]="eChartGraph['disk'].showEchartLoader"
                                       [chartData]="eChartGraph['disk'].graph"
                                       [hardwarePropertyType]="eChartGraph['disk'].type"
                                       [show]="eChartGraph['disk'].show"
                                       [pillActiveColour]="eChartGraph['disk'].dataSetsBackgroundColor"
                                       [defaultActivePillIndex]="0" [graphTitle]="eChartGraph['disk'].title"
                                       [arrayOfPills]="arrayOfDurationPills" [isPillTabToggleRequired]="true"
                                       (graphDataWithSeriesType)="getGraphDataForSeriesType($event)"></app-sys-stats-graph>
                </div>
              }
              @if (eChartGraph['cpu'].show) {
                <div class="card-border mt-2 mb-3 PT10">
                  <app-sys-stats-graph [loader]="eChartGraph['cpu'].showEchartLoader"
                                       [chartData]="eChartGraph['cpu'].graph"
                                       [hardwarePropertyType]="eChartGraph['cpu'].type" [show]="eChartGraph['cpu'].show"
                                       [pillActiveColour]="eChartGraph['cpu'].dataSetsBackgroundColor"
                                       [defaultActivePillIndex]="0" [graphTitle]="eChartGraph['cpu'].title"
                                       [arrayOfPills]="arrayOfDurationPills" [isPillTabToggleRequired]="true"
                                       (graphDataWithSeriesType)="getGraphDataForSeriesType($event)"></app-sys-stats-graph>
                </div>
              }
              @if (eChartGraph['memory'].show) {
                <div class="card-border mt-2 mb-3 PT10">
                  <app-sys-stats-graph [loader]="eChartGraph['memory'].showEchartLoader"
                                       [chartData]="eChartGraph['memory'].graph"
                                       [hardwarePropertyType]="eChartGraph['memory'].type"
                                       [show]="eChartGraph['memory'].show"
                                       [pillActiveColour]="eChartGraph['memory'].dataSetsBackgroundColor"
                                       [defaultActivePillIndex]="0" [graphTitle]="eChartGraph['memory'].title"
                                       [arrayOfPills]="arrayOfDurationPills" [isPillTabToggleRequired]="true"
                                       (graphDataWithSeriesType)="getGraphDataForSeriesType($event)"></app-sys-stats-graph>
                </div>
              }
            </div>
          }

          @if (showCarStatus) {
            <div id="carStatus" class="tabcontent" style="padding: 12px 0">
              @if (showCarStatusGraphs) {
                <div>
                  <p class="TAC">{{ 'app.core.custom-modal.last-2-stats' | translate }}</p>
                  <app-car-status-graph [loader]="carDistanceMovedStats.distanceMoved.showLoader"
                                        [chartData]="carDistanceMovedStats.distanceMoved.chartData"
                                        [show]="carDistanceMovedStats.distanceMoved.show"
                                        [updateValue]="carDistanceMovedStats.distanceMoved.updateValue"
                                        [isSmallEChart]="false"></app-car-status-graph>
          <app-car-status-graph [loader]="boxStatusGraph.temperature.showLoader"
                                [chartData]="boxStatusGraph.temperature.chartData"
                                [show]="boxStatusGraph.temperature.show"
                                [updateValue]="boxStatusGraph.temperature.updateValue"></app-car-status-graph>
          <app-car-status-graph [loader]="boxStatusGraph.humidity.showLoader" [chartData]="boxStatusGraph.humidity.chartData"
                                [show]="boxStatusGraph.humidity.show"
                                [updateValue]="boxStatusGraph.humidity.updateValue"></app-car-status-graph>
                  <app-car-status-graph [loader]="carStats.speed.showLoader" [chartData]="carStats.speed.chartData"
                                        [show]="carStats.speed.show"
                                        [updateValue]="carStats.speed.updateValue"></app-car-status-graph>
                  <app-car-status-graph [loader]="carStats.rpm.showLoader" [chartData]="carStats.rpm.chartData"
                                        [show]="carStats.rpm.show"
                                        [updateValue]="carStats.rpm.updateValue"></app-car-status-graph>
                  <app-car-status-graph [loader]="carStats.fuelLevel.showLoader"
                                        [chartData]="carStats.fuelLevel.chartData" [show]="carStats.fuelLevel.show"
                                        [updateValue]="carStats.fuelLevel.updateValue"></app-car-status-graph>
                  <app-car-status-graph [loader]="carStats.cTemp.showLoader" [chartData]="carStats.cTemp.chartData"
                                        [show]="carStats.cTemp.show"
                                        [updateValue]="carStats.cTemp.updateValue"></app-car-status-graph>
                  <app-car-status-graph [loader]="carStats.aTemp.showLoader" [chartData]="carStats.aTemp.chartData"
                                        [show]="carStats.aTemp.show"
                                        [updateValue]="carStats.aTemp.updateValue"></app-car-status-graph>
                </div>
              }
              @if (carStatsNotFound) {
                <h3 class="asset-not-found">
                  <span>&ldquo;</span>{{ 'app.core.custom-modal.curr-data-na' | translate }}<span>&rdquo;</span>
                </h3>
              }
            </div>
          }

          @if (carLive && carLive['showCarLive']) {
            <div id="carLiveData" class="tabcontent">
              @if (isCarNotLinkedToAnyObdDevice) {
                <h3 class="asset-not-found">
                  <span>&ldquo;</span>{{ 'sys.no-live-data-avl' | translate }}<span>&rdquo;</span>
                </h3>
              }
              @if (!isCarNotLinkedToAnyObdDevice) {
                <div class="tab-content-innerGrid" style="height: 98%">
                  <div class="grid justify-content-between mx-0 my-2">
                    <div>
                      @if (arrayOfTypePills.length > 0) {
                        <app-iotzen-pills class="gauge-graph-toggle-btn"
                                          [pillActiveColour]="'#6D7FCC'"
                                          [defaultActivePillIndex]="defaultActivePillIndex"
                                          [arrayOfPills]="arrayOfTypePills"
                                          (activePillValue)="getActivePillValue($event)"
                                          [isPillTabToggleRequired]="true"></app-iotzen-pills>
                      }
                    </div>
                    <div class="live-update-time" pTooltip="{{'sys.last-updated-time' | translate}}"
                         tooltipPosition="right">{{ dateFormatter.convertDateToSelectedTimezone(liveUpdatedTime) }}
                    </div>
                  </div>
                  @if (carLive['selectedContentType'] === 'gauge') {
                    <div class="grid mt-2">
                      @if (_box.isboxStatusRequiredToShow && gaugeItemsToShowInRightSlider?.isBoxTempGaugeRequired) {
                        <div class="md:col-6 col-12 MB10">
                  <app-thermometer-gauge [min]="-30" [max]="30" [value]="boxTemperature" [graphTitle]="'Box Temperature in °C'" [resetRandomNumber]="resetCarMeterGaugeRandomNumber"></app-thermometer-gauge>
                  <!--<app-car-meter-gauge [rawGpsData]="rawGpsData" [carGaugeOptions]="coldChainBoxTempGaugeOptions" [resetRandomNumber]="resetCarMeterGaugeRandomNumber"></app-car-meter-gauge>-->
                </div>
                      }
                      @if (_box.isboxStatusRequiredToShow && gaugeItemsToShowInRightSlider?.isBoxOpenStatusGaugeRequired) {
                        <div class="md:col-6 col-12 MB10">
                  <div class="box-container card-border">
                    <div class="box-header">Box {{_box.boxOpenStatusText}}</div>
                    @if (_box.isBoxOpened) {
                      <img class="box-image" pTooltip="Box is {{_box.boxOpenStatusText}}" tooltipPosition="left"
                           src="../../assets/asset-icons/box-open.png" alt="Box open">
                    }
                    @if (_box.isBoxClosed) {
                      <img class="box-image" pTooltip="Box is {{_box.boxOpenStatusText}}" tooltipPosition="left"
                           src="../../assets/asset-icons/box-close.png" alt="Box close">
                    }
                  </div>
                </div>
                      }
                      @if (gaugeItemsToShowInRightSlider?.isObdGaugeRequired) {
                        <div class="md:col-6 col-12 MB10"
                             [ngStyle]="{'visibility': obdOdoMeterReading ? 'visible' : 'hidden'}">
                  <div class="display-card-container card-border">
                    <div class="display-card-body">
                      <h1 class="field-value">{{obdOdoMeterReading}} <span class="field-value-label">{{'sys.kms' | translate}}</span></h1>
                      <h6 class="field-label">{{'sys.odometer' | translate}}</h6>
                    </div>
                  </div>
                </div>
                      }
                      <!-- <div class="md:col-6 col-12 MB10">
                      <div class="card-deck" style="height: 300px">
                        <div class="card mb-3">
                          <div class="card-body">
                            <div class="card-text"><span class="field-label">Distance travelled: </span><span class="field-value">{{obdOdoMeterReading}} <span>kms</span></span></div>
                          </div>
                        </div>
                      </div>
                    </div>-->
                      @if (!carStatsNotFound && gaugeItemsToShowInRightSlider?.isSpeedGaugeRequired) {
                        <div class="md:col-6 col-12 MB10">
                          <app-car-meter-gauge [rawGpsData]="rawGpsData" [carGaugeOptions]="carSpeedGaugeOptions"
                                               [resetRandomNumber]="resetCarMeterGaugeRandomNumber"></app-car-meter-gauge>
                        </div>
                      }
                      @if (!carStatsNotFound && gaugeItemsToShowInRightSlider?.isRmpGaugeRequired) {
                        <div class="md:col-6 col-12 MB10">
                          <app-car-meter-gauge [rawGpsData]="rawGpsData" [carGaugeOptions]="carRpmGaugeOptions"
                                               [resetRandomNumber]="resetCarMeterGaugeRandomNumber"></app-car-meter-gauge>
                        </div>
                      }
                      @if (!carStatsNotFound && gaugeItemsToShowInRightSlider?.isFuelGaugeRequired) {
                        <div class="md:col-6 col-12 MB10">
                          <app-car-meter-gauge [rawGpsData]="rawGpsData" [carGaugeOptions]="carFuelGaugeOptions"
                                               [resetRandomNumber]="resetCarMeterGaugeRandomNumber"></app-car-meter-gauge>
                        </div>
                      }
                      @if (!carStatsNotFound && gaugeItemsToShowInRightSlider?.isCoolentTempGaugeRequired) {
                        <div class="md:col-6 col-12 MB10">
                          <app-thermometer-gauge [value]="coolantTemperature" [graphTitle]="'Coolant Temperature'"
                                                 [resetRandomNumber]="resetCarMeterGaugeRandomNumber"></app-thermometer-gauge>
                        </div>
                      }
                    </div>
                  }
                  @if (carLive['selectedContentType'] === 'graph') {
                    <div>
                      @if (showCarLiveDataGraphs) {
                        <div>
                          <app-car-status-graph [loader]="carStats.speed.showLoader"
                                                [chartData]="carStats.speed.chartData" [show]="carStats.speed.show"
                                                [updateValue]="carStats.speed.updateValue"></app-car-status-graph>
                          <app-car-status-graph [loader]="carStats.rpm.showLoader" [chartData]="carStats.rpm.chartData"
                                                [show]="carStats.rpm.show"
                                                [updateValue]="carStats.rpm.updateValue"></app-car-status-graph>
                          <app-car-status-graph [loader]="carStats.fuelLevel.showLoader"
                                                [chartData]="carStats.fuelLevel.chartData"
                                                [show]="carStats.fuelLevel.show"
                                                [updateValue]="carStats.fuelLevel.updateValue"></app-car-status-graph>
                          <app-car-status-graph [loader]="carStats.cTemp.showLoader"
                                                [chartData]="carStats.cTemp.chartData" [show]="carStats.cTemp.show"
                                                [updateValue]="carStats.cTemp.updateValue"></app-car-status-graph>
                          <app-car-status-graph [loader]="carStats.aTemp.showLoader"
                                                [chartData]="carStats.aTemp.chartData" [show]="carStats.aTemp.show"
                                                [updateValue]="carStats.aTemp.updateValue"></app-car-status-graph>
                        </div>
                      }
                    </div>
                  }
                  @if (carLive['selectedContentType'] === 'map') {
                    <div style="height:94%">
                      <app-map-events-actions [showMap]="showMap" [options]="mapOptions" [showMarker]="true"
                                              [showGeofence]="false" [markers]="markers"
                                              [rawObdDeviceGpsData]="rawGpsData"
                                              [carDetails]="assetData" [trackedCarDetails]="assetData"
                                              [triggerFollowUnfollowAction]="triggerCarFollowAction">
                      </app-map-events-actions>
                    </div>
                  }
                  @if (((carStatsNotFound && !isCarNotLinkedToAnyObdDevice) || !_box.isboxStatusRequiredToShow)) {
                    <h3 class="asset-not-found"
                        [ngClass]="obdOdoMeterReading || coolantTemperature ? '' : 'asset-not-found-centerAligned' ">
                      <span>&ldquo;</span>{{ 'sys.no-live-data-avl' | translate }}<span>&rdquo;</span>
                    </h3>
                  }
                </div>
              }
            </div>
          }

          @if (showCarStatus) {
            <div id="carEventData" class="tabcontent">
              @if (showCarEventComponent) {
                <div style="height: 90%">
                  <div class="grid mx-0 mt-1">
                    <div class="input-outer-grid PR" style="margin: 0px 5px">
                      <p-multiSelect [options]="carEvents['types'] | keyvalue" [(ngModel)]="selectedEventObjectArray"
                                     (onChange)="getSelectedEvent($event)" [style]="{'width':'100%'}"
                                     placeholder="{{'sys.events' | translate}}" [optionLabel]="'key'"
                      ></p-multiSelect>
                    </div>
                    @if (selectedEventObjectArray.length > 0) {
                      <div class="input-outer-grid PR" style="margin: 0px 10px">
                        <p-multiSelect [style]="{'width': '100%'}" [showHeader]="false" optionGroupChildren="status"
                                       [options]="selectedEventStatusArray" [group]="true"
                                       [(ngModel)]="selectedStatusList"
                                       defaultLabel="{{'sys.status' | translate}}" scrollHeight="250px" display="chip"
                                       optionGroupLabel="key">
                        </p-multiSelect>
                      </div>
                    }
                  </div>
                  <!--            <app-car-event-table [dataSource]="carEventsArray" [showDataNotAvailable]="carEventDataNotAvailable" [isLongTableRequired]="false"-->
                  <!--                                 [updateValue]="carEventDataLatestPush" [isCarSelected]="isCarSelectedToShowEvents"></app-car-event-table>-->
                  <app-car-event-table [selectedEventList]="selectedEventList" [selectedStatusList]="selectedStatusList"
                                       [searchedCar]="assetData"
                                       [analyticsData]="analyticsData" [isLongTableRequired]="true"
                                       [isCarSelected]="isCarSelectedToShowEvents"
                                       (showLoaderEvent)="showLoaderEvent($event)"
                                       [userMapOptions]="(mapTiles$ | async)">
                  </app-car-event-table>
                </div>
              }
            </div>
          }

          @if (cameraLive.showCameraLive && ACTIONS.LIVE |able: SUBJECT.ASSET) {
            <div id="cameraLive" class="tabcontent">
              @if (cameraLive.isActive) {
                <app-video-feed [nameSpace]="assetData?._id" [camera]="assetData"></app-video-feed>
              }
              <!--<video width="100%" height="auto" autoplay preload="auto" *ngIf="cameraLive.isActive">
              <source src="../../../assets/video/officeCAm_rec_2.mp4" type="video/mp4">
            </video>-->
            </div>
          }

          @if (serverLive.show) {
            <div id="serverLive" class="tabcontent">
              @if (serverLive.isActive) {
                <app-server-live-stats [assetData]="assetData"></app-server-live-stats>
              }
            </div>
          }

        </div>

        @if (showCircularLoader) {
          <div>
            <app-circular-loader></app-circular-loader>
          </div>
        }
        <app-groups-for-adding [isGetGroupsInitiated]="_groupVariables.isGetGroupsInitiated" [assetData]="assetData"
                               (addGroupDetailsToSlider)="addGroupDetails($event)"></app-groups-for-adding>
      </div>

    <div class="messagePopup actionMessagePopup">
      {{ actionMessage }}
    </div>
    <!--  Modal To display Profile Photo  -->
    <!-- <div class="modal fade" id="profileImgModal" tabindex="-1" role="dialog" *ngIf="assetData && (isprofilePhotoAccepted || isThumbnailAccepted)">
    <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
      <div class="modal-content">
        <div class="modal-header sidebar-modal-header">
          <button type="button" class="close" (click)="hideProfileImgModal('#profileImgModal')"><span aria-hidden="true">&times;</span></button>
          <h5 class="modal-title" *ngIf="assetData && assetData.assetType">{{'sys.profile-image' | translate}}</h5>
        </div>

        <div class="modal-body">
          <div class="imgContainer">
            <img [src]="profileImageUrlInModel" alt="profile Photo">
          </div>
        </div>

      </div>
    </div>
    </div> -->

    <p-toast position="top-center"></p-toast>

    <!-- download file custo modal -->
    <p-dialog [header]="modal_options.modalHeader" [(visible)]="modal_options.display" [modal]="true"
              class="qr-file-modal" [baseZIndex]="10000"
              [draggable]="false" [resizable]="false">

      @if (modal_options.modalContent === modal_content['FILES']) {
        <div>
          @if (file_list.length > 0) {
            <div>
              @for (file of file_list; track file) {
                <button pButton (click)="downloadSampleFile(file._id)" class="file-list">
                  {{ transformFileName(file.name) }}
                </button>
              }
            </div>
          }
          @if (!(file_list.length > 0)) {
            <div>
              {{ 'sys.no-data-available' | translate }}
            </div>
          }
        </div>
      }

      @if (modal_options.modalContent === modal_content['QRCODE']) {
        <div>
          <div class="qr-image-container w-full flex justify-content-center">
        <span>
          @if (qrCode.data) {
            <img class="reciept-img" src="data:image/jpg;base64,{{qrCode.data}}">
          } @else {
            <div>
              {{ 'sys.no-data-available' | translate }}
            </div>
          }
        </span>
            <!-- <div *ngIf="qr_list.length> 0">
            <div *ngFor="let qr of qr_list" class="image-upload-container border">
              <img src="" alt="image" width="60" height="60">
            </div>
          </div>
          <div *ngIf="!(qr_list.length> 0)" >
            {{'sys.no-data-available' | translate}}
          </div> -->
          </div>
        </div>
      }

    </p-dialog>
  </div>
</p-sidebar>
<!-- </ng-container> -->
<!-- ---------------------------------- -->
<!-- toast -->


<!-- prime card clickable -->
@if (isCardClickable) {
  <p-card (click)="editAssetDetails(assetData)"
          [ngClass]="{'cardHeightFixed':isCardHeightFixed === true, 'cardClickable':isCardClickable === true, 'mb-3': true,
           'archived': assetData?.archived === true, 'active': ((selectedId === assetData['_id']) && splitScreenIsActive)}"
          [style]="{'margin-bottom': '2rem', 'overflow': 'hidden'}" class="p-thing-card">
    <ng-template pTemplate="header">
      <div class="card-header  flex-parent  justify-content-between align-items-center">
        <div>
          @if (showStatusOnAssetCard) {
            <div>
              <div [ngClass]="assetData.running ? 'success' : 'fail'" class="workingStatus"></div>
            </div>
          }
          @if (assetData.assetType) {
            <div
              [pTooltip]="getLangLabel(assetLabel)"
              class="flex-child card-header-text long-and-truncated card-title TTC  p-card-heading"
              tooltipPosition="left">{{ getLangLabel(assetLabel) }}
            </div>
          }
          @if (assetData.serviceType) {
            <div
              [pTooltip]="getLangLabel(serviceLabel)"
              class="flex-child  card-header-text long-and-truncated card-title TTC  p-card-heading"
              tooltipPosition="left">{{ getLangLabel(serviceLabel) }}
            </div>
          }
        </div>
        <!-- image container -->
        @if (imageDetails['imageUrl']) {
          <div class="flex-child short-and-fixed">
            @if (imageDetails['imageUrl']) {
              <p-avatar styleClass="avatar-img"
                        [image]="imageDetails['imageUrl']" size="xlarge" shape="circle"></p-avatar>
            }
            @if (isprofilePhotoAccepted && !(imageDetails['imageUrl'])) {
              <p-avatar
                [image]="assetService.getRegisteredProfilePhoto(assetData._id)" size="xlarge" shape="circle"></p-avatar>
            }
          </div>
        }
      </div>
    </ng-template>
    <div class="card-body p-0 pb-2">
      @for (fields of fieldsToBeDisplayed; track fields) {
        <div class="card-text mb-2">
          <span class="field-label" pTooltip={{getLangLabel(fields.label)}}
                tooltipPosition="left">{{ fields.label | translate }}
            <span class="colon">:</span>
          </span>
          @if (fields.type == 'colorPicker') {
            <span class="color-cls" pTooltip={{assetData[fields.field]}}><div
              [ngStyle]="{'background':assetData[fields.field]}"></div></span>
          }
          @if (fields.type != 'colorPicker') {
            <span class="field-value" pTooltip={{assetData[fields.field]}}
                  tooltipPosition="left">{{ assetData[fields.field] }}</span>
          }
        </div>
      }
      @if (assetData.stats) {
        <span>
          <app-server-details-card [assetData]="assetData"></app-server-details-card>
        </span>
      }
      @if (showGroupBtn) {
        <app-group-badge [assetDataGroups]="assetData.groups"></app-group-badge>
      }
      @if (assetData.sysName) {
        <span pTooltip="Host : {{assetData.sysName}}" tooltipPosition="above"
              class="sysName lighterColor">{{ assetData.sysName }}</span>
      }
    </div>
    <!-- footer -->
    <ng-template pTemplate="footer">
      <div class="grid justify-content-between p-card-footer-container">
        @if ((assetLinkedDetails && (assetLinkedDetails | json) != '{}')) {
          <div class="linked-asset-count-container col-8 pr-0">
            <app-asset-linked-count [serviceAssetConfigDetails]="serviceAssetConfigDetails"
                                    [assetLinkedDetails]="assetLinkedDetails"
                                    [assetConfigDetails]="assetConfigDetails"></app-asset-linked-count>
          </div>
        }
        @if (assetData.updated) {
          <div class="date text-right mt-1 lighterColor"
               [ngClass]="(assetLinkedDetails && (assetLinkedDetails | json) != '{}') ? 'col-4' : 'col-12'">
            <span class="dotsOnOverflow"
                  pTooltip="{{'sys.last-updated' | translate}} : {{dateFormatter.convertDateToSelectedTimezone(assetData.updated)}}"
                  tooltipPosition="above">{{ dateFormatter.convertDateToSelectedTimezone(assetData.updated) }}</span>
          </div>
        }
      </div>
    </ng-template>
  </p-card>
  @if (assetData?.archived) {
  <p-tag class="archived-text" severity="danger" value="Archived" />
  }
}

<!-- ------------------------ -->


<!-- - ------------------------------------------------------------------------------------------------------------------------------------>

<!--card Not Clickable-->
<!-- prime card not clicable  -->
@if (!isCardClickable) {
  <p-card [style]="{'margin-bottom': '2rem'}"
          [ngClass]="{'cardHeightFixed':isCardHeightFixed === true, 'cardClickable':isCardClickable === true,
          'not-clickable-archived': assetData?.archived === true, 'active': (assetData['cardClicked'] || assetData['isAssetSelected'])}"
          (click)="selectedAssetsForLinking()" pTooltip="{{isLinkable ? isLinkableTooltipValue : ''}}"
          tooltipPosition="right">
    <ng-template pTemplate="header">
      <div class="card-header flex justify-content-between align-items-center">
        <div class="flex  align-items-center">
          @if (showStatusOnAssetCard) {
            <div>
              <div [ngClass]="assetData.running ? 'success' : 'fail'" class="workingStatus"></div>
            </div>
          }
          <!--  card Header-->
          @if (assetData.assetType) {
            <div [pTooltip]="getLangLabel(assetLabelToolTip)"
                 class="card-title TTC card-header-text" tooltipPosition="left">{{ getLangLabel(assetLabel) }}
            </div>
          }
          @if (assetData.serviceType) {
            <div [pTooltip]="getLangLabel(serviceLabelToolTip)"
                 class="card-title TTC card-header-text"
                 tooltipPosition="left">{{ getLangLabel(serviceLabel) }}
            </div>
          }
        </div>
        <!-- {{ imageDetails['imageUrl']  | json}} -->
        <!-- image container -->
        @if (imageDetails['imageUrl'] || isprofilePhotoAccepted) {
          <div>
            @if (imageDetails['imageUrl']) {
              <p-avatar styleClass="avatar-img"
                        [image]="imageDetails['imageUrl']" size="xlarge" shape="circle"></p-avatar>
            }
            @if (isprofilePhotoAccepted && (imageDetails['imageUrl'] !== null)) {
              <p-avatar
                [image]="assetService.getRegisteredProfilePhoto(assetData._id)" size="xlarge" shape="circle"></p-avatar>
            }
          </div>
        }
      </div>
    </ng-template>
    <div class="card-body p-0 pb-2">
      @for (fields of fieldsToBeDisplayed; track fields?.label) {
        <div class="card-text my-1 mb-2">
          <span class="field-label" pTooltip={{getLangLabel(fields.label)}}
                tooltipPosition="left">{{ getLangLabel(fields.label) }}
            <span class="colon">:</span>
          </span>
          <span class="field-value" pTooltip={{assetData[fields.field]}}
                tooltipPosition="left">{{ assetData[fields.field] ? assetData[fields.field] : '-' }}</span>
        </div>
      }
      @if (showGroupBtn) {
        <app-group-badge [assetDataGroups]="assetData.groups"></app-group-badge>
      }
      @if (assetData.sysName) {
        <span class="sysName lighterColor" pTooltip="Host : {{assetData.sysName}}"
              tooltipPosition="above">{{ assetData.sysName }}</span>
      }
    </div>
    <!-- footer -->
    <ng-template pTemplate="footer">
      <div class="grid justify-content-between  p-card-footer-container">
        @if ((assetLinkedDetails && (assetLinkedDetails | json) != '{}')) {
          <div class="linked-asset-count-container col-6 pr-0">
            <app-asset-linked-count [assetLinkedDetails]="assetLinkedDetails"
                                    [assetConfigDetails]="assetConfigDetails"></app-asset-linked-count>
          </div>
        }
        @if (assetData.updated) {
          <div class="date text-right mt-1 lighterColor"
               [ngClass]="(assetLinkedDetails && (assetLinkedDetails | json) != '{}') ? 'col-6' : 'col-12'">
            <span class="dotsOnOverflow"
                  pTooltip="{{'sys.last-updated' | translate}}: {{dateFormatter.convertDateToSelectedTimezone(assetData.updated)}}"
                  tooltipPosition="above">{{ dateFormatter.convertDateToSelectedTimezone(assetData.updated) }}</span>
          </div>
        }
      </div>
      @if (showCloseBtn && !isAssetDetailsInReadMode) {
        <span class="linkBtn" (click)="closeBtnClicked()"
               pTooltip="{{'sys.delink-asset' | translate}}"
              tooltipPosition="left">
          {{ 'sys.delink-asset' | translate }} <i class="pi pi-question-circle" style="font-size: 1.2rem"></i></span>
      }
      @if (showDelinkServiceBtn && !isAssetDetailsInReadMode) {
        <span class="linkBtn" (click)="delinkServiceBtnClicked()"
              pTooltip="{{'sys.sdelink-service' | translate}}"
              tooltipPosition="left">
            {{ 'sys.sdelink-service' | translate }} <i class="pi pi-question-circle"
                                                       style="font-size: 1.2rem"></i></span>
      }
        </ng-template>
  </p-card>
  @if (assetData?.archived) {
    <p-tag class="archived-text" severity="danger" value="Archived" />
  }
}

<!-- --------- -->


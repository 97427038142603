import { createAction, props } from "@ngrx/store";
import { IAssignmentWindowApiResonse, IOBDSettings, IRoutePlanSettings } from "../../app/delivery-managenment-system/services/common.service";
import {Field, OrderSettings, Processor, Slot} from '../Settings/interfaces/orderSettings.interface'
import { Section } from "../Settings/interfaces/orderSettings.interface";
import { IAllStatusOptions, IConsignmentAssignForm, INotifyRecipientOptions, IProgressSettings, IQueueSetting, IReportSetting, IStatusesAllowed } from "./interfaces/lmdMmdSettings.interface";
import { IProcessorList } from "./settings.service";
import { IMMDFormMetaData, ILMDFormMetaData } from "./interfaces/mmd-formSettings.interface";
import { IDMSAllSettingsWithModule, IDMSMetaDataSettings,IGeofenceSetting, IGroupsOfDMSSettingsWithModuleSettings } from "./interfaces/Interface-processor";
//import { RoutePlanCategory } from "../../app/delivery-managenment-system/middle-mile-delivery/interfaces/mmd-interfaces";

export const getDefaultTableSettingsForAnAccount = createAction(
    '[Effects Settings API] fetch table default settings'
)

export const onDefaultTableSettingsForAnAccountFetchSuccuss = createAction(
    '[Effects Settings API] on table default settings succuss',
    props<{ settings: OrderSettings }>()
)

export const onDefaultTableSettingsForAnAccountFetchFailure = createAction(
    '[Effect API] on table default settings failure API request',
    props<{err:string}>()
)

export const setO2DFormSectionSetting = createAction(
    "[O2D Setting Effect] put form api",
    props<{payload: Section[]}>()
)

export const setO2DTableFieldSetting = createAction(
    "[O2D Setting Effect] put form api for table-field",
    props<{payload: Field[]}>()
)

export const setO2DFormSectionSettingSuccess = createAction(
    "[O2D Setting Effect] put form api success",
    props<{msg: string,payload:Section[]}>()
)
export const setO2DTableFieldSettingSuccess = createAction(
    "[O2D Setting Effect] put form api success in table field",
    props<{msg: string,payload:Field[]}>()
)

export const setO2DSlotSetting = createAction(
    "[O2D Setting Effect] put form api for slot-settings",
    props<{payload: Slot[]}>()
)

export const setO2DSlotSettingSuccess = createAction(
    "[O2D Setting Effect] put form api success in slot-settings",
    props<{msg: string,payload:Slot[]}>()
)

export const getNotifyRecipientOptions = createAction(
    "[lmd/mmd Notify Recipient] get options for notify recipient setting",
    props<{moduleType : string}>()
)

export const getNotifyRecipientOptionsSuccess = createAction(
    "[lmd/mmd Notify Recipient] get options for notify recipient setting successfully",
    props<{res : IAllStatusOptions,moduleType : string}>()
)

export const getNotifyRecipientOptionsFailure = createAction(
    "[lmd/mmd Notify Recipient] get options for notify recipient setting failure",
    props<{err:Error}>()
)

export const updateNotifyRecipientOptions = createAction(
    "[lmd/mmd Notify Recipient] update  options for notify recipient setting",
    props<{moduleType: string, data : INotifyRecipientOptions}>()
)
export const updateNotifyRecipientOptionsSuccess = createAction(
    "[lmd/mmd Notify Recipient] update options for notify recipient setting updated Successfully",
    props<{res:INotifyRecipientOptions,moduleType : string}>()
)
export const getReportSetting = createAction(
    "[lmd/mmd Report Setting] get settings for report",
    props<{moduleType : string}>()
)
export const getReportSettingSuccess = createAction(
    "[lmd/mmd Report Setting] get settings for report Successfully",
    props<{res:IReportSetting,moduleType : string}>()
)
export const updateReportSetting = createAction(
    "[lmd/mmd Report Setting] update settings for report setting",
    props<{moduleType: string, data : any}>()
)
export const updateReportSettingSuccess = createAction(
    "[lmd/mmd Report Setting] update settings for report setting successfully",
    props<{res:IReportSetting,moduleType : string}>()
)
export const getQueueSettingsByModule = createAction(
    "[lmd/mmd queue Setting] get queue settings",
    props<{moduleType : string, queue : string}>()
)
export const getQueueSettingsByModuleSuccess = createAction(
    "[lmd/mmd queue Setting] get queue settings successfully",
    props<{res:IQueueSetting,moduleType : string}>()
)
export const updateQueueSetting = createAction(
    "[lmd/mmd Queue Setting] update settings for queue setting",
    props<{moduleType: string, payload : IQueueSetting}>()
)
export const updateQueueSettingSuccess = createAction(
    "[lmd/mmd Queue Setting] update settings for queue setting successfully",
    props<{res:IQueueSetting,moduleType : string}>()
)
export const getRoundTripSetting = createAction(
    "[lmd/mmd Round Trip Setting] get round trip settings",
    props<{moduleType : string}>()
)
export const getRoundTripSettingSuccess = createAction(
    "[lmd/mmd Round Trip Setting] get round trip settings successfully",
    props<{res, moduleType : string}>()
)
export const updateGetRoundTripSetting = createAction(
    "[lmd/mmd Round Trip Setting] update settings for round trip setting",
    props<{moduleType: string, payload : IRoutePlanSettings}>()
)
export const updateGetRoundTripSettingSuccess = createAction(
    "[lmd/mmd Round Trip Setting] update settings for round trip setting successfully",
    props<{res ,moduleType : string}>()
)
export const getObdSetting = createAction(
    "[lmd/mmd Obd Setting] get Obd settings",
)
export const getObdSettingSuccess = createAction(
    "[lmd/mmd Obd Setting] get Obd settings successfully",
    props<{res:IOBDSettings}>()
)
export const updateObdSetting = createAction(
    "[lmd/mmd Obd Setting] update settings Obd setting",
    props<{payload: IOBDSettings}>()
)
export const updateObdSettingSuccess = createAction(
    "[lmd/mmd Obd Setting] update settings for Obd setting successfully",
    props<{res:IOBDSettings}>()
)
export const getAssignmentWindownSettings = createAction(
    "[mmd Assignment Window Setting] get assignment window settings",
    props<{moduleType: string}>()

)
export const getFormSettingsByModuleType = createAction(
    "[settings effects] get form settings",
    props<{moduleType: string}>()
)
export const setFormSettingsByModuleType = createAction(
    "[settings Store] set form settings",
    props<{moduleType: string, settings: IConsignmentAssignForm}>()
)

export const putFormSettingsByModuleType = createAction(
    "[settings effects] put form settings",
    props<{moduleType: string,payload:IConsignmentAssignForm}>()
)

export const getAssignmentWindownSettingsSuccess = createAction(
    "[mmd Assignment Window Setting] get assignment window settings successfully",
    props<{res:IAssignmentWindowApiResonse}>()
)
export const updateAssignmentWindownSettings = createAction(
    "[mmd Assignment Window Setting] update settings assignment window settings",
    props<{moduleType : string , payload: IAssignmentWindowApiResonse}>()
)
export const updateAssignmentWindownSettingsSuccess = createAction(
    "[mmd Assignment Window Setting] update settings for assignment window setting successfully",
    props<{res:IAssignmentWindowApiResonse}>()
)


export const getAllProcessorsSettingO2D = createAction('[O2D] get all procesoor list')

export const getAllProcessorsSettingO2DSuccess = createAction('[O2D] get all procesoor list success', props<{ processorList: IProcessorList[] }>())

export const putProcessorSettings = createAction('[O2D] put processor list', props<{ payload: Processor }>())


export const getFormMetadata = createAction('[MMD] get form meta data api')

export const setFormMetadata = createAction('[MMD] set form meta data', props<{ formMetaData: IMMDFormMetaData }>())

export const getLMDFormMetadata = createAction('[LMD] get LMD form meta data api')

export const setLMDFormMetadata = createAction('[LMD] set LMD form meta data', props<{ formMetaData: ILMDFormMetaData }>())

export const getProgressSettingsByModuleType = createAction(
    '[generic settings] get progress data', 
    props<{ moduleType: string }>());

export const setProgressSettingsByModuleType = createAction(
    '[generic settings] get progress data success',
    props<{moduleType:string,settings:IProgressSettings}>()
)



export const putProgressSettingsByModuleType = createAction(
    '[generic settings] set progress data', 
    props<{payload:IProgressSettings, moduleType: string }>()
)

export const getMetaDataForDMSSettings = createAction('[DMS] get meta data for dms settings',props<{moduleType:string,groupType?:string}>())
export const getMetaDataForDMSSettingsSuccess = createAction('[DMS] get meta data for dms settings success',props<{moduleType:string,settings:IDMSMetaDataSettings,groupType:string}>())

export const getAllDMSSettingsWithModuleType = createAction('[DMS] get all dms settings with module type',props<{moduleType:string}>())

export const getAllDMSSettingsWithModuleTypeSuccess = createAction('[DMS] get all dms settings with module type success',props<{moduleType:string,settings:IDMSAllSettingsWithModule}>())

export const putAllDMSSettingsWithModuleType = createAction('[DMS] put dms settings with module type and path',props<{moduleType:string,path:string,payload:unknown}>())

export const putAllDMSSettingsWithModuleTypeSuccess = createAction('[DMS] put dms settings with module type and path success',props<{moduleType:string,path:string,payload:unknown}>())

export const putGeofenceSettingsByAssetType = createAction(
    '[geofence settings] put geofence data', 
    props<{payload:IGeofenceSetting ,module:string,typeId:string}>()
)

export const putGeofenceSettingsByAssetTypeSuccess = createAction(
    '[geofence settings] put geofence data success', 
    props<{payload:IGeofenceSetting}>()
)
export const getGeofenceSettingsByAssetType = createAction(
    '[geofence settings] get geofence data',props<{accountId:string}>()
)

export const getGeofenceSettingsByAssetTypeSuccess = createAction(
    '[geofence settings] get geofence data success', 
    props<{payload:IGeofenceSetting}>()
)

export const getGroupsOfDMSSettingsByModule = createAction(
    '[generic settings] get groups of settings data',props<{module:string}>()
)

export const getGroupsOfDMSSettingsByModuleSuccess = createAction(
    '[generic settings]  get groups of settings data success', 
    props<{res:IGroupsOfDMSSettingsWithModuleSettings[],module:string}>()
)
export const errorHandlerForGenricDMSSetting = createAction('[DMS] error handler for generic settings')

// Generic State Actions For Settings 

export const getAllSettingsForSelectedModule = createAction('[SETTINGS] get all  data settings for selected module',props<{module:string}>())

export const getAllSettingsForSelectedModuleSuccess = createAction('[SETTINGS] get all  data settings for selected module success',props<{res:IDMSAllSettingsWithModule,module:string}>())

export const getMetaDataSettingsForSelectedModule = createAction('[SETTINGS] get  Generic ,metadata settings for selected module',props<{module:string,groupType:string}>())

export const getMetaDataSettingsForSelectedModuleSuccess = createAction('[SETTINGS] get  Generic ,metadata settings for selected module success',props<{res:IDMSMetaDataSettings,groupType:string,module:string}>())

export const getGroupTypesForSelectedModule = createAction('[SETTINGS] get settings  group types for selected module',props<{module:string}>())

export const getGroupTypesForSelectedModuleSuccess = createAction('[SETTINGS] get settings  group types for selected module success',props<{res:IGroupsOfDMSSettingsWithModuleSettings[],module:string}>())

export const updateAllSettingsForSelectedModule= createAction('[SETTINGS] update settings with  types for selected module',props<{payload:unknown,typeId:string,module:string}>())

export const updateAllSettingsForSelectedModuleSuccess= createAction('[SETTINGS] update settings with types for selected module success',props<{payload:unknown,typeId:string,module:string}>())


export const getAllowedStatusesSettingsByModule = createAction(
    '[generic settings] get allowed statuses settings data',
    props<{moduleType:string}>()
)

export const getAllowedStatusesSettingsByModuleSuccess = createAction(
    '[generic settings] get allowed statuses settings data sucess',
    props<{res:IStatusesAllowed[],moduleType:string}>()
)

export const getRoutePlanCategory = createAction('[MMD] get route plan category list')

export const getRoutePlanCategorySuccess = createAction('[MMD] get route plan category list success', props<{ routePlanCategory: string[]}>())

export const getRoutePlanCategoryFailure = createAction(
  "[MMD] get route plan category list failure",
  props<{ err: Error }>()
)

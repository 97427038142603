/**
 * Created by chandru on 19/9/18.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from "./config.service";

@Injectable()
export class ValidityCheckService {
  constructor(private http: HttpClient, public configService: ConfigService) {}
  private _validityMessage: string = '';

  getValidityStatus() {
    return this.http.get(this.configService.appConfig.loginBaseURL + '/licence/validity');
  }
  getValidityMessage(): string {
    return this._validityMessage;
  }
  setValidityMessage(message: string) {
    this._validityMessage = message;
  }

}

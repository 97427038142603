import {Component, OnInit, Input, OnChanges} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-group-badge',
  templateUrl: './group-badge.component.html',
  styleUrls: ['./group-badge.component.scss']
})
export class GroupBadgeComponent implements OnChanges, OnInit {
  @Input() assetDataGroups: any;
  @Input() removeFixedGroupListHeight: boolean;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.removeFixedGroupListHeight === true) {
      $('.groupList').css('height', 'auto');
    }
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {Ability} from '@casl/ability';

@Injectable()
export class AuthGuard {


  constructor(private router: Router, private ability: Ability) {
  }

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const token = sessionStorage.getItem('token');
    if (!token) {
      this.router.navigate(['login']);
      return false;
    }
    const auth = next.data['auth'];
    if (!auth || this.ability.can(auth.action, auth.subject)) {
      return true;
    }
    this.router.navigate(['forbidden']);
    return false;
  }

}

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AssetService} from '../../../services/asset.service';
import {GeofenceService} from '../../../services/geofence.service';
import {IotzenPillType} from '../iotzen-pills/iotzen-pill-type';
import L, {
  circleMarker,
  control,
  divIcon,
  icon,
  latLng,
  LatLng,
  Layer,
  layerGroup,
  Map,
  marker,
  Marker,
  Polygon,
  polygon,
  polyline,
  Polyline,
  tileLayer
} from 'leaflet';
import {clone, cloneDeep, find, findIndex, floor, forEach, groupBy, isEmpty, keys, map, orderBy} from 'lodash';
import moment from 'moment-timezone';
import {LangUtilService} from '../../util/lang-util.service';
import {DateFormatterService} from '../../../services/dateformat';
import {OrderStatusColorValuesService} from '../../../services/order-status-color-values.service';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import {CONSTANT} from '../../../config/constant';
import {IMapUserOptions} from '../../../state/User/interfaces/IUserMap';
import {handleMapResize} from '../../../state/User/user.utilityService';

declare let $: any;


@Component({
  selector: 'app-map-events-actions',
  templateUrl: './map-events-actions.component.html',
  styleUrls: ['./map-events-actions.component.scss']
})
export class MapEventsActionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() moduleType: any;
  @Input() loadCarriers: any;

  public mapDetails: IMapUserOptions;
  public selectedMap: any;
  public selectedZoom: number;
  public selectedLatitude: number;
  public selectedLongitude: number;
  public center: any;
  public customZoom: number;
  intervalTimeInMinutesForCheckingOffline = 3 ; // in minutes
  minutesAfterDeviceIsOffline = 3 ; // in minutes
  maintenanceDue = {
    value: null,
    isServiceNotRequired: false,
    isServiceModerate: false,
    isServiceRequired: false,
    isServiceBadlyRequired: false
  };
  faultCodes = {
    code: null,
    description: null
  };
  newDataForPolylineCount = 0;
  carTrackingLocationArray: Array<any> = [];
  @Input() trackedCarDetails: any;
  searchedCar: any = null;

  eventsMarkers = {
    Ignition: {
      On: [],
      Off: []
    } ,
    'Harsh Event': {
      'Harsh Braking': [] ,
      'Harsh Acceleration': [] ,
      'Harsh Cornering': []
    }
  };
  eventMarkersPlotted: Marker[] = [];

  private url: string;
  private option: any;
  mapToView: any;

  markersValueWithDeviceId = {};
  allTrackableCars: Array<any> = [];
  deviceToBeTracked: string ;
  polyLine: Polyline ;
  @Input() carDetails: any;
  @Input() lastMarkerClickedLocation: LatLng;
  selectedCarLiveGpsData: any = null;
  previousDateForLinePlotting: Date = new Date();

  @Input() showMap: boolean;
  @Input() options: any;
  @Input() markers: Layer[] = [];
  @Input() map: Map;
  @Input() showMarker: boolean;
  @Input() showEventMarkers: boolean;
  @Input() triggerFollowUnfollowAction: number;
  @Input() triggerMapReinitializeAction: number;
  @Input() rawObdDeviceGpsData: any;
  @Input() rawCarEventData: any;

  @Input() userMapOptions: IMapUserOptions

  @Output() markerClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() deviceToBeTrackedEvent: EventEmitter<any> = new EventEmitter();
  @Output() markersValueWithDeviceIdEvent: EventEmitter<any> = new EventEmitter();
  // ------------------------------


  intervalForCheckingForOfflineCarsId = null;
  trackableCarsListForSidePanel: Array<any> = [];
  public carStatusType = 'online';
  _carStatusPills: {
    list: Array<IotzenPillType>;
    selectedPillId: string
  } = {
    list: [],
    selectedPillId: 'all'
  };
  _trackableCars = {
    all: [],
    online: [],
    offline: [],
  };
  _trackableCarsCounts = {
    all: 0,
    online: 0,
    offline: 0,
  };

  @Input() carStatusPillClickedAction: string;
  @Output() trackableCarsListForSidePanelEvent: EventEmitter<any> = new EventEmitter();
  @Output() trackableCarsListEvent: EventEmitter<any> = new EventEmitter();
  @Output() trackableCarsCountsEvent: EventEmitter<any> = new EventEmitter();



  // geoFence variables --------
  geoFences: Array<any> = [];
  carsInGeoFence:  AssetsInFenceElement[];
  geoFenceSelectedObject: any;
  isCarsInGeoFence: boolean;
  newGeoFencePolygonPoints: Array<LatLng> = [];
  newGeoFencePolygon: Polygon;
  @Input() showGeofence: boolean;
  @Input() geoFenceCardClickAction: number;
  @Input() selectedGeoFenceCardDetails: any;
  @Input() isAddGeoFenceClicked: boolean;
  @Input() geoFenceAssetCountArray: any[];
  @Input() pageName: string;

  @Input() addGeoFenceCancelBtnClickTrigger: number;
  @Input() addGeoFenceClearBtnClickTrigger: number;
  @Input() geoFenceAddOrDeleteSuccessTrigger: number;
  @Output() geoFenceClickEvent: EventEmitter<any> = new EventEmitter();
  @Output() newGeoFencePolygonEvent: EventEmitter<any> = new EventEmitter();

  constructor( private assetService: AssetService,
              public lang: LangUtilService, private geoFenceService: GeofenceService,

              public dateFormatter: DateFormatterService, private orderStatusColorService: OrderStatusColorValuesService)
              {
    this.mapToView = tileLayer(this.url, this.option);
    this.trackedCarDetails = null;
    this.showMarker = false;
    this.showGeofence = false;
    this.showEventMarkers = false;
    this.isCarsInGeoFence = false;
    this.carsInGeoFence = [];
    this.customZoom = 11;
    this.markers = [];
    this.options = {
      layers: [],
      zoom: 5,
      zoomControl: false,
      center: latLng(null, null)
    };
    this.moduleType = CONSTANT.MODULES.FLEET.MODULE;
  }

  //-- Order Route map -----
  @Input() ordersRouteToPlot: object;
  @Input() orderMarkers: any;
  route = polyline([]);
  agentRoute = polyline([]);
  agentRouteArray = [];
  newDataForAgentPolylineCount: number;

  allDeliveryMarkers = [];
  allDeliveryLocMarkers = {};

  routeArray = [];

  startMarkerLocation;

  // -------methods-------

  private plotPolyLine() {
    if(Object.keys(this.ordersRouteToPlot).length> 0){
      if (this.ordersRouteToPlot['route'] && this.ordersRouteToPlot['route'].latLngs) {
        this.routeArray = map(this.ordersRouteToPlot['route'].latLngs, function (data) {
          return new LatLng(data[0], data[1]);
        });
        this.route.remove();
      }

      if (this.route && this.route['_map']) {
        this.route.setLatLngs(this.routeArray);
        this.route.redraw();

      } else {
        this.route = polyline(this.routeArray, { 'weight': 4, 'color': '#1d3785', 'opacity': .9 }).addTo(this.map);
        this.map.addLayer(this.route);
      }
      setTimeout(()=>{
        if(this.route && this.routeArray.length > 0) {
          this.map.fitBounds(this.route.getBounds());
        }
      },500);
    }
    else{
      this.removeLayer()
    }

  }

  plotIcons(){
    let orders;
    let routes;

    if(this.ordersRouteToPlot['orders'] && this.ordersRouteToPlot['route']){
        orders = this.ordersRouteToPlot['orders']
        routes = this.ordersRouteToPlot['route']

      if(this.ordersRouteToPlot['route']  && this.ordersRouteToPlot['route'].geocoded_waypoints) {
        const location = routes.geocoded_waypoints[0].location;

        const pickedUpLocation = new LatLng(location[1], location[0]);

        const startLocation = pickedUpLocation;
        const startMarkerLocation = circleMarker(startLocation,
          {
            color: '#373fa3',
            fillColor: '#fff',
            fillOpacity: 0.8,
            radius: 15,
            weight: 3
          }
        );
        const icon = divIcon({
          html: `<div> <span class='leaflet-circle-marker-txt' style='color: #373fa3'>P </span> </div>`,
          className: '',
          iconSize: [40, 40]
        });

        const orderMarker = marker(startLocation, {
          icon: icon
        });
        orderMarker.bindTooltip('<div><B>Pick up location </B></div>').openTooltip();
        this.startMarkerLocation = layerGroup([startMarkerLocation,orderMarker])
        this.startMarkerLocation.addTo(this.map);

         this.allDeliveryMarkers.push(this.startMarkerLocation);
         this.map.setView(startLocation, this.selectedZoom)
        }
      const sortedOrders = orderBy(orders, ['deliverySequence'], ['asc']);
      let deliverySequence: number;
      if (routes.geocoded_waypoints) {
             routes.geocoded_waypoints.shift();
          routes.geocoded_waypoints.forEach((delivery, index: any) => {
          console.log('location,', delivery.location)
          deliverySequence = sortedOrders[index].deliverySequence;
            const deliveryLocation = new LatLng(delivery.location[1], delivery.location[0]);

            const detailsForOrderMaker = {
              status: sortedOrders[index].status,
              order: deliverySequence,
              orderData: sortedOrders[index],
            };

            const endMarkerLocation = this.circleWithOrderNumber(deliveryLocation, detailsForOrderMaker);
          endMarkerLocation.addTo(this.map);

          this.allDeliveryMarkers.push(endMarkerLocation);

          this.allDeliveryLocMarkers[deliverySequence] = {
            marker: endMarkerLocation,
            order: delivery
          };

        });
      }
    }
  }

  plotOrderIcons(){
    // let orders;
    // let routes;
    if(this.orderMarkers && this.orderMarkers.length>0){
      console.log('orderMarkers', this.orderMarkers);
      forEach(this.orderMarkers, m => {

        const lat = m.lat;
        const lng = m.lng;
        // const orderLocationId = m.name;
        const shortHand = m.shortHand;
        const mOptions = m.mOptions ? m.mOptions : {
          color: '#373fa3',
          fillColor: '#fff',
          fillOpacity: 0.8,
          radius: 15,
          weight: 3
        };

        if (lat && lng) {
          const startLocation = {lat, lng};
          const startMarkerLocation = circleMarker([lat, lng], mOptions);
          const icon = divIcon({
            html: `<div> <span class='leaflet-circle-marker-txt' style='color: ${mOptions.color}'>${shortHand} </span> </div>`,
            className: '',
            iconSize: [40, 40]
          });

          const orderMarker = marker(startLocation, {
            icon: icon
          });
          orderMarker.bindTooltip(m.details).openTooltip();
          this.startMarkerLocation = layerGroup([startMarkerLocation, orderMarker]);
            this.startMarkerLocation.addTo(this.map);
            this.allDeliveryMarkers.push(this.startMarkerLocation);
          }
          else{
          //  this.showInfo(`Location for Order ID ${orderLocationId} is Empty`)
          }
        }

    )
    }
  }

  private removeLayer() {
    this.route.remove();
    this.routeArray = [];
    this.allDeliveryMarkers.forEach(markerObj => {
      markerObj.remove();
    });
    this.allDeliveryMarkers = [];
    this.allDeliveryLocMarkers = {};

    this.resetAgentRouteVariables();
  }
  resetAgentRouteVariables() {
    // this.agentMarkerLocation.remove();
    this.agentRoute.remove();
    this.agentRouteArray = [];
    this.newDataForAgentPolylineCount = 0;
  }

  circleWithOrderNumber(latLng, markerConfig) {
    // const _this = this;
    const orderData = markerConfig.orderData;
    const namingConventionFilterPipe = new NamingConventionFilterPipe();


    const fillColor = this.orderStatusColorService.getStatusBackgroundColor(markerConfig.status);
    const textColor = this.orderStatusColorService.getStatusColor(markerConfig.status);

    const toolTipData = namingConventionFilterPipe.transform(orderData.status);

    const expectedDelData = this.dateFormatter.convertDateToSelectedTimezone(orderData.expectedDel.date, 'DD/MM/YY');
    const expectedDelTime = this.dateFormatter.getFormattedTimeSlot(orderData.expectedDel.slot);


    const circleOrderMarker = circleMarker(latLng,
      {
        color: textColor,
        fillColor: fillColor,
        fillOpacity: 0.8,
        radius: 15,
        weight: 3
      }
    );
    const icon = divIcon({
      html: `<div> <span class='leaflet-circle-marker-txt' style='color: ${textColor}'>${markerConfig.order} </span> </div>`,
      className: '',
      iconSize: [40, 40]
    });

    const orderMarker = marker(latLng, {
      icon: icon
    });

    const group = layerGroup([circleOrderMarker, orderMarker]);
    group.addTo(this.map)
    orderMarker.addTo(this.map)
    const orderSummary = `<div style='padding: 15px'>
      <div>
        <b>Order ${markerConfig.order}</b>
      </div>
      <div>
        <b>Order ID: </b>
        <span>${orderData.orderId}</span>
        </br>
        <b>Cust. Name: </b>
        <span>${orderData.customer.name}</span>
        </br>
        <b>Exp. Date: </b>
        <span> ${expectedDelData} - ${expectedDelTime}</span>
        </br>
        <b>Order Status: </b>
        <span style='border: 1px solid ${textColor}; color: ${textColor}; border-radius: 5px; padding: 3px; font-weight: bold'>${toolTipData}</span>
      </div>
    </div>`
    orderMarker.bindTooltip(orderSummary);
    return group;


  }


  //-- Order Route map -----
 trackIcons: any = {
   active: '../assets/leaflet-icons/marker-icon-active.png',
   online: '../assets/leaflet-icons/marker-icon.png',
   offline: '../assets/leaflet-icons/marker-icon-offline.png'
 };

  ngOnInit(): void {
    const _this = this;
    let assetType = '';
    this.getMapForSelectedUser();
      if (this.showMarker) {
        setTimeout(() => { _this.getAllTraceableCars(); }, 500)
       this.intervalForCheckingForOfflineCar();
     }

     if(this.moduleType) {
      switch (this.moduleType) {
        case CONSTANT.DMS_MODULES.MMD.MODULE:
          assetType = CONSTANT.ASSET_TYPES.VEHICLE;
          break;
        case CONSTANT.DMS_MODULES.LMD.MODULE:
          assetType = CONSTANT.ASSET_TYPES.DELIVERY_PERSON;
          break;
        default:
          break;
      }
     }

     this.assetService.getTrackIconsByModuleType(this.moduleType, assetType).subscribe(res => {
      if(res) {
        this.trackIcons = cloneDeep(res);
      }

     }, () =>{})
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'rawObdDeviceGpsData') {
        if (change.currentValue !== change.previousValue) {
          this.updateMarkerOnGpsData(this.rawObdDeviceGpsData);
        }
      }
      if (propName === 'rawCarEventData') {
        if (change.currentValue !== change.previousValue) {
          if (this.showEventMarkers) {
            this.handleCarEvent(this.rawCarEventData);
          }
        }
      }
      if (propName === 'triggerFollowUnfollowAction') {
        if (change.currentValue !== change.previousValue) {
          this.followMeBtnAction(this.trackedCarDetails);
        }
      }
      if (propName === 'triggerMapReinitializeAction') {
        if (change.currentValue !== change.previousValue) {
          const that = this;
          setTimeout(function() { that.reInitializeMap(); }, 100);
        }
      }
      if (propName === 'geoFenceCardClickAction') {
        if (change.currentValue !== change.previousValue) {
          let geoFenceObj: any;
          const that = this;
          geoFenceObj = find(that.geoFences, function(geoFence) { return geoFence.id === that.selectedGeoFenceCardDetails.id; });
          this.geoFenceCardClickEvent(geoFenceObj);
        }
      }
      if (propName === 'geoFenceAssetCountArray') {
        if (change.currentValue !== change.previousValue) {
          this.setCountOnGeoFence(this.geoFenceAssetCountArray);
        }
      }
      if (propName === 'isAddGeoFenceClicked') {
        if (change.currentValue !== change.previousValue) {
          if (this.isAddGeoFenceClicked) {
            this.toggleGeoFence(false);
          }
        }
      }
      if (propName === 'addGeoFenceCancelBtnClickTrigger') {
        if (change.currentValue !== change.previousValue) {
            this.cancelNewGeoFence();
        }
      }
      if (propName === 'addGeoFenceClearBtnClickTrigger') {
        if (change.currentValue !== change.previousValue) {
          this.clearNewGeoFence();
        }
      }
      if (propName === 'geoFenceAddOrDeleteSuccessTrigger') {
        if (change.currentValue !== change.previousValue) {
          $('.leaflet-container').removeClass('crosshair-cursor-enabled');
          this.getAllGeoFence();
        }
      }
      if (propName === 'carStatusPillClickedAction') {
        if (change.currentValue !== change.previousValue) {
          this.filterCarsMarkersBasedOnStatus(this.carStatusPillClickedAction);
        }
      }
      if (propName === 'showMap') {
        /*if (change.currentValue !== change.previousValue) {
          if (this.showMap) {
            this.getMapForSelectedUser();
            if (this.showMarker) {
              this.getAllTraceableCars();
              this.intervalForCheckingForOfflineCar();
            }
          }
        }*/
      }

      if(propName ==='ordersRouteToPlot'){
        if (change.currentValue !== change.previousValue) {
          this.removeLayer();
          if(!isEmpty(this.ordersRouteToPlot)) {
            this.plotPolyLine();
            this.plotOrderIcons();
          }
        }
      }

    }
  }

  ngOnDestroy() {
    clearInterval(this.intervalForCheckingForOfflineCarsId);
    // this.followMeBtnAction(this.trackedCarDetails);
    this.showMap = false;
    this.markersValueWithDeviceId = {};
    this.markers.length = 0;
    // this.showMarker = false;
    // this.trackedCarDetails = null;
  }

  onMapReady(map: Map) {
    const localThis = this;
    $('.leaflet-control-attribution').hide();
    this.map = map;
    control.zoom({
      position: 'bottomright'
    }).addTo(map);
    if (this.showGeofence) {
      this.getAllGeoFence();
    }
    this.map.on('click', function (event: any) {
      if (localThis.showGeofence && localThis.isAddGeoFenceClicked) {
        localThis.addNewPolygonPoints (event.latlng );
      }
      //localThis.onMapClick( event );
    });

    handleMapResize(this.map, 'map-events-actions')
    // this.setMap(this.mapDetails);

  }

  updateMarkerOnGpsData(gpsData) {
    if (gpsData && gpsData.location) {
      const coordinates = gpsData.location.coordinates;
      const location = new LatLng(coordinates[0], coordinates[1]);
      const speed = Math.round(gpsData.speed);
      let boxTemp = '';
      let boxStatus = '';
      if (gpsData.sensorData && gpsData.sensorData.temperature) {
        boxTemp = boxTemp + gpsData.sensorData.temperature;
      }
      if (gpsData.sensorData && gpsData.sensorData.coldChainBox) {
        boxStatus = gpsData.sensorData.coldChainBox;
      }
      let generatedTimeStamp = new Date();
      if (gpsData.generatedTimeStamp) {
        generatedTimeStamp = new Date(gpsData.generatedTimeStamp);
      }
      const recvdDate = new Date();
      this.addMarker(location, gpsData.assetDetails._id,
        gpsData.assetDetails.data.registrationNumber, speed
        , false, generatedTimeStamp, gpsData.ignitionStatus,
        gpsData.assetDetails.data.vinNumber, recvdDate, boxTemp, boxStatus );
    }
  }

  getMapForSelectedUser() {

        if (this.userMapOptions) {
          this.mapDetails = this.userMapOptions;
          this.setZoomLevel();
          if (this.mapDetails.centerLocation) {
            this.selectedLatitude = this.mapDetails.centerLocation.coordinates[0];
            this.selectedLongitude = this.mapDetails.centerLocation.coordinates[1];
          } else {
            this.selectedLatitude = 25.214017;
            this.selectedLongitude = 55.310220;
          }
          this.setMap(this.mapDetails);
          this.center = latLng(this.selectedLatitude, this.selectedLongitude);
          this.map.setView(this.center, this.selectedZoom);
          this.map.setZoom(this.selectedZoom);
          if (this.trackedCarDetails && this.trackedCarDetails.lastlocation) {
            // Temprary fix
            const lng = this.trackedCarDetails.lastlocation[0];
            const lat = this.trackedCarDetails.lastlocation[1];
            this.map.panTo([lat, lng]);
          }
          else if (this.lastMarkerClickedLocation) {
            this.map.panTo(this.lastMarkerClickedLocation);
          }
        }
  }

  setZoomLevel() {
    this.map.on('zoom', () => {
      this.customZoom = this.map.getZoom();
    });
    if (this.mapDetails.zoom && !this.customZoom) {
      this.selectedZoom = this.mapDetails.zoom;
    } else if (this.mapDetails.zoom && this.customZoom) {
      this.selectedZoom = this.customZoom;
    } else {
      this.selectedZoom = 11;
    }
  }

  setMap(mapSelected) {
    if (mapSelected && mapSelected.tile) {
      this.url = mapSelected.tile.url;
      this.option = mapSelected.tile.option;
      this.mapToView = tileLayer(this.url, this.option);
      if (mapSelected.tile['google_style']) {
        (L as any).gridLayer.googleMutant({
          type: 'roadmap',
          styles: mapSelected.tile['google_style'],
        }).addTo(this.map);

      } else {
        this.mapToView.addTo(this.map);
      }
      this.map.invalidateSize(true);
    }
  }


  getAllTraceableCars() {
     this.allTrackableCars = [];
    // this.showCircularLoader = true;
    this.assetService.getAllTrackableDeviceByModuleType(this.moduleType, false).subscribe((data: Array<any>) => {
      // this.allTrackableCars = data;
      // this.showCircularLoader = false;
      // this.showLoader = false;
      const _this = this;
      data.forEach((details) => {
        if (details && details['gpsData']) {
          _this.allTrackableCars.push(details);
          // _this.trackableCarDetailsArray.push(details);
        }
      });
      this.createDataForMarkers(_this.allTrackableCars);
      this.filterCars();
      if (this.trackedCarDetails) {
        this.followMeBtnAction(this.trackedCarDetails);
      }
      this.trackableCarsListForSidePanel = clone(this.allTrackableCars);
      this.trackableCarsListForSidePanelEvent.emit(this.allTrackableCars);
      // this.buildCarStatusLabelAndDescription();

    }, (error) => {
      // this.showCircularLoader = false;
      console.log(error);
    });
  }

  createDataForMarkers(data) {
    const _this = this;
    forEach(data, function (carData) {
      const locationArray: any = [];
      let latLngObj: any;
      latLngObj = latLng(carData.gpsData.latitude, carData.gpsData.longitude);
      locationArray.push(latLngObj.lat);
      locationArray.push(latLngObj.lng);
      const carId = carData._id;
      const carRegNo = carData.registrationNumber;
      const lastUpdated = carData.gpsData.generatedTimeStamp;
      const ignitionStatus = carData.ignitionStatus;
      const vinNumber = carData.vinNumber;
      const recvdDate = new Date(lastUpdated);
      if (locationArray && lastUpdated ) {
        _this.addMarker( locationArray , carId , carRegNo, 0 , true , lastUpdated, ignitionStatus, vinNumber, recvdDate);
      }
    });
    setTimeout(function() {
      if (_this.pageName === 'geoFence') {
        _this.filterCarsMarkersBasedOnStatus('all');
      } else {
        _this.filterCarsMarkersBasedOnStatus('online');
      }
    }, 10)
  }

  /* creating and updting marker */
  addMarker( location: LatLng , deviceId , carNo , speed , isOffline, lastDate, ignitionStatus, vinNumber, recvdDate, boxTemp?, boxStatus?) {
    const _this = this
    /*const deviceId = assetObj._id;
     const carNo = assetObj.data.registrationNumber;*/
    if (this.markersValueWithDeviceId[deviceId]) {
      const markerInstance = this.markersValueWithDeviceId[deviceId].markerData;
      this.markersValueWithDeviceId[deviceId].vinNumber = vinNumber;
      if (this.markersValueWithDeviceId[deviceId].isOffline) {
        this.markersValueWithDeviceId[deviceId].isOffline = isOffline;
        this.checkForOfflineCars();
      }
      setTimeout(() => {
            // _this.markersValueWithDeviceId[deviceId].isOffline = isOffline;
            (_this.markersValueWithDeviceId?.[deviceId]?.isOffline) ? _this.markersValueWithDeviceId[deviceId].isOffline = isOffline : null
         }, 50);

         this.updateMarker(markerInstance, location , deviceId , carNo , speed , lastDate , ignitionStatus, vinNumber, recvdDate, boxTemp, boxStatus);
    } else {
      this.createMarker( location, deviceId , carNo, speed  , lastDate , ignitionStatus, vinNumber, recvdDate);
    }
  }

  updateMarker(markerInstance: Marker, location: LatLng ,
               deviceId , carNo, speed , lastDate , ignitionStatus , vinNumber, recvdDate, boxTemp?, boxStatus? ) {
    //TODO:need to remove this arg
    if(vinNumber){

    }
    const _this = this;
    let speedFloored: number;
    speedFloored = floor(speed);
    const prevDateOfGpsPlotted = new Date(this.markersValueWithDeviceId[deviceId]['lastUpdated']).getTime();
    const isOldData =  new Date(lastDate).getTime() < prevDateOfGpsPlotted  ;
    // const dataDiff = new Date(lastDate).getTime() - prevDateOfGpsPlotted ;
    /*if ( isOldData ) {
     console.log(`##################### old gps  ${deviceId} is old diff is ${dataDiff}`);
     }*/
    this.markersValueWithDeviceId[deviceId]['lastUpdated'] = new Date(lastDate);
    this.markersValueWithDeviceId[deviceId]['recvdDate'] = new Date(recvdDate);
    this.markersValueWithDeviceId[deviceId].latLng = LatLng ;
    this.markersValueWithDeviceId[deviceId].ignitionStatus = ignitionStatus ;
    if (this.deviceToBeTracked === deviceId) {
      if (prevDateOfGpsPlotted && !isOldData) {
        /*this.markersValueWithDeviceId[deviceId]['lastUpdated'] = new Date(lastDate);
         this.markersValueWithDeviceId[deviceId].latLng = LatLng ;*/
        markerInstance.setIcon(icon({
          iconSize: [30, 40],
          iconAnchor: [13, 38],
          iconUrl: _this.getIconsBasedOnModules('active'), // '../assets/leaflet-icons/marker-icon-active.png',
          shadowUrl: 'leaflet/marker-shadow.png',
          shadowAnchor: [13, 38]
        }));
        markerInstance.setLatLng(location);
        if (this.map) {
          this.map.panTo(location);
        }
         this.updatePolyline(location , speed , lastDate);
         this.previousDateForLinePlotting = new Date(lastDate);
      } else {
        const milliSeconds = new Date(lastDate).getTime();
        if ((this.previousDateForLinePlotting.getTime() !== new Date(lastDate).getTime())) {
          this.carTrackingLocationArray.push({location: location, milliSeconds: milliSeconds});
        }
      }
    } else {
      markerInstance.setIcon(icon({
        iconSize: [30, 40],
        iconAnchor: [13, 38],
        iconUrl: _this.getIconsBasedOnModules('online'), // '../assets/leaflet-icons/marker-icon.png',
        shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      }));
      markerInstance.setLatLng(location);
      markerInstance.closeTooltip();
    }
    this.setTooltipContent(markerInstance, lastDate , false , carNo , speedFloored , false , ignitionStatus, boxTemp, boxStatus);
    this.markersValueWithDeviceIdEvent.emit(this.markersValueWithDeviceId);
  }

  createMarker( location , deviceId , carNo, speed  , lastDate, ignitionStatus , vinNumber, recvdDate) {
    //TODO: need to remove below arg
    if(vinNumber){

    }
    const localThis = this;
    // localThis.showSlider = true;
    let iconPath = localThis.getIconsBasedOnModules('online'); // '../assets/leaflet-icons/marker-icon.png';
    let lastUpdated = new Date(lastDate);
    let isOffline = false;
    const a = moment();
    const b = moment(lastDate);
    const  differenceInMinutes = a.diff(b, 'minute');
    if (differenceInMinutes >= this.minutesAfterDeviceIsOffline) {
      isOffline = true;
    }
    if (isOffline) {
      iconPath = localThis.getIconsBasedOnModules('offline'); // '../assets/leaflet-icons/marker-icon-offline.png';
      lastUpdated = lastDate;
    }
    const newMarker = marker( location,
      {
        icon: icon({
          iconSize: [30, 40],
          iconAnchor: [13, 38],
          iconUrl: iconPath,
          shadowUrl: 'leaflet/marker-shadow.png',
          shadowAnchor: [13, 38]
        }),
        autoPan: true
      }
    );

    newMarker.on( 'click', function () {
      const clickEvent = {
        'deviceId': deviceId,
        'location': location
      };
      localThis.markerClickEvent.emit(clickEvent);
    });
    this.markersValueWithDeviceId[deviceId] = {
      markerData: newMarker,
      carNo: carNo,
      latlng: location,
      isOffline: isOffline,
      lastUpdated: lastUpdated,
      ignitionStatus: ignitionStatus,
      recvdDate: recvdDate
    };
    // const markersTrackedObj = {
    //   deviceId: deviceId,
    //   carRegNo: carNo,
    //   speed: floor(speed),
    //   isCarSelectedToTrack: false,
    //   ignitionStatus: ignitionStatus,
    //   vinNumber: vinNumber
    // };
    this.markers.push(newMarker);
    this.setTooltipContent(newMarker, lastDate, isOffline , carNo , speed, false, ignitionStatus);
    this.markersValueWithDeviceIdEvent.emit(this.markersValueWithDeviceId);
  }

  setTooltipContent(markerInstance: Marker, lastUpdated , isOffline , regNo , speed ,
     isTracking, ignitionStatus, boxTemp?, boxStatus?) {


      boxTemp = boxTemp ? typeof boxTemp === 'number' ? boxTemp : parseFloat(boxTemp).toFixed(1)+ ' °C' : 'NA';
    let toolTipContent = '';
    if (this.moduleType === CONSTANT.MODULES.FLEET.MODULE) {
      const labelWidth = 45;
      let ignitionStatusValue = '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/ignition-25.png"></span> ' ;

      let minWidth = 110;
      let formattedLastUpdate = '';
      this.lang.updateMomentLanguage(moment);
      formattedLastUpdate = moment(lastUpdated).calendar();
      if ((regNo && regNo.length > 7 && regNo.length < 15)) {
        minWidth = 150;
      }
      if ((regNo && regNo.length >= 15) || (formattedLastUpdate && formattedLastUpdate.length >= 18)) {
        minWidth = 160;
      }
      if (ignitionStatus) {
        ignitionStatusValue += '</B><strong style="color: green">on</strong></br>';
      } else {
        ignitionStatusValue += '</B><strong style="color: red">off</strong></br>';
      }
      if (isOffline) {
        toolTipContent = '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/licence-plate-25.png"></span> ' +
          '</B> <strong style="color: #483b3c">' + regNo + '</strong></br>' +
          '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/status-25.png"></span> ' +
          '</B><strong style="color: red">offline</strong></br>' +
          '<italics><strong style="color: #483b3c; width: ' + minWidth + 'px; float: left">' + formattedLastUpdate + '</strong></italics></br>' ;
      } else {
        toolTipContent =  '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/licence-plate-25.png"></span> ' +
          '<strong style="color: #483b3c">' + regNo + '</strong></br>' +
          '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><i style="width: 15px;" class="fas fa-tachometer-alt"></i></span>' +
          '</B><strong style="color: #483b3c">' + (speed ? (speed + ' km/hr'): 'N/A'  ) + '</strong></br>' + ignitionStatusValue +
          '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/status-25.png"></span> ' +
          '</B><strong style="color: green">online</strong></strong></br>' +
          '<italics><strong style="color: #483b3c; width:  ' + minWidth + 'px; float: left">' + formattedLastUpdate + '</strong></italics></br>' ;
      }
    } else if ((this.moduleType === CONSTANT.DMS_MODULES.MMD.MODULE) || (this.moduleType === CONSTANT.DMS_MODULES.LMD.MODULE)) {
      const labelWidth = 45;

      let minWidth = 110;
      let formattedLastUpdate = '';
      this.lang.updateMomentLanguage(moment);
      formattedLastUpdate = moment(lastUpdated).calendar();
      if ((regNo && regNo.length > 7 && regNo.length < 15)) {
        minWidth = 150;
      }
      if ((regNo && regNo.length >= 15) || (formattedLastUpdate && formattedLastUpdate.length >= 18)) {
        minWidth = 160;
      }
      if (isOffline) {
        toolTipContent = '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/licence-plate-25.png"></span> ' +
          '</B> <strong style="color: #483b3c">' + regNo + '</strong></br>' +
          '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/status-25.png"></span> ' +
          '</B><strong style="color: red">offline</strong></br>' +
          '<italics><strong style="color: #483b3c; width: ' + minWidth + 'px; float: left">' + formattedLastUpdate + '</strong></italics></br>' ;
      } else {
        toolTipContent =  '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/licence-plate-25.png"></span> ' +
          '<strong style="color: #483b3c">' + regNo + '</strong></br>' +
          '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><i style="width: 15px;" class="fas fa-tachometer-alt"></i></span>' +
          '</B><strong style="color: #483b3c">' + (Number.isNaN(speed) ? 'N/A' : speed + ' km/hr') + '</strong></br>' +
          '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><img style="width: 15px;" src="../../../../assets/asset-icons/status-25.png"></span> ' +
          '</B><strong style="color: green">online</strong></strong></br>';

          toolTipContent = toolTipContent + '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><i style="width: 15px;" class="fas fa-thermometer-full"></i></span>' +
            '</B><strong style="color: #483b3c">' +boxTemp  + '</strong></br>';

        if (boxStatus) {
          toolTipContent = toolTipContent + '<span style="margin-right: 5px;width: ' + labelWidth + 'px;"><i style="width: 15px;" class="fas fa-box"></i></span> ' +
            '</B><strong style="color: #483b3c">' + boxStatus + '</strong></strong></br>';
        }
        toolTipContent = toolTipContent + '<italics><strong style="color: #483b3c; width:  ' + minWidth + 'px; float: left">' + formattedLastUpdate + '</strong></italics></br>';
      }
    }

    markerInstance.bindTooltip( toolTipContent);
    if (isTracking) {
      markerInstance.openTooltip();
    }
  }

  followMeBtnAction(assetData, isMapReInitialize?: boolean) {
    // this.trackedCarDetails = assetData;
    //TODO: need to remove below arg
    if(isMapReInitialize){

    }
    this.carTrackingLocationArray = [];
    if (this.deviceToBeTracked && this.deviceToBeTracked === assetData._id ) {
      this.stopCarTracking();
      // this.carDetails.isCarSelectedToTrack = false;
      /*if (isMapReInitialize) {
        this.reInitializeMap();
      }*/
    } else {
      //this.selectedCarLiveGpsData = this.formatSelectedCarLiveGpsData(assetData);
      // this.getSelectedCarLiveGpsData(assetData);
      this.startTracking(assetData._id);
      // this.carDetails.isCarSelectedToTrack = true;
      this.setTrackingCarDropdown();
    }
  }

  reInitializeMap() {
    this.getMapForSelectedUser();
  }

  setTrackingCarDropdown() {
    let index: number;
    const localThis = this;
    index = findIndex(localThis.allTrackableCars, function(o) { return o.registrationNumber == localThis.carDetails.registrationNumber; });
    if (index >= 0) {
      this.searchedCar = this.allTrackableCars[index];
    }
  }

  startTracking(deviceId: string) {
    this.carTrackingLocationArray = [];
    const previouslyTrackedDevice = this.deviceToBeTracked;
    this.togglePreviousSelected(previouslyTrackedDevice);
    this.deviceToBeTracked = deviceId;
    this.panToTheSelectedCar(deviceId);
    this.deviceToBeTrackedEvent.emit(this.deviceToBeTracked);
    if (this.showEventMarkers) {
      this.removePreviousEventMarkers();
    }
  }

  updatePolyline( location: LatLng , speed , lastDate ) {
    //TODO: need to remove below arg
    if(speed){

    }
    const milliSeconds = new Date(lastDate).getTime();
    this.carTrackingLocationArray.push({location: location, milliSeconds: milliSeconds});
    this.sortPolyLineByRecievedPoints();
    // const toolTip = '<B> Speed : </B><strong>' + speed + ' km/hr</strong>';
    if ( this.polyLine && this.polyLine['_map']) {
      this.polyLine.addLatLng( location );
    } else {
      this.polyLine = polyline( [ location ] , { 'weight': 5 , 'color': '#1d3785' , 'opacity': .9 } );
      this.map.addLayer( this.polyLine );
    }
  }

  sortPolyLineByRecievedPoints() {
    this.newDataForPolylineCount = this.newDataForPolylineCount + 1;
    if (this.newDataForPolylineCount > 5) {
      const routeArray = map(orderBy(this.carTrackingLocationArray, ['milliSeconds'], ['asc']), function (routePath) {
        if (routePath.location) {
          return routePath.location;
        }
      });
      if (this.polyLine && this.polyLine['_map']) {
        this.polyLine.setLatLngs(routeArray);
        this.polyLine.redraw();
      }
      this.newDataForPolylineCount = 0;
    }
  }

  panToTheSelectedCar(deviceId) {
    const _this = this;
    if ( this.markersValueWithDeviceId && this.markersValueWithDeviceId[deviceId] &&
      this.markersValueWithDeviceId[deviceId].latlng ) {
      const location = this.markersValueWithDeviceId[deviceId].latlng;
        _this.map.panTo(location);
      setTimeout(function() { _this.map.setZoomAround(location, 14); }, 500);
      this.markersValueWithDeviceId[deviceId].markerData.setIcon(icon({
        iconSize: [30, 40],
        iconAnchor: [13, 38],
        iconUrl: _this.getIconsBasedOnModules('active'), // '../assets/leaflet-icons/marker-icon-active.png',
        shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      }));
      this.setTooltipContent(this.markersValueWithDeviceId[deviceId].markerData, this.markersValueWithDeviceId[deviceId].lastUpdated
        , this.markersValueWithDeviceId[deviceId].isOffline ,
        this.markersValueWithDeviceId[deviceId].carNo , 0 , false , this.markersValueWithDeviceId[deviceId].ignitionStatus);
    } else {
      /*this.trackableCarNotFoundMessages = [];
      this.trackableCarNotFoundMessages.push('Location unavailable for ' + this.searchedCar.registrationNumber);
      this.setCssProperties.showMessagePopup('trackableCarNotFound');*/
    }
  }
  stopCarTracking() {
    if (this.carDetails._id === this.deviceToBeTracked && this.deviceToBeTracked) {
      this.carDetails.isCarSelectedToTrack = false;
    }
    this.carTrackingLocationArray = [];
    this.newDataForPolylineCount = 0;
    this.selectedCarLiveGpsData = null;
    const previousDeviceTracked = this.deviceToBeTracked;
    this.togglePreviousSelected( previousDeviceTracked );
    this.searchedCar = null;
    this.deviceToBeTracked = null;
    if (this.showEventMarkers) {
      this.removePreviousEventMarkers();
    }
    this.deviceToBeTrackedEvent.emit(this.deviceToBeTracked);
  }
  togglePreviousSelected(id: string) {
    this.newDataForPolylineCount = 0;
    if (id && this.markersValueWithDeviceId[id]) {
      if (this.polyLine && this.polyLine['_map']) {
        this.polyLine.remove();
      }
      let markerInstance: Marker ;
      markerInstance = this.markersValueWithDeviceId[id].markerData;

      let iconPath = this.getIconsBasedOnModules('online'); // '../assets/leaflet-icons/marker-icon.png';
      if (this.markersValueWithDeviceId[id].isOffline) {
        iconPath = this.getIconsBasedOnModules('offline'); // '../assets/leaflet-icons/marker-icon-offline.png';
      }
      markerInstance.setIcon(icon({
        iconSize: [30, 40],
        iconAnchor: [13, 38],
        iconUrl: iconPath,
        shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      })) ;
      markerInstance.closeTooltip();
    } else {
    }
  }

  getIconsBasedOnModules(status) {
    const _this = this;
    let imageUrl: string;
      imageUrl = '';
      switch (status) {
        case 'active':
          imageUrl = _this.trackIcons.active;
          break;
        case 'online':
          imageUrl = _this.trackIcons.online;
          break;
        case 'offline':
          imageUrl = _this.trackIcons.offline;
          break;
      }
      return imageUrl;
  }

  // ----------------------------- codes for plotting ignition and other events

  handleCarEvent(event) {
    if ( this.deviceToBeTracked && this.markersValueWithDeviceId[this.deviceToBeTracked] &&
      this.markersValueWithDeviceId[this.deviceToBeTracked].vinNumber === event.vinNumber ) {
      if (event && event.name === 'Ignition' ) {
        this.plotIgnitionEvents(event);
      }
      if (event && event.name === 'Harsh Event' ) {
        this.plotHarshEvents(event);
      }
    }
  }

  plotIgnitionEvents(event) {
    let iconPath = '../assets/leaflet-icons/carOff.png';
    // const type = event.status;
    if (event.status === 'Off') {
      iconPath = '../assets/leaflet-icons/carOff.png';
    }

    if (event.status === 'On') {
      iconPath = '../assets/leaflet-icons/carOn.png';
    }
    this.createEventMarker(event.coordinates, iconPath, event.name, event.status, event.description
      , event.generatedTimeStamp , event.name , event.rawData.speed);
  }
  plotHarshEvents(event) {
    const  iconPath = '../assets/leaflet-icons/harshEvents2.png';
    this.createEventMarker(event.coordinates, iconPath, event.name, event.status, event.description,
      event.generatedTimeStamp , event.description , event.rawData.speed );
  }

  createEventMarker(location: LatLng, iconPath: string, eventName: string, eventStatus: string, description: string ,
                    eventTime: Date , type: string , speed ) {
    //TODO: need to remove below arg
    if(type && eventName && eventStatus){

    }

    const newMarker = marker( location,
      {
        icon: icon({
          iconSize: [15, 15],
          iconUrl: iconPath
        }),
        autoPan: true
      }
    ).addTo(this.map);
    this.eventMarkersPlotted.push(newMarker);
    this.setEventTooltipContent(newMarker, description , eventTime , speed );
    this.updateLayer();
  }
  eventMarkerClickEvent() {
    // console.log(event);
  }
  setEventTooltipContent(markerInstance: Marker , description , eventTime , speed) {
    let toolTipContent = '';
    this.lang.updateMomentLanguage(moment);
    toolTipContent = '</B> <strong style="color: #483b3c">' + description + '</strong></br>' +
      '<B><strong style="color:#504d4d;width: 45px;float: left">' + this.lang.getTranslation('sys.status') + '</strong> : ' +
      '</B><strong style="color: #483b3c">' + speed + ' km/hr</strong></br> ' +
      '<italics><strong style="color: #483b3c; width: 110px; float: left">' + moment(eventTime).calendar() + '</strong></italics></br>' ;
    markerInstance.bindTooltip( toolTipContent);
  }

  removePreviousEventMarkers() {
    const localThis = this;
    forEach(this.eventMarkersPlotted, function (markersPlotted) {
      markersPlotted.removeFrom(localThis.map);
    });
    this.eventMarkersPlotted = [];
  }


  updateLayer() {
    const ignitionOn = layerGroup(this.eventsMarkers.Ignition.On);
    const ignitionOff = layerGroup(this.eventsMarkers.Ignition.Off);
    const suddenBraking = layerGroup(this.eventsMarkers['Harsh Event']['Harsh Braking']);
    const suddenAcceleration = layerGroup(this.eventsMarkers['Harsh Event']['Harsh Acceleration']);

    const overlayMaps = {
      'Ignition Off': ignitionOff,
      'Ignition On': ignitionOn,
      'Sudden Acceleration': suddenAcceleration,
      'Sudden Braking': suddenBraking
    };

    if(overlayMaps){}
  }

  // --------------------------------------------------------------------------------------------

  // ----------------------------- codes for GeoFence -------------------------------------------

  getAllGeoFence() {
    this.removeAllGeoFencePlotted();
    const numOfVehicles = this.lang.getTranslation('sys.no-of-vehicles');
    this.geoFenceService.getAll()
      .subscribe((data: Array<any>) => {
        this.plotGeoFence(data, numOfVehicles);
      });
  }
  getAllGeoFenceAssetCount() {
    this.geoFenceService.getAllGeoFenceAssetCount().subscribe(geoFenceAssetCount => {
      this.setCountOnGeoFence( geoFenceAssetCount );
    });
  }
  private plotGeoFence(data: Array<any>, numOfVehicles) {
    const localThis = this;
    forEach(data, function (geoFence) {
      const coordinates = map(geoFence.location.coordinates, function (coordinate) {
        return latLng(coordinate[0], coordinate[1]);
      });
      const geoFencePlot = polygon(coordinates, {color: 'blue'} ).addTo(localThis.map).bindTooltip(
        `<B style="font-size: 14px">${ geoFence.label }</B></br> <span style="font-size: 13px">${numOfVehicles}: 0</span>`).closeTooltip();
      const geoFenceData = {
        coordinates: coordinates,
        geoFenceLabel: geoFence.label,
        chart: geoFencePlot,
        id: geoFence._id,
        count: 0
      };
      geoFencePlot.on( 'click', function () {
        localThis.geoFenceClickEvent.emit(geoFenceData);
      });
      localThis.geoFences.push(geoFenceData);
    });
    localThis.getAllGeoFenceAssetCount();
  }

  setCountOnGeoFence(geoFenceCountList) {
    const groupedAssetCountByGeoFenceId = groupBy(geoFenceCountList, 'geoFence.id' );
    const numOfVehicles = this.lang.getTranslation('sys.no-of-vehicles');
    this.geoFences = map(this.geoFences, function (geoFenceObj) {
      let geoFenceCountObj: any;
      if (groupedAssetCountByGeoFenceId[geoFenceObj.id] && groupedAssetCountByGeoFenceId[geoFenceObj.id][0]) {
        geoFenceCountObj = groupedAssetCountByGeoFenceId[geoFenceObj.id][0];
      }
      if ( geoFenceCountObj ) {
        geoFenceObj.count = geoFenceCountObj.count;
        if (geoFenceCountObj.assets) {
          geoFenceObj.assets = geoFenceCountObj.assets;
        }
        const tootlTipMsg = '<B style="font-size: 14px">' + geoFenceObj.geoFenceLabel +
          '</B></br>' + '<span style="font-size: 13px">' + numOfVehicles + ' : ' + geoFenceObj.count + '</span>';
        geoFenceObj.chart.bindTooltip(tootlTipMsg);
        if (geoFenceObj.chart.isTooltipOpen()) {
          geoFenceObj.chart.openTooltip();
        } else {
          geoFenceObj.chart.closeTooltip();
        }
      }
      return geoFenceObj;
    });
    this.geoFences = orderBy(this.geoFences, ['count', 'geoFenceLabel'], ['desc', 'asc']);
  }

  geoFenceCardClickEvent(geoFence) {
    let geoFenceId: string;
    geoFenceId = geoFence.id;
    this.carsInGeoFence = [];
    let selectedGeoFence: any;
    selectedGeoFence = find(this.geoFences, function(o) { return (o.id === geoFenceId); });
    if (selectedGeoFence) {
      this.geoFenceSelectedObject = selectedGeoFence;
    }
    this.toggleGeofenceSelect(geoFence);
  }

  toggleGeofenceSelect(geoFence) {
    let geoFenceId: string;
    const that = this;
    let selectedGeofence: any;
    let selectedGeofenceIndex: number;
    geoFenceId = geoFence.id;
    selectedGeofence = find(this.geoFences, function(fence) {return (fence.id === geoFenceId); });
    selectedGeofenceIndex = findIndex(this.geoFences, function(fence) { return (fence.id === geoFenceId); });
    if (selectedGeofence) {
      if (selectedGeofence['cardClicked']) {
        this.updateGeoFenceColor(geoFence, 'blue');
        selectedGeofence['cardClicked'] = false;
        geoFence.chart.closeTooltip();
        setTimeout(function() { that.popUpGridClose(); }, 100);
      } else {
        geoFence.chart.openTooltip();
        this.updateGeoFenceColor(geoFence, '#FFEB3B');
        selectedGeofence['cardClicked'] = true;
        this.getAssetsForGeoFenceById(geoFenceId);
        this.paneToSelectedGeoFence(geoFence.geoFenceLabel);
      }
      this.geoFences[selectedGeofenceIndex] = selectedGeofence;
    }
    forEach(this.geoFences, function(fence) {
      if (fence['cardClicked'] && (fence !== selectedGeofence)) {
        fence.chart._source.setStyle({fillColor: 'blue'});
        fence.chart.closeTooltip();
        fence['cardClicked'] = false;
      }
    });
  }

  updateGeoFenceColor(geoFence, fillColor) {
    let geoFenceId: string;
    let selectedGeofence: any;
    let selectedGeofenceIndex: number;
    geoFenceId = geoFence.id;
    selectedGeofence = find(this.geoFences, function(fence) {return (fence.id === geoFenceId); });
    selectedGeofenceIndex = findIndex(this.geoFences, function(fence) { return (fence.id === geoFenceId); });
    if (selectedGeofence && selectedGeofence.chart) {
      selectedGeofence.chart._source.setStyle({fillColor: fillColor});
      this.geoFences[selectedGeofenceIndex] = selectedGeofence;
    }
  }

  getAssetsForGeoFenceById(geoFenceId) {
    this.carsInGeoFence = [];
    this.isCarsInGeoFence = false;
    this.geoFenceService.getAssetsForGeoFenceById(geoFenceId).subscribe((assets: any) => {
      if (assets && assets.length > 0) {
        const array: any = [];
        forEach(assets, function(asset) {
          array.push(asset.assets);
        });
        this.carsInGeoFence = array;
        this.isCarsInGeoFence = false;
      } else {
        this.carsInGeoFence = [];
        this.isCarsInGeoFence = true;
      }
      document.getElementById('click-btn').click();
    });
  }

  paneToSelectedGeoFence(name) {
    const groupGeoFenceByName = groupBy(this.geoFences, 'geoFenceLabel' );
    const bound = groupGeoFenceByName[name][0]['chart']['_source'].getBounds();
    this.map.fitBounds(bound);
  }

  popUpGridClose() {
    $('.pop-up-grid').slideUp();
    this.carsInGeoFence = [];
    this.isCarsInGeoFence = false;
  }

  removeAllGeoFencePlotted( ) {
    forEach(this.geoFences, function (geoFence) {
      geoFence.chart.remove();
    });
    this.geoFences = [];
  }

  addNewPolygonPoints(location: LatLng) {
    this.newGeoFencePolygonPoints.push( location );
    if ( this.newGeoFencePolygon && this.newGeoFencePolygon['_map'] ) {
      this.newGeoFencePolygon.addLatLng( location ).redraw();
    } else {
      this.newGeoFencePolygon = polygon( this.newGeoFencePolygonPoints , {color: 'green'} ).addTo( this.map );
    }
    this.newGeoFencePolygonEvent.emit(this.newGeoFencePolygon);
  }

  toggleGeoFence( show ) {
    const localThis = this;
    forEach(this.geoFences , function (geoFenceData) {
      if ( show ) {
        geoFenceData.chart.addTo( localThis.map );
      } else {
        geoFenceData.chart.remove();
      }
    });
  }

  clearNewGeoFence() {
    if ( this.newGeoFencePolygon && this.newGeoFencePolygon['_map'] ) {
      this.newGeoFencePolygonPoints = [];
      this.newGeoFencePolygon.setLatLngs(this.newGeoFencePolygonPoints).redraw();
    }
  }
  cancelNewGeoFence() {
    $('.leaflet-container').removeClass('crosshair-cursor-enabled');
    this.clearNewGeoFence();
    this.toggleGeoFence( true );
  }

  // -------------------------------------------------------------------

  intervalForCheckingForOfflineCar() {
    const _this = this;
    this.intervalForCheckingForOfflineCarsId = setInterval(function () {
      _this['checkForOfflineCars']();
    }, 1000 * 60 * this.intervalTimeInMinutesForCheckingOffline );
  }
  checkForOfflineCars() {
    const allDevices = keys(this.markersValueWithDeviceId);
    const allCarGroupedByCarId = groupBy(this.allTrackableCars, '_id');
    const _this = this;
    let onlineCount = 0;
    let offlineCount = 0;
    const onlineCars: any = [];
    const offlineCars: any = [];
    forEach(allDevices, function ( deviceId ) {
      const deviceData = _this.markersValueWithDeviceId[deviceId];
      const a = moment();
      const b = moment(deviceData['recvdDate']);
      const  differenceInMinutes = a.diff(b, 'minute');
      if (differenceInMinutes >= _this.minutesAfterDeviceIsOffline) {
        _this.markersValueWithDeviceId[deviceId].isOffline = true;
        _this.makeMarkerOffline(deviceId);
        if (allCarGroupedByCarId[deviceId] && allCarGroupedByCarId[deviceId][0]) {
          offlineCars.push(allCarGroupedByCarId[deviceId][0]);
        }
        offlineCount++;
      } else {
        _this.markersValueWithDeviceId[deviceId].isOffline = false;
        if (allCarGroupedByCarId[deviceId] && allCarGroupedByCarId[deviceId][0]) {
          onlineCars.push(allCarGroupedByCarId[deviceId][0]);
        }
        onlineCount++;
      }
    });
    this._trackableCarsCounts.online = onlineCount;
    this._trackableCarsCounts.offline = offlineCount;
    this._trackableCars.offline = offlineCars;
    this._trackableCars.online = onlineCars;
    if (this.carStatusType === 'offline') {
      this.trackableCarsListForSidePanel = clone(this._trackableCars.offline);
    }
    if (this.carStatusType === 'online') {
      this.trackableCarsListForSidePanel = clone(this._trackableCars.online);
    }
    if (this.carStatusType === 'all') {
      this.trackableCarsListForSidePanel = clone(this._trackableCars.all);
    }
    // this.buildCarStatusLabelAndDescription();
    this.trackableCarsListForSidePanelEvent.emit(this.trackableCarsListForSidePanel);
    this.trackableCarsListEvent.emit(this._trackableCars);
    this.trackableCarsCountsEvent.emit(this._trackableCarsCounts);
    this.markersValueWithDeviceIdEvent.emit(this.markersValueWithDeviceId);
  }
  makeMarkerOffline(deviceId) {
    const _this = this;
    const markerInstance = this.markersValueWithDeviceId[deviceId].markerData;
    if (deviceId !== this.deviceToBeTracked) {
      markerInstance.setIcon(icon({
        iconSize: [30, 40],
        iconAnchor: [13, 38],
        iconUrl: _this.getIconsBasedOnModules('offline'), // '../assets/leaflet-icons/marker-icon-offline.png',
        shadowUrl: 'leaflet/marker-shadow.png',
        shadowAnchor: [13, 38]
      }));
    }
    /*const toolTip = '<B> Reg No  : ' + this.markersValueWithDeviceId[deviceId].carNo + '</B></br><strong>Status : <label style="color: red">offline</label></strong></B>' +
     '</br><italics>' + moment(this.markersValueWithDeviceId[deviceId].lastDate).calendar() + '</italics>';
     markerInstance.bindTooltip( toolTip);*/
    this.markersValueWithDeviceId[deviceId].isOffline = true;
    this.setTooltipContent(this.markersValueWithDeviceId[deviceId].markerData, this.markersValueWithDeviceId[deviceId].lastUpdated
      , this.markersValueWithDeviceId[deviceId].isOffline , this.markersValueWithDeviceId[deviceId].carNo , 0 , false , this.markersValueWithDeviceId[deviceId].ignitionStatus  );
    this.markersValueWithDeviceIdEvent.emit(this.markersValueWithDeviceId);
  }

  /*getActivePillValue(filterTerm: string) {
    this.openCarPanelIfClosed();
    this._carStatusPills.selectedPillId = filterTerm;
    switch (filterTerm) {
      case 'all':
        this.carStatusType = 'all';
        break;
      case 'online':
        this.carStatusType = 'online';
        break;
      case 'offline':
        this.carStatusType = 'offline';
        break;
    }
    this.filterCarsMarkersBasedOnStatus(filterTerm);
  }*/

  /*openCarPanelIfClosed() {
    const trackableCarList = '.trackable-cars-list';
    const listToggleBtn = '.list-toggle-button';
    if ($(trackableCarList).hasClass('panelClosed')) {
      $(trackableCarList).removeClass('panelClosed').addClass('panelOpened');
      if ($(listToggleBtn).children('.arrow-icon').hasClass('fa-chevron-right')) {
        $(listToggleBtn).children('.arrow-icon').removeClass('fa-chevron-right').addClass('fa-chevron-left');
      }
    }
  }*/

  filterCarsMarkersBasedOnStatus(filterTerm) {
    this.carStatusType = filterTerm;
    this.checkForOfflineCars();
    this.removeAllMarkers(this.markersValueWithDeviceId);
    switch (filterTerm) {
      case 'all':
        this.trackableCarsListForSidePanel = this._trackableCars.all;
        this.addAllCars(this.markersValueWithDeviceId);
        break;
      case 'online':
        this.trackableCarsListForSidePanel = this._trackableCars.online;
        this.addOnlineCars(this.markersValueWithDeviceId);
        break;
      case 'offline':
        this.trackableCarsListForSidePanel = this._trackableCars.offline;
        this.addOfflineCars(this.markersValueWithDeviceId);
        break;
    }
    this.trackableCarsListForSidePanelEvent.emit(this.trackableCarsListForSidePanel);
  }

  addOnlineCars(markersValueWithDeviceId) {
    const that = this;
    let isTrackedCarIdMatched: boolean;
    forEach(markersValueWithDeviceId, function(value, key) {
      if (value.isOffline === false) {
        value.markerData.addTo(that.map);
        if (key === that.deviceToBeTracked) {
          isTrackedCarIdMatched = true;
        }
      }
    });
    if (this.deviceToBeTracked && !isTrackedCarIdMatched) {
       this.stopCarTracking();
    }
  }

  addOfflineCars(markersValueWithDeviceId) {
    const that = this;
    let isTrackedCarIdMatched: boolean;
    forEach(markersValueWithDeviceId, function(value, key) {
      if (value.isOffline === true) {
        value.markerData.addTo(that.map);
        if (key === that.deviceToBeTracked) {
          isTrackedCarIdMatched = true;
        }
      }
    });
    if (this.deviceToBeTracked && !isTrackedCarIdMatched) {
       this.stopCarTracking();
    }
  }

  addAllCars(markersValueWithDeviceId) {
    const that = this;
    forEach(markersValueWithDeviceId, function(value) {
      value.markerData.addTo(that.map);
    });
  }

  removeAllMarkers(markersValueWithDeviceId) {
    forEach(markersValueWithDeviceId, function(value) {
      value.markerData.remove();
    });
  }

  filterCars() {
    const that = this;
    const onlineCars: Array<any> = [];
    const offlineCars: Array<any> = [];
    forEach(this.allTrackableCars, function(car) {
      if ( that.checkIsCarOffline(car)) {
        offlineCars.push(car);
      } else {
        onlineCars.push(car);
      }
    });

    this._trackableCars.all = this.allTrackableCars;
    this._trackableCars.offline = offlineCars;
    this._trackableCars.online = onlineCars;

    this._trackableCarsCounts.all = this.allTrackableCars.length;
    this._trackableCarsCounts.online = onlineCars.length;
    this._trackableCarsCounts.offline = offlineCars.length;


    this.trackableCarsListEvent.emit(this._trackableCars);
    this.trackableCarsCountsEvent.emit(this._trackableCarsCounts);
  }

  checkIsCarOffline(carDetails): boolean {
    let isCarOffline: boolean;
    isCarOffline = false;
    const a = moment();
    const b = moment(carDetails.locationLastUpdated);
    const  differenceInMinutes = a.diff(b, 'minute');
    if (differenceInMinutes >= this.minutesAfterDeviceIsOffline) {
      isCarOffline = true;
    }
    return isCarOffline;
  }

  setPillsTooltipsDescription(filterTerm, msg) {
    let index: number;
    let localArrayOfFilterPills: Array<IotzenPillType> = [];
    localArrayOfFilterPills = this._carStatusPills.list;
    index = findIndex(localArrayOfFilterPills, function(o) { return o.id === filterTerm; });
    localArrayOfFilterPills[index].description = msg;
    this._carStatusPills.list = localArrayOfFilterPills;
  }

  toggleCarsList() {
    $('.trackable-cars-list').toggleClass('panelOpened panelClosed');
    $('.list-toggle-button').children('.arrow-icon').toggleClass('fa-chevron-left fa-chevron-right');
  }

}

export interface AssetsInFenceElement {
  id: number;
  registrationNo: string;
  vinNo: string;
  inTime: string;
}

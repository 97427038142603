<div class="grid PR">
  <p class="stats-title">{{graphTitle}}</p>
  <div class="stats-iotzen-pill-container">
    <app-iotzen-pills [pillActiveColour]="pillActiveColour" [isPillTabToggleRequired]="isPillTabToggleRequired"
                      [defaultActivePillIndex]="defaultActivePillIndex"
                      [arrayOfPills]="arrayOfPills"
                      (activePillValue)="getActivePillValue($event)"></app-iotzen-pills>
  </div>
</div>

<div>
  <app-e-charts [loader]="loader" [chartData]="chartData" [show]="show"></app-e-charts>
</div>


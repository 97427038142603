import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ConfigService} from './config.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaceMatchService {

  constructor(private http: HttpClient, public configService: ConfigService) { }

  getFaceMatchCardsBasedOnRange(skip, limit) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'faceMatchDetection/?skip=' + skip + '&limit=' + limit);
  }

  getFrameForFaceCard(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'faceMatchDetection/image/' + id + '/frame');
  }

  getFaceMatchCardsCount() {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'faceMatchDetection/count');
  }

  getFaceMatchCountsWithFilter(startDate?, endDate?, assetId?: string, embeddingId?: string, camera?: string, groups?: string) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceMatchDetection/count?';
    if (startDate) {
      queryUrl = queryUrl + 'startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (assetId) {
      queryUrl = queryUrl + '&assetId=' + assetId;
    }
    if (embeddingId) {
      queryUrl = queryUrl + '&embeddingId=' + embeddingId;
    }
    if (camera) {
      queryUrl = queryUrl + '&cameraId=' + camera;
    }
    if (groups) {
      queryUrl = queryUrl + '&groups=' + groups;
    }
    return this.http.get(queryUrl);
  }

  getFaceMatchCardsBasedOnRangeWithFilters(skip, limit, startDate?, endDate?, assetId?: string, embeddingId?: string, camera?: string, groups?: string) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceMatchDetection';
    queryUrl += '?skip=' + skip + '&limit=' + limit;
    if (startDate) {
      queryUrl = queryUrl + '&startDate=' + startDate;
    }
    if (endDate) {
      queryUrl = queryUrl + '&endDate=' + endDate;
    }
    if (assetId) {
      queryUrl = queryUrl + '&assetId=' + assetId;
    }
    if (embeddingId) {
      queryUrl = queryUrl + '&embeddingId=' + embeddingId;
    }
    if (camera) {
      queryUrl = queryUrl + '&cameraId=' + camera;
    }
    if (groups) {
      queryUrl = queryUrl + '&groups=' + groups;
    }
    return this.http.get(queryUrl);
  }

  getAllFaceMatchCardsDataOnSearch(searchtext) {
    let queryUrl = this.configService.appConfig.appBaseUrl + 'faceMatchDetection/search/asset/';
    if (searchtext.length > 0) {
      queryUrl += searchtext;
    }
    return this.http.get(queryUrl + '?limit=5&autoRegistered=true');
  }

  getAnalyticsData(startDate, endDate, series, status, selectedPersonEmbeddingId?) {
    let queryUrl;
    queryUrl = this.configService.appConfig.appBaseUrl + 'faceMatchDetection/analytics/counts/' + startDate + '/' + endDate + '?status=' + status;
    if (series) {
      queryUrl = queryUrl + '&series=' + series;
    } else {
      queryUrl = queryUrl + '&series=' + 'day';
    }
    if (selectedPersonEmbeddingId) {
      queryUrl = queryUrl + '&embeddingId=' + selectedPersonEmbeddingId;
    }
    return this.http.get(queryUrl);
  }

  getAssetDetailsById(id) {
    if(!id){
      return of({})
    }
    return this.http.get(this.configService.appConfig.appBaseUrl + 'asset/' + id);
  }

  removeFalsePositive(obj) {
    return this.http.post(this.configService.appConfig.appBaseUrl + 'faceMatchDetection/falsePositive',  obj);
  }

  getImages(id, type) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'faceMatchDetection/image/' + id + '/' + type, { responseType: 'blob' });
  }

  getThumbnailsById(id) {
    return this.http.get(this.configService.appConfig.appBaseUrl + 'autoRegisteredFace/imageById/' + id + '/thumbnail', { responseType: 'blob' });
  }

  getFaceMatchDetectionImages(id, type) {
    /*const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'faceMatchDetection/image/' + id + '/' + type + '?token=' + token;*/
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'image/faceMatch/' + id + '/' + type + '?token=' + token;
  }

  getFaceMatchImages(id, type) {
    const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    return this.configService.appConfig.appBaseUrl + 'image/faceMatchImages/' + id + '/' + type + '?token=' + token;
  }
}

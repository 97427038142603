import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
declare  var $: any;
import * as _ from "lodash";
import {LangUtilService} from '../../util/lang-util.service';
@Component({
  selector: 'app-services-for-linking',
  templateUrl: './services-for-linking.component.html',
  styleUrls: ['./services-for-linking.component.scss']
})
export class ServicesForLinkingComponent implements OnChanges, OnInit {
  searchedAssetForLinking: any;
  resetSearchedAssetOnBackBtn: number;
  public  linkAssetsDetails: any;
  public linkAssetsIds: any = [];
  @Input() allAssetTypes: any;
  @Input() serviceAssetConfigDetails: any;
  @Input() assetData: any;
  @Input() resetSearchedServicesOnPlusClick: any;
  @Input() config: any;
  @Output() linkAssetDetailsToSlider: EventEmitter<any> = new EventEmitter();
  constructor(private lang: LangUtilService) { }

  ngOnInit() {
  }
  ngOnChanges() {
  }
  searchedAssetsFound (searchedAssets) {
    this.searchedAssetForLinking = searchedAssets;
    this.linkAssetsIds = [];
  }

  linkAssetDetails(linkingAssetDetails) {
    console.log(linkingAssetDetails);
    this.linkAssetsDetails = linkingAssetDetails;
    if (linkingAssetDetails.isAssetSelected === true) {
      let res;
      res = _.find(this.linkAssetsIds, function(o) { return o === linkingAssetDetails._id; });
      if (!res) {
        this.linkAssetsIds.push(linkingAssetDetails._id);
      }
    } else if (linkingAssetDetails.isAssetSelected === false) {
      _.remove(this.linkAssetsIds, function(n) {
        return (n === linkingAssetDetails._id);
      });
    }
    this.linkAssetDetailsToSlider.emit(this.linkAssetsIds);
  }

  resetSearchedAssets() {
    this.resetSearchedAssetOnBackBtn = Math.random();
  }

  getLangLabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";

@Injectable()
export class RfidGatewayService {

  constructor(private http: HttpClient,public configService:ConfigService) { }

  saveRFIDGatewaySetting(url,RIFDGatewaySettings) {
    return this.http.post(url +'/rfidGatewaySettings', RIFDGatewaySettings);
  }


}

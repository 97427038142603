import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import {LangUtilService} from '../../util/lang-util.service';
import {SearchService} from '../../../services/search.service';
declare var $: any;
@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent implements OnChanges, OnInit {

  @Input() linkOrAddAction: string; // should be add or link
  @Input() linkOrAddActionCount: number;
  @Input() linkOrAddActionLabel: string;
  @Input() linkOrAddActionDescription: string;

  @Input() delinkOrRemoveAction: string; // should be remove or delinnk
  @Input() delinkOrRemoveActionCount: number;
  @Input() delinkOrRemoveActionLabel: string;
  @Input() delinkOrRemoveActionDescription: string;

  @Input() selectedAssetToLinkCount: number;
  @Input() selectedGroupsToAddCount: number;
  @Input() selectedGroupsToRemoveCount: number;
  @Input() selectedExceptionAssetsCount: number;
  @Input() errorMessages: Array<any>;
  @Input() serverAction: any;
  @Input() labelToDelete: string;
  @Input() msgToDisplay: string;
  @Output() confirmed: EventEmitter<string> = new EventEmitter();
  @Output() approvalConfirmed: EventEmitter<string> = new EventEmitter();
  @Output() confirmedDelink: EventEmitter<any> = new EventEmitter();
  @Output() confirmedServiceDelink: EventEmitter<any> = new EventEmitter();
  @Output() confirmedlink: EventEmitter<any> = new EventEmitter();
  @Output() confirmedAddingGroup: EventEmitter<any> = new EventEmitter();
  @Output() confirmedRemovingGroup: EventEmitter<any> = new EventEmitter();
  @Output() confirmedServicelink: EventEmitter<any> = new EventEmitter();
  @Output() confirmedDeregisteringAsset: EventEmitter<any> = new EventEmitter();
  @Output() confirmedDeletingUserApiKeys: EventEmitter<string> = new EventEmitter();
  @Output() confirmedRegisteringExceptionBeacons: EventEmitter<any> = new EventEmitter();
  @Output() confirmedDeregisteringExceptionBeacons: EventEmitter<any> = new EventEmitter();
  @Output() confirmedServerAction: EventEmitter<any> = new EventEmitter();
  @Output() confirmedDeletingAsset: EventEmitter<string> = new EventEmitter();
  @Output() confirmedLinkOrAddAction: EventEmitter<string> = new EventEmitter();
  @Output() confirmedDelinkOrRemoveAction: EventEmitter<string> = new EventEmitter();

  @Input() specificConfirmationText: string;

  note: string;
  message: string;
  disableOkBtn: boolean;
  constructor(private lang: LangUtilService, private searchService: SearchService) {
    this.serverAction = null;
    this.labelToDelete = '';
    this.msgToDisplay = '';
    this.note = '';
    this.message = '';
    this.specificConfirmationText = '';
  }

  ngOnChanges() {
    if (this.msgToDisplay) {
      let input = this.msgToDisplay;
      let fields = input.split('~');
      this.message = fields[0];
      this.note = fields[1];
    }
  }
  ngOnInit() {
  }
  cancelBtnClicked(){
    this.searchService.sendCancelBtnInPopupClickedEvent(true);
  }
  confirm() {
    this.confirmed.emit('OK');
  }
  confirmApproval() {
    const _this = this;
    this.disableOkBtn = true;
    if (this.specificConfirmationText) {
      this.approvalConfirmed.emit(this.specificConfirmationText);
    } else {
      this.approvalConfirmed.emit('OK');
    }
    setTimeout(function() {
        _this.disableOkBtn = false;
        $('.popup').fadeOut(250, function() {
          $('.background').fadeOut(250);
        });
      }, 1000);
  }
  confirmDelink() {
    this.confirmedDelink.emit('OK');
  }
  confirmServiceDelink() {
    this.confirmedServiceDelink.emit('OK');
  }
  linkingConfirmed() {
    this.confirmedlink.emit('OK');
  }

  linkingServiceConfirmed() {
    this.confirmedServicelink.emit('OK');
  }

  addGroupConfirmed() {
    this.confirmedAddingGroup.emit('OK');
  }

  removeGroupConfirmed() {
    this.confirmedRemovingGroup.emit('OK');
  }
  deregisteringAssetConfirmed() {
    this.confirmedDeregisteringAsset.emit('OK');
  }

  deleteUserApiKeysConfirmed() {
    this.confirmedDeletingUserApiKeys.emit('OK');
  }

  registerExceptionBeaconConfirmed() {
    this.confirmedRegisteringExceptionBeacons.emit('OK');
  }

  deregisterExceptionBeaconConfirmed() {
    this.confirmedDeregisteringExceptionBeacons.emit('OK');
  }

  serverActionConfirmed() {
    this.confirmedServerAction.emit('OK');
  }

  deletingAssetConfirmed() {
    this.confirmedDeletingAsset.emit('OK');
  }

  linkOrAddActionConfirmed() {
    this.confirmedLinkOrAddAction.emit(this.linkOrAddAction);
  }

  delinkOrRemoveActionConfirmed() {
    this.confirmedDelinkOrRemoveAction.emit(this.delinkOrRemoveAction);
  }

  getLangLabel(labelCode: string) {
    return this.lang.getTranslation(labelCode);
  }

}

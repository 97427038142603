import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
// import {log} from "util";
import {FormService} from '../../../services/form.service';
import {AssetService} from '../../../services/asset.service';
import { Store } from '@ngrx/store';
import { currentActiveId, selectIsSidebarFullScreen} from '../../../state/Sidebar/sidebar.selector';
import { CONSTANT } from '../../../config/constant';
declare let $: any;
const SIDEBAR_ID = CONSTANT.SIDEBAR_IDS.MAINTENANCE_LOG_FORM;

@Component({
  selector: 'app-notification-event-create-form',
  templateUrl: './notification-event-create-form.component.html',
  styleUrls: ['./notification-event-create-form.component.scss']
})
export class NotificationEventCreateFormComponent implements  OnChanges, OnInit, OnDestroy {
  assetSelectedType: string;
  assetSelectedLabel: string;

  sidebarId = SIDEBAR_ID;
   currentActiveSidebarId$;
  @Input() selectedAssetConfigDetails: any;
  @Input() isEventSelectedToEdit: boolean;
  @Input() isCloneBtnReq: boolean;
  @Input() showUpdateBtn: boolean;
  @Input() eventToEdit: any;
  @Input() formData: any;
  @Input() formConfiguration: any;
  @Input() resetForm: number;
  @Input() selectedAccountId :string
  @Input() assetTypeToSend:string
  @Output() savedAssetCardToAssetList: EventEmitter<any> = new EventEmitter();
  @Output() assetRegistrationErrorMsgToAssetList: EventEmitter<string> = new EventEmitter();
  @Output() createModalClosedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() formSubmitTrigger: EventEmitter<any> = new EventEmitter();
  @Output() resetOnModelClosed: EventEmitter<any> = new EventEmitter();
  isSidebarClosed=true
  sidebarIsFullScreen$ = this.store.select(selectIsSidebarFullScreen);
  public output: any;
  public assetFromConfig: any;
  public formDataForRendering: any;
  public showCircularLoader: boolean;
  public formLabel: string;
  isCurrentIdActive = false;

  constructor(private store: Store,public assetService: AssetService, public formService: FormService) { }

  ngOnInit(): void {
    this.currentActiveSidebarId$ = this.store.select(currentActiveId).subscribe(res =>{
      if(res === this.sidebarId)
      this.isCurrentIdActive = true;
      else
      this.isCurrentIdActive = false;
    })
  }

  ngOnDestroy(){
    this.currentActiveSidebarId$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'resetForm') {
        if (change.currentValue !== change.previousValue) {
          this.assetFromConfig = [];
          this.showCircularLoader = true;
          this.renderForm();
        }
      }
      if (propName === 'eventToEdit') {
        if (change.currentValue !== change.previousValue) {
          this.resetForm = Math.random();
          this.formDataForRendering = this.eventToEdit ? this.formDataForRendering : []
        }
      }
    }
  }
  renderForm() {
    if ( this.formConfiguration && this.formConfiguration['label'] ) {
      this.formLabel = this.formConfiguration['label'];
      this.formDataForRendering = this.formService.formatAssetConfig(this.formData);
      this.showCircularLoader = false;
    }
  }
  closePopup() {
    // $('html').css('overflow-y', 'auto');
    this.resetForm = Math.random();
    if(!this.isSidebarClosed){
      this.createModalClosedEvent.emit(true);
      this.resetOnModelClosed.emit(this.resetForm);
    }
    this.isSidebarClosed = true;   
    this.formLabel = '';

  }
  fetchConfigBasedOnType() {
    /* this.assetService.getAssetConfig(this.assetSelectedType)
      .subscribe((data: any) => {
        this.assetFromConfig = this.formService.formatAssetConfig(data.configuration);
        this.showCircularLoader = false;
      }); */
  }

  fetchConfigBasedOnServiceAssetType() {
   /*  this.serviceLinkableService.getServiceAssetConfig(this.assetSelectedType)
      .subscribe((config: any) => {
        this.assetFromConfig = this.formService.formatAssetConfig(config.configuration);
        this.showCircularLoader = false;
      }); */
  }

  savedAssetCard(assetValue) {
    this.savedAssetCardToAssetList.emit(assetValue);
  }

  assetRegistrationErrorMsg(errMsg: string) {
    this.assetRegistrationErrorMsgToAssetList.emit(errMsg);
  }
  formSubmitHandler(event: any) {
    this.formSubmitTrigger.emit(event);
  }

}

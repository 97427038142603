<div [ngClass]="isSmallEChart ? (showTitleWithCounts ? 'graph-main-full-container-small-chart' : 'graph-main-half-container') : 'graph-main-full-container'" class="graph-main-container">

  @if (showChartFilterMenu && leftArrayOfPills || showChartFilterMenu && arrayOfPills) {
    <div class="chart-filter-menu-container">
      @if (graphData?.pills?.selected; as series) {
        <span class="series"
              [ngClass]="{'series-top-position':((graphData ? (graphData?.isAnalytics===false && graphData?.hideCount!==true) : true) && (count || graphData?.counts?.counts) >= 0)}">
          <p-chip pTooltip="Series: {{series | titlecase}}" tooltipPosition="top" [label]="series | capitalizeFirst"/>
        </span>
      }
      <app-chart-filter-menu [filterMenuOptions]="filterMenuOptionsArray" [isGraphStackable]="isGraphStackable"
                             (selectedFilterType)="getSelectedChartFilterOption($event)"
                             [series]="graphData?.pills?.selected"></app-chart-filter-menu>
    </div>
  }

  <div class="wrapper card_bg" [ngClass]="{'card-border':showTitleWithCounts}" [ngStyle]="{height: (count || graphData?.counts?.counts) || countArray ? '' : wrapperCardHeightInPx}">
    @if (showTitleWithCounts) {
      <div class="titleAndCounts-container grid">
        <div class="col-10 sm:col-10 md:col-9 lg:col-10 xl:col-11"
             [ngStyle]="{'margin-bottom': gapBelowTitle ? gapBelowTitle : '0px'}">
          <div class="title-and-series">
            <span class="title">
              {{ title || graphData?.counts?.title }}
            </span>
            <!-- <span class="series" *ngIf="graphData?.pills?.selected as series"
            [ngClass]="{'series-top-position':((graphData ? (graphData?.isAnalytics===false && graphData?.hideCount!==true) : true) && (count || graphData?.counts?.counts) >= 0)}">
            <p-chip pTooltip="{{series}}" tooltipPosition="top" [label]="series | capitalizeFirst" />
          </span> -->
        </div>
      </div>
      <div class="col-2 sm:col-2 md:col-3 lg:col-2 xl:col-1"></div>
        @if (!graphData?.hideCount && (graphData ? graphData?.isAnalytics === false : true) && (count >= 0)) {
          <div class="upper-grid">
            <div><span class="count">{{ count && count < 10 ? '0' + count : count }}</span></div>
          </div>
        }
        @if (count === undefined && !graphData?.hideCount && (graphData ? graphData?.isAnalytics === false : true) && (graphData?.counts?.counts >= 0)) {
          <div class="upper-grid">
            <div><span
              class="count">{{ graphData?.counts?.counts && graphData?.counts?.counts < 10 ? '0' + graphData?.counts?.counts : graphData?.counts?.counts }}</span>
            </div>
          </div>
        }
    </div>
    }

    @if (countArray && countArray.length > 0) {
      <div class="titleAndCounts-container-2 grid">
        @for (obj of countArray; track obj) {
          <div class="col-12 xl:col-4 lg:col-3 md:col-6">
            @if (obj.count || obj.count === 0) {
              <div class="subTitle">{{ obj.tooltip }}</div>
            }
            @if (obj.count || obj.count === 0) {
              <div class="count" [pTooltip]="obj.tooltip" tooltipPosition="top">{{ obj.count }}{{ obj.unit }}</div>
            }
            @if (obj.count || obj.count === 0) {
              <div class="subTitle bold">{{ obj.subTitle }}</div>
            }
          </div>
        }
    </div>
    }

    <div class="graph-container py-0" [ngClass]="{'card-border':!showTitleWithCounts}">
      @if (showLeftPills && !showChartFilterMenu) {
        <div class="left-pills-container">
        <app-iotzen-pills [pillActiveColour]="leftPillActiveColour" [isPillTabToggleRequired]="isPillTabToggleRequired"
                          [defaultActivePillIndex]="leftDefaultActivePillIndex" [arrayOfPills]="leftArrayOfPills"
                          (activePillValue)="getGraphType($event)">
        </app-iotzen-pills>
      </div>
      }
      @if (showPills && !showChartFilterMenu) {
        <div class="pillsContainer">
          <app-iotzen-pills [pillActiveColour]="pillActiveColour" [isPillTabToggleRequired]="isPillTabToggleRequired"
                            [defaultActivePillIndex]="defaultActivePillIndex" [arrayOfPills]="arrayOfPills"
                            (activePillValue)="getActiveDistancePillValue($event)">
          </app-iotzen-pills>
      </div>
      }
      <div class="echart-grid">
        @if ((chartData || graphData?.counts?.chartData)?.['type'] !== 'pie') {
        <app-e-charts [loader]="loader" [chartData]="chartData || graphData?.counts?.chartData" [show]="show || graphData?.counts?.show" (sendDataToPlotMarkerOnMap)="sendDataToPlotMarkerOnMap($event)"
          [updateValue]="updateValue" [graphTypeChangedRandomNumber]="graphTypeChangedRandomNumber || graphData?.counts?.graphTypeChangedRandomNumber" (sendDataToOpenInModal)="sendDataToOpenInModal($event)"
                      [isSmallEChart]="isSmallEChart" [forceChartHeightInPx]="forceChartHeightInPx"
                      [isDataZoomRequired]='isDataZoomRequired' [isTotalNotRequired]='isTotalNotRequired'>
        </app-e-charts>
        }
        @if ((chartData || graphData?.counts?.chartData)?.['type'] === 'pie') {
          <app-echart-doughnut-chart [forceChartHeightInPx]="forceChartHeightInPx" [isSmallEChart]="isSmallEChart"
                                     [chartData]="(chartData || graphData?.counts?.chartData).pieChartData"
                                     [chartName]="''"
                                     (sendDataToNavigateToSelectedComponent)="sendDataToNavigateToSelectedComp($event)"
                                     (sendDataToOpenInModal)="sendDataToOpenInModal($event)"></app-echart-doughnut-chart>
        }
      </div>
    </div>
  </div>

</div>
